import { useState, useEffect } from "react";
import { API_BASE_URL } from '../../config';
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import edit_profile from "../../Images/flutter/edit_profile.png";
import FlutterBackHeader from "./FlutterBackHeader";
import { useTranslation } from "react-i18next";

const FlutterMultiProfileEdit = () => {
    const location = useLocation();
    const {t} = useTranslation();
    const UserId = localStorage.getItem('user_id') || '';
    const navigate = useNavigate();
    const { id } = useParams();
    const [subAccount, setSubAccount] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [displayBlock, setDisplayBlock] = useState('none');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Add or remove class based on the current route
        if (location.pathname === `/flutter-edit-profile`) {
            document.body.classList.add('black-background');
        } else {
            document.body.classList.remove('no-black-background');
        }
    }, [location.pathname]);

    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/sub_account_get`, { id })
            .then(response => {
                console.log(response.data.VIDEO_STREAMING_APP);
                setSubAccount(response.data.VIDEO_STREAMING_APP[0]);
            })
            .catch(err => {
                console.log(err);
            });
    }, [id]);

    const deleteSubAccount = () => {
        axios.post(`${API_BASE_URL}api/v1/sub_account_delete`, {
            user_id: UserId,
            sub_account_id: id
        })
            .then(response => {
                console.log(response);
                localStorage.removeItem("sub_account_name");
                localStorage.removeItem("sub_account_id");
                localStorage.removeItem("sub_account_img");
                localStorage.removeItem("sub_account_age");
                navigate('/flutter-multi-profiles'); // Use navigate instead of window.location.href
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleImageClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            console.log(file);
        }
    };

    const handleNameChange = (e) => {
        setSubAccount({ ...subAccount, name: e.target.value });
    };

    const handleAgeChange = (e) => {
        setSubAccount({ ...subAccount, age: e.target.value });
    };

    const handleSave = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('user_id', UserId);
        formData.append('name', subAccount.name); // Use subAccount.name
        formData.append('user_image', selectedImage);
        formData.append('age', subAccount.age); // Use subAccount.age
        formData.append('id', id);

        axios.post(`${API_BASE_URL}api/v1/sub_account_update`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(response => {
            if (response.data.status_code === 401) {
                setResponseMessage(response.data.VIDEO_STREAMING_APP);
            } else {
                console.log(response.data.VIDEO_STREAMING_APP[0].msg);
                setResponseMessage(response.data.VIDEO_STREAMING_APP[0].msg);
                navigate('/flutter-multi-profiles'); // Use navigate instead of window.location.href
            }
            setDisplayBlock('block');
        }).catch(err => {
            setResponseMessage('An error occurred while creating the profile.');
            setDisplayBlock('block');
            console.error(err);
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <>
        <FlutterBackHeader />
        <section className="multi__edit_profiles_flutter">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="profile__edit_main" onClick={handleImageClick}>
                            <div className="profile__avatar_futter" style={{ overflow: 'hidden', height: '100px', width: '100px', borderRadius: '100%' }}>
                                <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                    onChange={handleFileChange}
                                />
                                {selectedImage ? (
                                    <img src={URL.createObjectURL(selectedImage)} alt="Selected" style={{ width: '100%', objectFit: 'cover', height: '100%' }} />
                                ) : (
                                    <img src={subAccount.user_image || edit_profile} alt="Avatar" style={{ width: '100%', objectFit: 'cover', height: '100%' }} />
                                )}
                            </div>
                            <div className="profile__edit_title__flutter">
                            <p>{t("Choose Avatar")}</p>
                            </div>
                        </div>

                        <div className="edit__profile_form__main_flutter">
                            <div className="form__profile_flutter">
                                <form action="">
                                    <div className="group__input">
                                        <label htmlFor="">{t("name", { defaultValue: "Your Name" })}</label>
                                        <input
                                            type="text"
                                            className="edit__profile_flutter_field"
                                            value={subAccount.name || ""}
                                            onChange={handleNameChange} // Control input value
                                        />
                                    </div>
                                    <div className="group__input">
                                        <label htmlFor="">{t("What age-appropriate content do you watch?")}</label>
                                        <select
                                            name=""
                                            id=""
                                            className="edit__profile_flutter_field"
                                            value={subAccount.age || ""}
                                            onChange={handleAgeChange} // Control select value
                                        >
                                            <option value="" disabled>{t("Select Age Group")}</option>
                                            <option value="3">{t("up to 3 years")}</option>
                                            <option value="5">{t("up to 5 years")}</option>
                                            <option value="12">{t("up to 12 years")}</option>
                                            <option value="15">{t("up to 15 years")}</option>
                                            <option value="18">{t("up to 18 years")}</option>
                                            <option value="1000">{t("everyone")}</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {/* Buttons */}
                        <div className="profile__btns_flutter">
                            <Link to="#" className="btn_profile_edit save_btn" onClick={handleSave}>{t("save",{defaultValue:'Save'})}</Link>
                            
                            <Link to="#" className="btn_profile_edit btn_btn" 
                            onClick={()=>navigate(-1)}>{t("back",{defaultValue:'Back'})}</Link>
                            
                            <Link to="#" className="delete_profile_flutter" onClick={deleteSubAccount}>
                               {t("Delete Profile")}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="show__profile__flutter"></div>
        </section>
        </>
    );
}

export default FlutterMultiProfileEdit;
