import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import kurdishflag from '../Images/home_images/kurdishflag.png';
import usaflag from '../Images/home_images/usaflag.jpg';
import logo from '../Images/home_images/newflix-logo.png';
import { API_BASE_URL } from '../config';

const Navbar = ({ setShowSearch, isTallScreen }) => {
  let sessionName = localStorage.getItem('session_name');
  const [subImage, setSubimage] = useState('');
  const [Navgenre, SetNavgenre] = useState([]);
  const navigate = useNavigate();
  const [liveTv, Setlivecategory] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState(null);
  const UserId = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
  const sub_account_id = localStorage.getItem('sub_account_id') ? localStorage.getItem('sub_account_id') : '';
  const [flags, setFlags] = useState([]);
  const [isChildModeActive, setIsChildModeActive] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  let [userplan, setUserplan] = useState('');
  const [checkplansuscription, setcheckplansuscription] = useState('');

  // For search filter
  const [searchResults, setSearchResults] = useState({
    movies: [],
    live_tv: [],
    shows: []
  });

  useEffect(() => {
    const childMode = localStorage.getItem('child_mode');
    setIsChildModeActive(childMode === '1');
  }, []);

  useEffect(() => {
    let subAccountimage = localStorage.getItem('sub_account_img') || '';
    setSubimage(subAccountimage);
  }, []);
  const logouthandle = (e) => {
    e.preventDefault();
    let userId = localStorage.getItem('user_id');

    localStorage.clear();
    axios.post(`${API_BASE_URL}api/v1/logout`, {
      user_id: userId,
      user_session_name: sessionName,
    }).then(response => {
      window.location.href = '/';
      // console.log(response);
    }).catch(err => {
      console.log(err);
    });
  };

  const [openNav, setNav] = useState(false);
  const opensidemenu = () => {
    setNav(true);
  };
  const closesidemenu = () => {
    setNav(false);
  };


  useEffect(() => {
    axios.post(`${API_BASE_URL}api/v1/genres`, {}).then((response) => {
      SetNavgenre(response.data.VIDEO_STREAMING_APP);
    }).catch(error => {
      console.error('There was an error making the request:', error);
    });
    axios.post(`${API_BASE_URL}api/v1/livetv_category`, {
      filter: 'rand',
    })
      .then(response => {
        Setlivecategory(response.data.VIDEO_STREAMING_APP);
      }).catch(err => {
        console.log("Live Category is not fetching due to some issues" + err);
      })
  }, []);

  const filterGenres = (type) => {
    return Navgenre.filter(genre => genre.genre_type && genre.genre_type.includes(type));
  };
  // 
  const changeLanguage = (languageCode) => {
    var selectBox = document.querySelector(".goog-te-combo");
    if (selectBox) {
      selectBox.value = languageCode;
      selectBox.dispatchEvent(new Event('change'));
    } else {
      console.error("Google Translate combo box not found.");
    }
  };
  const toggleChildMode = (e) => {
    e.preventDefault();
    window.location.href = '/exit-child-mode'; // Redirect to home page

    // const newMode = !isChildModeActive ? '1' : '0';
    // localStorage.setItem('child_mode', newMode);
    // setIsChildModeActive(!isChildModeActive);
    // setTimeout(() => {
    //   if (newMode === '1') {
    //     window.location.href = '/kids'; // Redirect to kids page
    //   } else {
    //     window.location.href = '/'; // Redirect to home page
    //   }
    // }, 2000);
  };
  // Flags fetching
  useEffect(() => {
    axios.post(`${API_BASE_URL}api/v1/languages`, {
      user_id: UserId,
    }).then(response => {
      // console.log(response.data.VIDEO_STREAMING_APP)
      setFlags(response.data.VIDEO_STREAMING_APP);
    }).catch(err => {
      console.log(err + "Fetching Error in flags");
    })
  }, []);


  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (flag) => {
    setSelectedFlag(flag);
    changeLanguage(flag.language_code.toLowerCase());
    setIsOpen(false);
  };

  // search Work
  const searchMovie = () => {
    setSearchOpen(true);
  };

  const closeSearch = () => {
    setSearchOpen(false);
  }

  const searchFunct = (event) => {
    const searchText = event.target.value;
    if (event.key === 'Enter') {
      navigate(`/search/${searchText}`);
      setSearchOpen(false);
    }
    axios.post(`${API_BASE_URL}api/v1/search`, {
      search_text: searchText
    })
      .then(response => {
        setSearchResults({
          movies: response.data.VIDEO_STREAMING_APP.movies || [],
          live_tv: response.data.VIDEO_STREAMING_APP.live_tv || [],
          shows: response.data.VIDEO_STREAMING_APP.shows || []
        });
        // console.log(response.data.VIDEO_STREAMING_APP);
      })
      .catch(err => {
        console.log("Error on search: " + err);
      });
    // axios.post(`${API_BASE_URL}api/v1/search`, {
    //   search_text: searchText
    // })
    //   .then(response => {
    //     // Handle the response here
    //     setSearchFilter(response.data.VIDEO_STREAMING_APP.movies)
    //     console.log(response.data.VIDEO_STREAMING_APP.movies);
    //   })
    //   .catch(err => {
    //     console.log("Error on search: " + err);
    //   });
  };

  const playMovieSearch = (movie_id) => {
    window.location.href = `/movie-page/${movie_id}`
    // navigate(`/movie/${movie_id}`);
  }

  function extractAndLimitText(htmlString, wordLimit) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    let text = tempElement.textContent || tempElement.innerText || '';
    const words = text.split(/\s+/).slice(0, wordLimit);
    return words.join(' ');
  }
  const location = useLocation();
  const hideNavbarPaths = [
    /^\/movies(\/\d+)?$/, // Matches /movies and /movies/:id
    /^\/serials(\/\d+)?$/,   // Matches /serials/:id
    /^\/view-all(\/\d+)?$/,   // Matches /serials/:id
    /^\/view-all-shows(\/\d+)?$/,   // Matches /serials/:id
    /^\/childrens(\/\d+)?$/,   // Matches /serials/:id
    /^\/movie-page(\/\d+)?$/,   // Matches /watch-movie/:id
    /^\/live-tv(\/\d+)?$/,   // Matches /live-tv/:id
    /^\/watch-movie(\/\d+)?$/,   // Matches /live-tv/:id
    /^\/view-all-live-tv(\/\d+)?$/,   // Matches /live-tv/:id
    /^\/watch-episode(\/\d+)?$/,   // Matches /live-tv/:id
    /^\/episodes(\/\d+)?$/,   // Matches /live-tv/:id
    /^\/exit-child-mode\/?$/,
    /^\/contact-us\/?$/,
    /^\/kids\/?$/,
    /^\/profile\/dashboard\/?$/,
    /^\/profile\/edit\/?$/,
    /^\/app\/?$/,
    /^\/live-tv\/?$/,
  ];
  const shouldShowNavbar = !hideNavbarPaths.some((path) => path.test(location.pathname));
  // console.log(window.location.pathname);

  const [subAccountList, setAccountList] = useState([]);
  const userId = localStorage.getItem("user_id");
  const [loading, setLoading] = useState(false);
  const [subAccountId, setSubAccountId] = useState(localStorage.getItem("sub_account_id") || '');
  const [subImageNav, setSubImageNav] = useState('');
  // Multi Profiles

  useEffect(() => {
    let subAccountImage = localStorage.getItem('sub_account_img') || '';
    setSubImageNav(subAccountImage);
  }, []);

  useEffect(() => {
    setLoading(true);
    axios.post(`${API_BASE_URL}api/v1/sub_account_list`, {
      user_id: userId,
    }).then(response => {
      setLoading(false);
      setAccountList(response.data.VIDEO_STREAMING_APP);
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }, [userId]);

  const profileData = (subAccountData) => {
    setSubAccountId(subAccountData.id);
    localStorage.setItem("sub_account_id", subAccountData.id);
    localStorage.setItem("sub_account_name", subAccountData.name);
    localStorage.setItem("sub_account_img", subAccountData.user_image);
    localStorage.setItem("sub_account_age", subAccountData.age);
    localStorage.setItem("child_mode", 1);
    window.location.href = '/kids';
    // reloadMultiProfile();
  };

  useEffect(() => {
    axios.post(`${API_BASE_URL}api/v1/check_user_plan`, {
      user_id: UserId
    })
      .then(response => {
        let checkPlan = response.data.VIDEO_STREAMING_APP;
        setcheckplansuscription(checkPlan.msg);
        setUserplan(response.data.VIDEO_STREAMING_APP);
        // console.log(response)
      }).catch(err => {
        console.log(err);
      })
  }, []);


  return (
    <>
      <nav>
        <div className="container-fluid">
          <div className={`row align-items-center ${!shouldShowNavbar ? 'justify-content-between videos-inner-wrapper' : 'nav_row main-inner-wrapper'} `}>
            <div className="col-md-12 top-nav-logo">
              <div className="nav__logo" >
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              </div>
            </div>
            <div className={`col-12 col-lg-12 col-xl-5 nav__btn-mobile-menu ${!shouldShowNavbar ? 'p-0' : ''}`}>
              <div className="row align-items-center">
                <div className="col-12 col-sm-10">
                  <div className="nav__btn-main" >
                    {
                      localStorage.getItem('user_id') && localStorage.getItem('session_name')
                        ? (
                          <div className="for-login__user">
                            <div className="logged_in_user_img">
                              {
                                subImage === '' ? (
                                  <>
                                    <i className={`fa-regular fa-user login_icon ${checkplansuscription === 'Renew subscription plan' || checkplansuscription === 'Please select subscription plan' ? 'active_plan_color' : ''}`}></i>
                                    <i className="fa-solid fa-angle-down angle_dropdown_nav"></i>
                                  </>
                                ) : (
                                  <>
                                    <img src={subImage} alt="" />
                                    <i className="fa-solid fa-angle-down angle_dropdown_nav"></i>
                                  </>
                                )
                              }

                            </div>
                            <div className="user__dropdown">
                              <div className="dropdown_check_subscription">
                                <span className={`plan_msg ${(checkplansuscription === 'My Subscription') ? 'plan_subcribed_color' : ''} `}>
                                  {checkplansuscription === 'Renew subscription plan' && 'Renew subscription plan'}
                                  {checkplansuscription === 'Please select subscription plan' && 'You do not have a subscription'}
                                  {(checkplansuscription === 'My Subscription') && userplan.current_plan + " subscribed"}
                                  {/* You do not have a subscription */}

                                  <i className="fa-regular fa-credit-card"></i></span>
                                <div className="inside_profile__btn">
                                  <Link to="/subscription">Buy a subscription <i class="fa-solid fa-angle-left"></i></Link>
                                </div>
                              </div>
                              <div className="management_profile">
                                <ul className="m-0 list-unstyled member_profiles_ul">
                                  <li>
                                    <Link to="#" onClick={() => {
                                      if (localStorage.getItem('child_mode') == 1) {
                                        window.location.href = '/exit-child-mode';
                                      }
                                    }}>
                                      <div className="profile_management_img">{localStorage.getItem('user_name').split(' ')[0][0]}</div>
                                      {localStorage.getItem('user_name')}
                                      {/* <p>Age-{account.age}</p> */}
                                    </Link>
                                  </li>
                                  {
                                    subAccountList.map((account, index) => (
                                      <li>
                                        <Link to="#" onClick={() => profileData(account)} key={index}>
                                          <div className="profile_management_img">{account.name.split(' ')[0][0]}</div>
                                          {account.name}
                                          {
                                            subAccountId == account.id && (
                                              <span className="active_account_dropdown">active</span>
                                            )
                                          }
                                        </Link>
                                      </li>
                                    ))
                                  }
                                  <li>
                                    <Link to="/multiple-profiles">
                                      <i class="fa-solid fa-arrow-right-arrow-left"></i>
                                      Member management
                                    </Link>
                                  </li>
                                  <li className="mylist_tag">
                                    <Link to="/watch-list">
                                      <i class="fa-solid fa-eye"></i>
                                      My lists
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/profile/dashboard">
                                      <i class="fa-solid fa-gear"></i>
                                      User account
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/subscription">
                                      <i class="fa-regular fa-credit-card"></i>
                                      Buy a subscription
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" onClick={logouthandle}>
                                      <i class="fa-solid fa-right-from-bracket"></i>
                                      Logout
                                    </Link>
                                  </li>

                                  {/* <li>
                                    <Link to="#">
                                      <div className="profile_management_img">M</div>
                                      Sabah
                                    </Link>
                                  </li> */}
                                  {/* <li><Link to="/profile/dashboard">Profile</Link></li>
                                  <li><Link to="/watch-list">Watch List</Link></li>
                                  <li><Link to="/multiple-profiles">Member Mangement</Link></li>
                                  <li><Link to="/logout">Logout</Link></li> */}
                                </ul>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <Link to="#" onClick={() => window.location.href = '/login'} className="nav__btn login-btn">login</Link>
                        )
                    }
                    {/* <div className="col-md-6"> */}
                    <div className="custom-dropdown">
                      <div
                        className="dropdown-toggle"
                        onClick={toggleDropdown}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          borderRadius: '4px',
                          fontFamily: 'Arial, sans-serif',  // Ensure a valid font-family is set
                          fontSize: '12px',
                          textDecoration: 'none',
                          background: 'transparent',
                          border: '1px solid #ffffff',
                          color: '#ffffff',
                          padding: '9px 20px',
                          cursor: 'pointer',
                        }}
                      >
                        <p className="m-0">
                          {selectedFlag ? selectedFlag.language_name : 'English'}
                        </p>
                      </div>
                      {isOpen && (
                        <ul ul className={`dropdown-menu ${isOpen ? 'active_flag' : 'deactive'}`}>
                          {flags.map((flag, index) => (
                            <li
                              key={index}
                              onClick={() => handleSelect(flag)}
                              className="dropdown-item"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '5px 10px',
                                cursor: 'pointer',
                                color: '#000000',
                              }}
                            >
                              <img
                                src={flag.language_flag}
                                alt={flag.language_name}
                                title={flag.language_name}
                                style={{ width: '20px', height: '15px', marginRight: '10px', objectFit: 'cover' }}
                              />
                              {flag.language_name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    {/* </div> */}
                    <div className="nav_top_btn_link">

                      <Link to="#" onClick={() => window.location.href = '/subscription'} className="nav__btn subscription-nav__btn">
                        <i className="fa-regular fa-credit-card"></i>
                        Buy a subscription
                      </Link>
                      {
                        (sub_account_id &&
                          <Link to="#" onClick={toggleChildMode} className={`nav__btn subscription-nav__btn ${isChildModeActive ? 'active_childmode' : ''}`} > {isChildModeActive ? 'Exit Child Mode' : 'Active Child Mode'}
                          </Link>
                        )
                      }
                    </div>
                  </div>

                </div>
                <div className="col-2 d-lg-block d-xl-none nav__right_logo">
                  <div className="nav__logo" >
                    <Link to="/">
                      <img src={logo} alt="" />
                    </Link>
                  </div>
                </div>
              </div>

            </div>


            <div className={`col-12 col-lg-12 col-xl-6 nav__links__mobile ${!shouldShowNavbar ? 'p-0' : ''}`}>
              <div className="nav__links">
                <ul
                  className="d-flex justify-content-around list-unstyled m-0">
                  <li className="text-center">
                    <div className="menu_logo">
                      <img src={logo} alt="" />
                    </div>
                  </li>

                  <li>
                    <Link to="#" className="search__top" onClick={searchMovie}>
                      <i class="fa-solid fa-magnifying-glass" style={{ marginRight: "5px" }}></i>
                      Search
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => window.location.href = '/kids'}><i className="fa-solid fa-horse" style={{ marginRight: "5px" }}></i>child</Link>
                    <i className="fa-solid fa-angle-down angle_down"></i>
                    <div className="nav_dropdown">
                      <ul className="list-unstyled">
                        {
                          filterGenres('child').map((NavLinks, index) => (
                            <li key={index}>
                              <Link to={`/childrens/${NavLinks.genre_id}`}>{NavLinks.genre_name}</Link>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </li>

                  <li>
                    <Link to="#" onClick={() => window.location.href = '/live-tv'}>live Tv</Link>
                    <i className="fa-solid fa-angle-down angle_down"></i>
                    <div className="nav_dropdown">
                      <ul className="list-unstyled">
                        {
                          liveTv.map((livecategory, index) => (
                            <li key={index}>
                              <Link to={`/live-tv/${livecategory.category_id}?name=${encodeURIComponent(livecategory.category_name)}`}>
                                {livecategory.category_name}
                              </Link>
                            </li>

                          ))
                        }
                      </ul>
                    </div>
                  </li>

                  <li>
                    <Link to="#" onClick={() => window.location.href = '/serials'}>serial</Link>
                    <i className="fa-solid fa-angle-down angle_down"></i>
                    <div className="nav_dropdown">
                      <ul className="list-unstyled">
                        {
                          filterGenres('serials').map((NavLinks, index) => (
                            <li key={index}>
                              <Link to={`/serials/${NavLinks.genre_id}`}>{NavLinks.genre_name}</Link>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </li>

                  <li>
                    <Link to="#" onClick={() => window.location.href = '/movies'}>
                      movie
                    </Link>
                    <i className="fa-solid fa-angle-down angle_down"></i>
                    <div className="nav_dropdown">
                      <ul className="list-unstyled">
                        {
                          filterGenres('movies').map((NavLinks, index) => (
                            <li key={index}>
                              <Link to={`/movies/${NavLinks.genre_id}`}>{NavLinks.genre_name}</Link>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </li>

                  <li className="main_nav__logo d-none d-xl-flex">
                    <Link to="/">
                      <img src={logo} alt="" />
                    </Link>
                  </li>

                </ul>
              </div>

            </div>
            {/* <div className="col-6 col-md-1 text-right logo_main_nav_right">
              <div className="nav__logo" >
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </nav>

      <div className={`search__overlay search__top ${searchOpen ? 'd-block' : ''}`}>
        <div className="container">
          <div className="container-fluid">
            <div className="custom-container">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <div className="search__main">
                    <div className="row align-items-start">
                      <div className="col-md-2 text-right">
                        <Link to="#" className="close__search" onClick={closeSearch}>
                          to close
                        </Link>
                      </div>
                      <div className="col-md-10">
                        <input type="text" placeholder="Search for movies, actors, directors and..." className="search_input" onKeyUp={searchFunct} />
                        <div className="search__content">
                          <p className="m-0">Search history</p>
                          <p className="m-0">In this list, movies and series, actors and directors that you search are saved.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="filter_search">
                      {searchResults.movies.length > 0 && (
                        <div className="filter_search">
                          <h3>Movies</h3>
                          {searchResults.movies.map((movie, index) => (
                            <Link to="#" style={{ textDecoration: 'none' }} onClick={() => playMovieSearch(movie.movie_id)}>
                              <div className="filter__search-main" key={index}>
                                <div className="row align-items-center">
                                  <div className="col-md-10">
                                    <div className="filter_content">
                                      <h2>{movie.movie_title}</h2>
                                      <div className="filter_info">
                                        <span>{movie.movie_access}, </span>
                                        <span>{movie.movie_duration}</span>
                                      </div>
                                      <p className="m-0">
                                        {
                                          extractAndLimitText(movie.movie_description, 20)
                                        }
                                      </p>
                                      <div className="play__movie_btn">
                                        <Link to="#" onClick={() => playMovieSearch(movie.movie_id)}>
                                          Watch Movie
                                          <i class="fa-regular fa-circle-play"></i>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="search__filter_img">
                                      <img src={movie.movie_poster} alt={movie.movie_title} loading="lazy" />
                                      <div className="over__lay_search">
                                        <div className="play_search__main">
                                          <i className="fa-solid fa-play"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          ))}
                        </div>
                      )}
                      {/* Live TV */}
                      {searchResults.live_tv.length > 0 && (
                        <div className="filter_search">
                          <h3>Live TV</h3>
                          {searchResults.live_tv.map((live, index) => (
                            <div className="filter__search-main" key={index}>
                              <div className="row align-items-center">
                                <div className="col-md-10">
                                  <div className="filter_content">
                                    <h2>{live.tv_title}</h2>
                                    <div className="filter_info">
                                      <span>{live.tv_access}</span>
                                    </div>
                                    <p className="m-0">
                                      {
                                        extractAndLimitText(live.tv_description, 20)
                                      }
                                    </p>
                                    <div className="play__movie_btn">
                                      <Link to="#" onClick={() => window.location.href = `/live-tv-detail/${live.tv_id}`}>
                                        Watch Live Tv
                                        <i class="fa-regular fa-circle-play"></i>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="search__filter_img">
                                    <img src={live.tv_logo} alt={live.tv_logo} loading="lazy" />
                                    <div className="over__lay_search">
                                      <div className="play_search__main">
                                        <i className="fa-solid fa-play"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {/* Shows */}
                      {searchResults.shows.length > 0 && (
                        <div className="filter_search">
                          <h3>Shows</h3>
                          {searchResults.shows.map((show, index) => (
                            <div className="filter__search-main" key={index}>
                              <div className="row align-items-center">
                                <div className="col-md-10">
                                  <div className="filter_content">
                                    <h2>{show.show_title}</h2>
                                    <div className="filter_info">
                                      <span>{show.show_access}</span>
                                    </div>
                                    <p className="m-0">
                                      {
                                        extractAndLimitText(show.show_description, 20)
                                      }
                                    </p>
                                    <div className="play__movie_btn">
                                      <Link to="#" onClick={() => window.location.href = `/show-detail/${show.show_id}`}>
                                        Watch Show
                                        <i class="fa-regular fa-circle-play"></i>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="search__filter_img">
                                    <img src={show.show_poster} alt={show.show_poster} loading="lazy" />
                                    <div className="over__lay_search">
                                      <div className="play_search__main">
                                        <i className="fa-solid fa-play"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
