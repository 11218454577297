import { React } from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { API_BASE_URL } from '../config';

const KidsComponent = ({ genreId, genreName, moviesByGenre }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const subUserSelectedAge = parseInt(localStorage.getItem('sub_account_age'));
    const [moviesBygenre, setMovieBygenre] = useState([]);
    // Movie By Genre
    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/movies_by_genre`, {
            genre_id: genreId,
            filter: "new"
        }).then((response) => {
            let childMode = parseInt(localStorage.getItem('child_mode'));
            // console.log(response.data.VIDEO_STREAMING_APP)
            setLoading(false);
            if (childMode === 1 && subUserSelectedAge) {
                let filteredMovies = response.data.VIDEO_STREAMING_APP.filter(movie => {
                    if (movie.content_rating === '') {
                        return false;
                    }
                    return parseInt(movie.content_rating) <= subUserSelectedAge;
                });
                // const filteredMovies = response.data.VIDEO_STREAMING_APP.filter(movie => {
                //     const validContentRating = movie.content_rating !== '' && parseInt(movie.content_rating) <= subUserSelectedAge;
                //     const validChildMode = movie.child_mode !== '' && parseInt(movie.child_mode) === childMode;
                //     return validContentRating && validChildMode;
                // });
                // setMoviesByGenre(filteredMovies);
                setMovieBygenre(filteredMovies);
            }
            else {
                const latestSixMovies = response.data.VIDEO_STREAMING_APP.slice(0, 6);
                setMovieBygenre(latestSixMovies);
            }
        }).catch((error) => {
            setMovieBygenre([]);
            console.error('Error fetching movies:', error);
        });
    }, [])
    return (
        <>
            <div className="all__movies-main" data-aos="fade-up" data-aos-duration="2000">
                <div className="videos__container">
                    {moviesBygenre && moviesBygenre.length > 0 ? (
                        moviesBygenre.map((movie, index) => (
                            <div className="videos_item" key={index}>
                                <div className="movie" onClick={() => navigate(`/movie-page/${movie.movie_id}`)} >
                                    <div className="movie_img">
                                        <img src={movie.movie_poster} alt={movie.movie_title} />
                                        <div className="overlay__movie">
                                            <div className="movie__info">
                                                {/* <p className='duration'>Duration: {movie.movie_duration}</p>
                                                <p className='rating duration d-block text-center'>{movie.movie_access}</p>
                                                <p className='categ_related'>{genreName}</p> */}
                                                <p>
                                                    {
                                                        movie.genre_list && movie.genre_list.length > 0
                                                            ? movie.genre_list.slice(0, 2).map(genres => genres.genre_name).join(' - ')
                                                            : 'No genres available'
                                                    }
                                                </p>
                                                <p className="movie_sun">{movie.country_name} - {movie.release_date}</p>



                                            </div>
                                        </div>
                                    </div>
                                    <div className="movie__title">
                                        <p>
                                            {movie.movie_title.split(' ').length > 2
                                                ? movie.movie_title.split(' ').slice(0, 2).join(' ') + '...'
                                                : movie.movie_title}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p style={{ color: "white", fontFamily: 'i-med' }}>No movies available in this Category.</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default KidsComponent;
