import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_BASE_URL } from '../../config';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import hd from '../../Images/flutter/hd.png'; // Ensure this image is required
import i9 from '../../Images/flutter/NewFlix Images/i9.png';
import screen from '../../Images/flutter/screen.png';
import eds from '../../Images/flutter/eds.png';
import headphone from '../../Images/flutter/headphone.png';
import plygon from '../../Images/flutter/Polygon.png';
import email from '../../Images/flutter/email.png';
import phone from '../../Images/flutter/phone.png';
import question from '../../Images/flutter/question.png';
import detail_pg from '../../Images/flutter/detail_pg.png';
import FlutterHomeScreenNav from './../FlutterPages/flutter_component/FlutterHomeScreenNav';
import FlutterBottomBar from "./flutter_component/FlutterBottomBar";
import FlutterBackHeader from "./FlutterBackHeader";

const FlutterCategories = () => {
    const { id } = useParams();
    const location = useLocation();
    const [listMoviesShowsGenre, setListMoviesShowsGenre] = useState([]); // Default empty array
    const [genresFlutter, setgenresFlutter] = useState([]); // Default empty array
    const navigate = useNavigate();
    const [pages_spinner, setpagesSpinnerFlutter] = useState(false);


    // Apply or remove class based on the route
    // useEffect(() => {
    //     if (location.pathname === `/flutter-categories`) {
    //         document.body.classList.add('no-black-background');
    //     } else {
    //         document.body.classList.remove('no-black-background');
    //     }
    //     return () => {
    //         document.body.classList.remove('no-black-background');
    //     };
    // }, [location.pathname]);

    useEffect(() => {
        setpagesSpinnerFlutter(true);

        axios.post(`${API_BASE_URL}api/v1/genres`, {})
            .then(response => {
                const genres = response.data.VIDEO_STREAMING_APP;
                setgenresFlutter(genres);
                setpagesSpinnerFlutter(false)
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    const ClickByGenre = (genre_all) => {
        navigate(`/flutter-genre-all-movie-serials/${genre_all.genre_id}`);
    } 
    return (
        <>  
            <FlutterBackHeader />

            <section className="genre_main_flutter">
                <div className="genre_main">
                    {
                        genresFlutter.map((genre_all, index) => (
                            <div onClick={()=>ClickByGenre(genre_all)} className="genre_image_flutter" style={{ backgroundImage: `url(${genre_all.genre_poster})` }}>
                                <div className="genre_name_flutter">
                                    <p>{genre_all.genre_name}</p>
                                </div>
                            </div>

                        ))
                    }
                </div>
            </section>
            
            {pages_spinner && (
                <div className="flutter_detail_page_spinner">
                    <div className="flutter_section_spinner">
                        <div class="flutter_spinned_sections"></div>
                    </div>
                </div>
             )} 
            {/* <FlutterBottomBar /> */}
        </>
    );
};

export default FlutterCategories;
