import { useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from '../../config';
import { Theme_Variable } from '../../config';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import hd from '../../Images/flutter/hd.png'; // Ensure this image is required
import i9 from '../../Images/flutter/NewFlix Images/i9.png';
import screen from '../../Images/flutter/screen.png';
import eds from '../../Images/flutter/eds.png';
import headphone from '../../Images/flutter/headphone.png';
import plygon from '../../Images/flutter/Polygon.png';
import email from '../../Images/flutter/email.png';
import phone from '../../Images/flutter/phone.png';
import question from '../../Images/flutter/question.png';
import FlutterBackHeader from "./FlutterBackHeader";
import { useTranslation } from "react-i18next";

const FlutterLogin = () => {
    const { id } = useParams();
    const location = useLocation();
    const [listMoviesShowsGenre, setListMoviesShowsGenre] = useState([]); // Default empty array
    const navigate = useNavigate();
    const {t} = useTranslation();

    // Apply or remove class based on the route
    // useEffect(() => {
    //     if (location.pathname == `/flutter-login` && Theme_Variable == 'Dark') {
    //         document.body.classList.add('no-black-background');
    //     } else if (location.pathname == `/flutter-login` && Theme_Variable == 'Light ') {
    //         document.body.classList.add('no-black-background');
    //     } else {
    //         document.body.classList.remove('no-black-background');
    //     }
    // }, [location.pathname]);


    const [isListOpen, setIsListOpen] = useState(false);

    const handleToggle = () => {
        setIsListOpen(!isListOpen);
    };

    // Login Work

    const [phoneNumber, setPhoneNumber] = useState('');
    const [alertResponse, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    // Login Work
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        localStorage.setItem("phoneNumberVerification", phoneNumber);

        axios.post(`${API_BASE_URL}api/v1/signup`, {
            phone: "+" + phoneNumber
        })
            .then(response => {
                setLoading(false);
                if (response.data.VIDEO_STREAMING_APP[0]['success'] == 1) {
                    setResponse(`${t("Success! Redirecting")}...`);
                    setTimeout(() => {
                        navigate("/flutter-login-otp");
                    }, 2000);
                } else {
                    setResponse(response.data.VIDEO_STREAMING_APP[0]['msg']);
                }
            })
            .catch(error => {
                setLoading(false);
                setResponse("There was an error submitting the phone number: " + error.message);
                console.error("Error:", error.message);
            });
    };
    return (
        <>
            <FlutterBackHeader />
            <section className="flutter_login_main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="title_login_flutter">
                                <h2>{t("Login Or SignUp")}</h2>
                            </div>
                            {/* login Form */}
                            <div className="login__form_flutter_main">
                                <div className="login__form_flutter">
                                    <p>{t("Login or signup with your information.")}</p>
                                    <div className="form_login_fl">
                                        <div className="input_flutter_icon_main">
                                            {/* <form onSubmit={handleSubmit}>
                                                <input type="text" placeholder="Mobile" className="login_filled_flutter" />
                                                <div className="icon_profile_inp">
                                                    <img src={eds} alt="" />
                                                </div>
                                                <input type="submit" value="Continue" className="login_flutter_btn" />
                                            </form> */}

                                            <form onSubmit={handleSubmit}>

                                                <input
                                                    type="text"
                                                    id="phoneNumber"
                                                    placeholder="Mobile"
                                                    className="login_filled_flutter"
                                                    value={phoneNumber}
                                                    onChange={(e) => setPhoneNumber(e.target.value)} // Store input in state
                                                    required
                                                />
                                                <div className="icon_profile_inp">
                                                    <img src={eds} alt="" />
                                                </div>
                                                <input type="submit" value={loading ? `${t("Loading")}...` : `${t("continue")}`} className="login_flutter_btn" disabled={loading} />
                                            </form>
                                            {alertResponse && <p className="err_flutter_login">{alertResponse}</p>} {/* Display response message */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Social contact  */}
                            <div className="social_contact_flutter_login">
                                <p className="m-0" onClick={handleToggle}>
                                    <div className="head_phone_icon">
                                        <img src={headphone} alt="" />
                                    </div>
                                    {t("Support")}
                                    <div className={`polygon ${isListOpen ? 'polygon_icon_rotate' : ''}`}>
                                        <img src={plygon} alt="" />
                                    </div>
                                </p>
                                <div className={`social_contact_list_flutter ${isListOpen ? 'social_contact_list_flutter_active' : ''}`}>
                                    <ul>
                                        <li>
                                            <div className="list_icons_flutter">
                                                <img src={email} alt="" />
                                            </div> {t("Email")} : <Link>newflix@gmail.com</Link>
                                        </li>
                                        <li>
                                            <div className="list_icons_flutter">
                                                <img src={phone} alt="" />
                                            </div>   {t("phoneNumber", { defaultValue: "Phone" })}: <Link to="tel:0215652455">0215652455</Link>
                                        </li>
                                        <li>
                                            <div className="list_icons_flutter">
                                                <img src={question} alt="" />
                                            </div> {t("FAQ")} : <Link>newflix@gmail.com</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FlutterLogin;
