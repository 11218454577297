import styled from "styled-components";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Imdb from '../Images/imdb.png';
import lock2 from '../Images/lock.webp'
import caster1 from '../Images/home_images/profile.jpg';
import movieimg from '../Images/movies/movie-2.webp';
import rating from '../Images/rating.jpg';
import '../Components/css/style.css';
import { Link } from "react-router-dom";
import Allmovies from '../Components/Allmovies';
import MovieTrailerPlayer2 from "../Components/MovieTrailerPlayer2";
import axios from "axios";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
import ShowTrailerplayer from '../Components/ShowTrailerplayer'
import { API_BASE_URL } from '../config';
import default_user from '../Images/default-user.webp';
import cinema_poster from '../Images/cinema.webp';


const Showsdetail = () => {
    const location = useLocation();
    const [activeIndex, setActiveIndex] = useState(null);
    const genre_id = location.state?.params_id;
    const { id } = useParams();
    const [showDetail, setShowDetail] = useState({});
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [seasons, setseasons] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [allcomments, setallcomments] = useState([]);
    const [faq, setfaq] = useState([]);
    const UserId = localStorage.getItem('user_id') || '';


    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    useEffect(() => {
        setLoading(true);
        axios.post(`${API_BASE_URL}api/v1/show_details`, {
            'show_id': id
        })
            .then(response => {
                setLoading(false);
                let setShowDetails = response.data.VIDEO_STREAMING_APP;
                setShowDetail(setShowDetails);
                console.log(setShowDetails)
            })
            .catch(error => {
                setLoading(false);
                console.error('There was an error making the request:', error);
            });
        // Seasons Api
        axios.post(`${API_BASE_URL}api/v1/seasons`, {
            'show_id': id
        })
            .then(response => {
                let setSeasons = response.data.VIDEO_STREAMING_APP;
                setseasons(setSeasons);
                // console.log(setSeasons)
            })
            .catch(error => {
                console.error('There was an error making the request:', error);
            });


        axios.post(`${API_BASE_URL}api/v1/shows`, {
            filter: 'new'
            // filter: 'rand'
        })
            .then(response => {
                let random_shows = response.data.VIDEO_STREAMING_APP.shows.slice(0, 8);
                setSuggestionShows(random_shows); // Use setSuggestionMovies to update the state
                console.log(random_shows)
            })
            .catch(err => {
                console.log('There was an error making the request:', err);
            });
        //  faqs Api

        axios.post(`${API_BASE_URL}api/v1/faqs`, {
            model: 'Series',
            model_id: id,
        })
            .then(response => {
                //  console.log(response.data.VIDEO_STREAMING_APP.faq);
                setfaq(response.data.VIDEO_STREAMING_APP.faq);
            })
            .catch(err => {
                console.log("Faqs fetching error " + err);
            });
        listComments();


    }, [id]);

    const listComments = () => {
        axios.post(`${API_BASE_URL}api/v1/list_comment`, {
            commentable_id: id,
            commentable_type: 'Series',
            user_id: UserId,
        })
            .then(response => {
                let all_comments = response.data.VIDEO_STREAMING_APP;
                setallcomments(all_comments);
            })
            .catch(err => {
                console.log('There was an error making the request:', err);
            });
    };

    const [suggestionShows, setSuggestionShows] = useState([]);


    useEffect(() => {
        // JavaScript to add Font Awesome icons
        const prevButtons = document.querySelectorAll('.swiper-button-prev');
        const nextButtons = document.querySelectorAll('.swiper-button-next');
        prevButtons.forEach(button => {
            button.innerHTML = '<i class="fas fa-chevron-left"></i>'; // Font Awesome left chevron
        });
        nextButtons.forEach(button => {
            button.innerHTML = '<i class="fas fa-chevron-right"></i>'; // Font Awesome right chevron
        });
    }, []);


    const [openModal, setOpenModal] = useState(false);
    const [CommentMessage, setCommentMessage] = useState('');
    const openLoginModal = (e) => {
        e.preventDefault();

        if (UserId) {
            saveCommentToAPI();
        } else {
            setOpenModal(true);
        }
    };

    const saveCommentToAPI = () => {
        const comment = document.getElementById('comment_body').value;
        axios.post(`${API_BASE_URL}api/v1/add_comment`,
            {
                content: comment,
                commentable_id: id,
                commentable_type: 'Series',
                user_id: UserId,
            }
        ).then(response => {
            setCommentMessage(response.data.VIDEO_STREAMING_APP[0].msg);
            // console.log('Comment saved successfully:', response.data);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        })
            .catch(error => {
                console.error('Error saving comment:', error);
            });
    };

    const closeLoginModal = () => {
        setOpenModal(false)
    }
    // Like Comment  
    const [likesData, setLikesData] = useState({});

    const likeComment = (comment_id) => {
        axios.post(`${API_BASE_URL}api/v1/like_comment`, {
            comment_id: id,
            user_id: UserId || '',
        })
            .then(response => {
                LikeDislikeCount(comment_id);
                setCommentMessage('Comment Liked');
                setTimeout(() => {
                    setCommentMessage('');
                }, 2000);
            })
            .catch(error => {
                console.error('Error liking comment:', error);
            });
    };

    // Dislike Comment
    const dislikeComment = (comment_id) => {
        axios.post(`${API_BASE_URL}api/v1/unlike_comment`, {
            comment_id: comment_id,
            user_id: UserId || '',
        })
            .then(response => {
                // After disliking, fetch the updated counts
                LikeDislikeCount(comment_id);
                setCommentMessage('Comment disliked');
                setTimeout(() => {
                    setCommentMessage('');
                }, 2000);
            })
            .catch(error => {
                console.error('Error disliking comment:', error);
            });
    };
    const LikeDislikeCount = (comment_id) => {
        axios.post(`${API_BASE_URL}api/v1/list_comment_like_unlike`, {
            commentable_id: comment_id,
            commentable_type: 'Series',
        })
            .then(response => {
                // Extract like and dislike counts from the response
                const data = response.data.VIDEO_STREAMING_APP;
                const like_count = data[0]?.total_likes || 0;
                const dislike_count = data[1]?.total_unlikes || 0;
                setLikesData(prevData => ({
                    ...prevData,
                    [comment_id]: { like_count, dislike_count }
                }));
            })
            .catch(error => {
                console.error('Error fetching like/dislike count:', error);
            });
    };
    useEffect(() => {
        allcomments.forEach(comment => {
            LikeDislikeCount(comment.id);
        });
    }, [allcomments]);
    // Function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);

        // Options to format the date as "MonthName - Day - Year"
        const options = { year: 'numeric', month: 'long', day: 'numeric' };

        return date.toLocaleDateString('en-US', options).replace(',', ' -');
    };

    const [isActiveDiscount, setIsActiveDiscount] = useState(false);
    const [isActiveDiscountModal, SetisActiveDiscountModal] = useState(false);
    const [subscriptionCode, setSubscriptionCode] = useState('');

    // Toggle function
    const toggleActiveForm = () => {
        setIsActiveDiscount(!isActiveDiscount); // Toggle the state
    };
    const openCinemaModal = () => {
        SetisActiveDiscountModal(true);
    };

    // Function to close the cinema modal
    const closeMoviecinema = () => {
        SetisActiveDiscountModal(false);
    };

    // verify Cinema copun code
    const [couponCode, setCouponCode] = useState('');
    const [CouponCode, SetCouponCode] = useState(null);
    const [alertMsg, SetalertMsg] = useState('');

    const handleInputChange = (e) => {
        setCouponCode(e.target.value);
    };

    const saveSubscriptionCode = () => {
        if (!couponCode) {
            SetalertMsg('Please enter a coupon code')
            return;
        }
        setLoading(true); // Start loading state
        axios.post(`${API_BASE_URL}api/v1/apply_coupon_code`, { coupon_code: couponCode })
            .then(response => {
                setLoading(false); // Stop loading state
                if (response.data.VIDEO_STREAMING_APP[0].success == 0) {
                    SetalertMsg(response.data.VIDEO_STREAMING_APP[0].msg);
                } else {
                    SetalertMsg(response.data.VIDEO_STREAMING_APP[0].msg);
                    localStorage.setItem('coupon_code', couponCode)
                    localStorage.setItem('coupon_percentage', response.data.VIDEO_STREAMING_APP[0].coupon_percentage)
                }
                SetCouponCode(response.data.VIDEO_STREAMING_APP); // Handle the API response
            })
            .catch(err => {
                setLoading(false); // Stop loading state
                console.log("something went wrong:" + err);
                alert('Invalid coupon code');
            });
    };

    const redirectCinemaPage = () => {
        localStorage.setItem('cinema_id', showDetail.show_id);
        localStorage.setItem('ticket_price', parseInt(showDetail.show_ticket_price));
        localStorage.setItem('cinema_type', 'Series');
        window.location.href = '/payment-process-cinema';
    };

    const [checkUserCinemaHas, SetcheckUserCinemaHas] = useState(0);

    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/shows_user_cinema_list`, { user_id: UserId || '' })
            .then(response => {
                let movieExists = false; // Initialize as false

                response.data.VIDEO_STREAMING_APP.shows.forEach(show => {
                    if (show.show_id == showDetail.show_id) {
                        SetcheckUserCinemaHas(1);
                        console.log("show 1")
                    }
                });
            })
            .catch(err => {
                console.log("something went wrong:" + err);
            });
    }, [UserId, showDetail.show_id]);


    const seasonsRef = useRef(null);
    const scrollToSeasons = () => {
        if (seasonsRef.current) {
            seasonsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <section className="single__movie-detail">
                <div className="js__movie_poster">
                    <div className="single__movie-banner" data-aos="fade-up" data-aos-duration="2000">
                        <div className="video_trailer__wrapper">
                            <ShowTrailerplayer data={showDetail} sourceUrl={showDetail.show_trailer_url} poster={showDetail.show_poster} />
                            {/* <video playsInline preload="none" loop muted className="trailervideo" width="100%" height="auto" controls>
                                <source src={showDetail.show_trailer_url} type="video/mp4" />
                                <source src={showDetail.show_trailer_url} type="video/ogg" />
                            </video> */}
                        </div>
                        <div className="details_overlay d-overlay"></div>
                        <div className="details_overlay-two d-overlay"></div>
                        <div className="details_wrapper" >
                            <div className="details_poster d-flex">
                                <div className="js__details_poster-thumb details_poster-thumb">
                                    <div className="ds-thumb on-hover">
                                        <div className="ds-thumb_inner">
                                            <div className="ds-media ds-thumb_media" onClick={scrollToSeasons}>
                                                <img class="ds-media_image lazyloaded" src={showDetail.show_poster} style={{ direction: 'ltr', textAlign: 'left' }} loading="lazy" />

                                                {/* <div className="ds-thumb_content_inner"> */}
                                                {/* <a href="https://www.filimo.com/signin?afterlogin=watch&amp;uid=zkjbr" className="overlay--transparent" ></a> */}
                                                <div className="ds-thumb_content_middle ta-center on-hover_show">
                                                    <button className="ui-btn-purchase ui-btn-circle ui-btn" tabindex="-1">
                                                        <i class="fa-solid fa-play"></i>
                                                    </button>
                                                </div>
                                                {/* </div> */}
                                            </div>

                                            {/* <div className="ds-thumb_content_inner">
                                                    <a href="https://www.filimo.com/signin?afterlogin=watch&amp;uid=zkjbr" className="overlay--transparent" ></a>
                                                    <div className="ds-thumb_content_middle ta-center on-hover_show">
                                                        <button className="ui-btn-purchase ui-btn-circle ui-btn" tabindex="-1">
                                                             <i class="fa-solid fa-play"></i>
                                                        </button>
                                                    </div>
                                                </div> */}
                                            <span className="overlay on-hover_show"></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="details_poster-description d-flex ui-fd-c">

                                    <div className="" id="movieToggleDetails">
                                        <h1 className="details_poster-description-title">
                                            <div className="fa-title ui-fw-semibold">
                                                <span className="d-inline-flex movie_detail_title" style={{ direction: 'ltr', textAlign: 'left' }}>{showDetail.show_name}</span>
                                                <span className="ui-icons ui-icon-arrow_down_ios ui-bg-white ui-br-24 ui-p-x d-none mobile_accordion-icon ui-icon-size-14">
                                                </span>
                                            </div>
                                            <p className="en-title ui-fw-normal ui-fs-medium force-text-en d-inline-bock"></p>
                                        </h1>
                                    </div>

                                    <div className="details_poster-description-rating"></div>

                                    <div className="details_poster-description-age ui-fc-primary ui-fi-primary d-inline-flex">
                                        <span className="age-badge ui-ml-x age-three">
                                        </span>
                                        <span className="sutable_age_text" style={{ direction: 'ltr', textAlign: 'left' }}> Suitable for over {showDetail.content_rating != '' ? showDetail.content_rating : '18'} year old </span>
                                    </div>

                                    <div className="details_poster-description-meta">
                                        <table className="details-v2_mobile-accordion w100">
                                            <tbody>
                                                <tr className="details_poster-description-director ui-mb-4x d-flex ui-ai-c md-ui-jc-c" >
                                                </tr>
                                                <tr className="details_poster-description-more ui-mb-6x d-flex">
                                                    <th className="ui-fw-normal" style={{ direction: 'ltr', textAlign: 'left' }}> Produced <a href="#">in {showDetail.country_name}</a> - {showDetail.release_date} - HD Quality
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="d-flex ui-ai-c details_poster-description-badge">
                                            <ul className=" d-flex ui-ai-c list-unstyled p-0">
                                                {
                                                    showDetail.genre_list && showDetail.genre_list.length > 0 ? (
                                                        showDetail.genre_list.map((showGenre, index) => (
                                                            <li className="ui-ml-2x" key={index}>
                                                                <a href="#" className="ui-btn ui-btn-force-dark ui-btn-small ui-btn ui-br-24 ui-pr-2x ui-pl-2x ui-pt-x ui-pb-x ui-bg-gray-20 details_poster-description-meta-link genre_detail_badge" style={{ direction: 'ltr', textAlign: 'left' }}>
                                                                    <span>{showGenre.genre_name}</span>
                                                                </a>
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <li>No genres available</li>
                                                    )
                                                }

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* {
                                    showDetail.show_cinema == 1 ? (

                                        <div className="details_poster-cinema ui-mr-auto ui-pr-2x ui-pl-2x ui-pt-4x ui-pb-4x ps-relative">
                                            <div className="details_poster-cinema-img">
                                                <img src={cinema_poster} alt="" class="w-100" />
                                            </div>
                                            <div className="details_poster-cinema-info text-center">
                                                <p class="movie-release ui-mb-6x  ui-mb-6x ">
                                                    Online screening of movies
                                                </p>
                                                <ul className="list-unstyled p-0 m-0">
                                                    <li className="d-flex ui-ai-center ui-mb-2x">
                                                        <span><i class="fa-solid fa-film"></i></span>
                                                        <p className="p-0 m-0">Movies of the day in Iranian cinema</p>
                                                    </li>
                                                    <li className="d-flex ui-ai-center ui-mb-2x">
                                                        <span><i class="fa-regular fa-clock"></i></span>
                                                        <p className="p-0 m-0">8 hours of time to watch movies anywhere</p>
                                                    </li>
                                                    <li className="d-flex ui-ai-center ui-mb-2x">
                                                        <span> <i class="fa-solid fa-ticket"></i></span>
                                                        <p className="p-0 m-0">Buy a ticket and watch with family members</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )
                                } */}

                            </div>
                            {/* Actions */}
                            <div className="details_actions ui-pt-6x d-flex sm-ui-pt-0">
                                <div className="details_actions-right d-flex ui-ai-c">
                                    {/* <div id="bookmark-wrapper" className="movie-bookmark-zkjbr ui-ml-2x sm-ui-ml-0 d-inline-flex" data-anl="fallow">
                                        <button id="bookmark-zkjbr"
                                            className="request-link ui-btn ui-btn-force-dark ui-btn-small ui-btn details_actions-right-link ui-br-24 mobile-bg-transparent nar_bookmark js__bookmark lock-link set-query open-modal is-ajax-button" style={{ direction: 'ltr' }}>
                                            <i className="fa-regular fa-bookmark"></i>
                                            <span className="sm-ui-pt-2x label">I'll see later</span>
                                        </button>
                                        <span className="ui-icons ui-icon-bookmark ui-icon-size-18"></span>
                                        <span className="ui-icons ui-icon-bookmark_check_fill ui-icon-size-18"></span>
                                    </div> */}

                                    {/* <a href="#" className="ui-btn ui-btn-force-dark ui-btn-small ui-btn ui-br-24 details_actions-right-link ui-ml-2x sm-ui-ml-0 nar_dl open-modal episodev2_dl-btn d-block" style={{ direction: 'ltr' }}>
                                        <i className="fa-solid fa-cloud-arrow-down"></i>
                                        <span className="sm-d-block sm-ui-pt-2x label">Download</span>
                                    </a> */}


                                    <div id="rate-mobile-wrapper" className="d-inline-flex">
                                        <button id="mobileRateButton"
                                            className="request-link ui-btn ui-btn-force-dark ui-btn-small ui-btn mobile-bg-transparent details_actions-right-link nar_like open-modal js__movie-rate lock-link set-query d-none sm-d-flex is-ajax-button">
                                            <span className="ui-icons ui-icon-like_dislike ui-icon-size-18">
                                                +
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                {/* { */}
                                {/* showDetail.show_cinema != 1 ? ( */}
                                <div className="details_actions-left d-flex ui-ai-c" data-anl="watch-cta">
                                    <div className="ps-relative parent_watch-btn">
                                        <a onClick={scrollToSeasons} id="login-to-watch"
                                            className="ui-btn-purchase ui-btn-small ui-btn ui-br-24 js_show_restricted ui-mr-2x sm-ui-mr-0 sm-ui-mb-2x mobile_btn nar_btn js__hover-show-tooltip ui-br-24" style={{ direction: 'ltr' }}>
                                            <i className="fa-solid fa-play"></i>
                                            <span>watch show</span>
                                        </a>
                                    </div>
                                </div>
                                {/* ) :  */}
                                {/* // ( */}
                                {/* //     checkUserCinemaHas != 1 ? ( */}

                                {/* <div className="details_actions-left d-flex ui-ai-c" data-anl="watch-cta">
                                            <div className="ps-relative parent_watch-btn">
                                                <Link
                                                    onClick={openCinemaModal} // Call the function here
                                                    to="#"
                                                    id="cinema_movie_btn"
                                                    className='ui-btn-purchase ui-btn-small ui-btn ui-br-24 js_show_restricted ui-mr-2x sm-ui-mr-0 sm-ui-mb-2x mobile_btn nar_btn js__hover-show-tooltip ui-br-24'
                                                    style={{ direction: 'ltr', cursor: 'pointer' }}
                                                >
                                                    <i className="fa-solid fa-play"></i>
                                                    <span>Watch Cinema show</span>
                                                </Link>
                                            </div>
                                        </div>
                                    //     ):( */}

                                {/* //         <div className="details_actions-left d-flex ui-ai-c" data-anl="watch-cta">
                                    //         <div className="ps-relative parent_watch-btn">
                                    //             <Link */}
                                {/* //                 to="#"
                                    //                 id="cinema_movie_btn"
                                    //                 className='ui-btn-purchase ui-btn-small ui-btn ui-br-24 js_show_restricted ui-mr-2x sm-ui-mr-0 sm-ui-mb-2x mobile_btn nar_btn js__hover-show-tooltip ui-br-24'
                                    //                 style={{ direction: 'ltr', cursor: 'pointer' }}
                                    //             >
                                    //                 <i className="fa-solid fa-play"></i>
                                    //                 <span>Watch Cinema show</span>
                                    //             </Link>
                                    //         </div>
                                    //     </div>
                                    //   ) */}

                                {/* ) */}
                                {/* } */}


                            </div>


                        </div>
                    </div>
                </div>
            </section>
            {/* Movie Detail Story */}
            <section className="movie__detail-story">
                <div className="container-fluid">
                    <div className="video-inner-wrapper">
                        <div className="row align-items-center">
                            <div className="col-md-12 col-lg-5">
                                <div className="movie__detail-title" data-aos="fade-up" data-aos-duration="2000">
                                    <h2>The about of the {showDetail.show_name}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: showDetail.show_info }} />
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-7 text-center text-lg-right order-first order-lg-last" data-aos="fade-up" data-aos-duration="2000">
                                <div className="show_poster">
                                    {/* <img src={showDetail.show_poster} alt="" className="w-100" /> */}
                                    <MovieTrailerPlayer2 poster={showDetail.show_poster} sourceUrl={showDetail.show_trailer_url} />

                                    {/* <div className="show_over_lay"></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Seasons */}
            <section className="show__seasons" ref={seasonsRef}>
                <div className="container-fluid">
                    <div className="video-inner-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="seasons__main-title">
                                    <h2>All Seasons of {showDetail.show_name}</h2>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {
                                    seasons.length > 0 ? (
                                        seasons.map((seasonsAll, index) => (
                                            <div className="season__main" key={index}
                                                onClick={() => navigate(`/episodes/${seasonsAll.season_id}`)}
                                            >
                                                <div className="row align-items-center">
                                                    <div className="col-12 col-sm-6 col-lg-3">
                                                        <div className="season__image">
                                                            <img src={seasonsAll.season_poster} alt="" className="w-100" />
                                                            <div className="season_poster_icon">
                                                                <div className="season_poster_play">
                                                                    <i class="fa-solid fa-play"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-9">
                                                        <div className="seasons_title">
                                                            <p className="m-0">{seasonsAll.season_name}</p>
                                                        </div>
                                                        <div className="seasons_paragraph">
                                                            {/* {suggestions.movie_title.split(' ').length > 2
                                                            ? suggestions.movie_title.split(' ').slice(0, 2).join(' ') + '...'
                                                            : suggestions.movie_title} */}
                                                            <p className="m-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, nemo debitis. Praesentium architecto quae excepturi. Aliquam aperiam voluptas in quod possimus culpa quas repellendus neque reiciendis? Delectus inventore iusto est.</p>
                                                        </div>
                                                        <div className="season__actions">
                                                            <Link to="#" className="show_watch-btn">
                                                                <i class="fa-solid fa-play"></i>
                                                                watch
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="seasons_title text-center">
                                            <p className="m-0">Shows Not Found</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Recommended Videos */}
            <section className="details__movie-recommend">
                <div className="container-fluid">
                    <div className="video-inner-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="details__movie-recommend-title text-right" >
                                    <h2>Recommended Movies</h2>
                                </div>
                            </div>
                        </div>
                        <div className="slider_recommend" >
                            <div className="swiper-navigation">
                                <div className="swiper-button-prev"></div>
                                <div className="swiper-button-next"></div>
                            </div>
                            {/* Swiper Component */}
                            <Swiper
                                slidesPerView={5}
                                spaceBetween={10}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={{
                                    prevEl: '.swiper-button-prev',
                                    nextEl: '.swiper-button-next',
                                }}
                                breakpoints={{
                                    100: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    480: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    992: {
                                        slidesPerView: 5,
                                        spaceBetween: 10,
                                    },
                                }}
                                autoplay={{
                                    delay: 500,
                                }}
                                modules={[Navigation]}
                                className="recommendation_swiper"
                            >
                                {

                                    suggestionShows.map((suggestions, index) => (
                                        <SwiperSlide>
                                            <div className="recommendation_card" key={index} onClick={() => navigate(`/show-detail/${suggestions.show_id}`)}>
                                                <div className="recommend__movie-img recommendation_card_shows hover_show">
                                                    <div className="serial__over" style={{ backgroundImage: `url(${suggestions.show_poster})` }}></div>
                                                    <div className="serial__over_2" style={{ backgroundImage: `url(${suggestions.show_poster})` }}></div>
                                                    <img src={suggestions.show_poster} alt="" loading="lazy" />
                                                    <div className="recommend__movie-overlay show_overlay">
                                                        <div className="recommend-movie__info">
                                                            {/* <p className='duration'>Duration: {suggestions.movie_duration}</p>
                                                            <p className='rating d-block text-center'>{suggestions.movie_access}</p> */}
                                                            <p>
                                                                {
                                                                    suggestions.genre_list && suggestions.genre_list.length > 0
                                                                        ? suggestions.genre_list.slice(0, 2).map(genres => genres.genre_name).join(' - ')
                                                                        : 'No genres available'
                                                                }
                                                            </p>
                                                            <p className="movie_sun">{suggestions.country_name} - {suggestions.release_date}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="movie_recommend">
                                                    <p>
                                                        {suggestions.show_title.split(' ').length > 2
                                                            ? suggestions.show_title.split(' ').slice(0, 2).join(' ') + '...'
                                                            : suggestions.show_title}
                                                    </p>
                                                </div>
                                            </div>

                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            {/* Recommended Videos */}
            {/* faqs */}
            {/* Faqs */}
            {
                faq.length > 0 && (
                    <section className="faqs__section faqs_for_videos">
                        <div className="container-fluid">
                            <div className="video-inner-wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="faqs__title text-right">
                                            <h2 className="text-right">Faq</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="faqs">
                                    <div className="row justify-content-center">
                                        <div className="col-md-12 videos_faqs_top">
                                            {faq.map((faq, index) => (
                                                <div className="videos_faqs_main" onClick={() => handleToggle(index)} key={index}>
                                                    <div className="videos_faq__question">
                                                        <div className="videos_faq_icon">
                                                            <p>
                                                                {activeIndex === index
                                                                    ? <i className="fa-solid fa-minus"></i>
                                                                    : <i className="fa-solid fa-plus"></i>}
                                                            </p>

                                                        </div>
                                                        <p className={activeIndex === index ? 'videos_question_active' : ''} dangerouslySetInnerHTML={{ __html: faq.faq_title }} />
                                                    </div>
                                                    <div className={`video_faq__answer ${activeIndex === index ? 'video_faqs_show' : ''}`}>
                                                        <p dangerouslySetInnerHTML={{ __html: faq.faq_description }} />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }

            {/*  */}
            <section className="film__cast-section">
                <div className="container-fluid">
                    <div className="video-inner-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="film_cast-title text-right" >
                                    <h2>Cast of the {showDetail.show_name}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="film_casts">
                            <div className="casters__main">
                                {
                                    showDetail.actor_list && showDetail.actor_list.length > 0 ? (
                                        showDetail.actor_list.map((actor, index) => (
                                            <div className="caster__child" key={index}>
                                                <div className="film_caster-img">
                                                    <img src={actor.ad_image} alt={actor.ad_name} loading="lazy" />
                                                </div>
                                                <div className="film_caster-title">
                                                    <p className="m-0">{actor.ad_name}</p>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="caster__child">
                                            <div className="film_caster-img">
                                                <img src={caster1} />
                                            </div>
                                            <div className="film_caster-title">
                                                <p className="m-0">No Actor Found</p>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Films Casts */}
            {/* othe Elements of Movie */}
            <section className="other__element-of-movie">
                <div className="container-fluid">
                    <div className="video-inner-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="o__element-title text-right" >
                                    <h2>Other Elements of the {showDetail.show_name} Movie</h2>
                                </div>
                            </div>
                        </div>
                        <div className="other__element-names">
                            <div className="elements_main">
                                {
                                    showDetail.director_list && showDetail.director_list.length > 0 ? (
                                        showDetail.director_list.map((director, index) => (
                                            <div className="elements__child" key={index}>
                                                <div className="role">
                                                    <p>Director Name</p>
                                                </div>
                                                <div className="name_element">
                                                    <p>{director.ad_name}</p>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="elements__child">
                                            <div className="role">
                                                <p>Director Name</p>
                                            </div>
                                            <div className="name_element">
                                                <p>No director found</p>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* User Views Comments  */}
            <section>
                <div className="container-fluid">
                    <div className="video-inner-wrapper">
                        <div className="user__comment_wrapper">
                            <h2>Views of Song of Wish 2 (Music Album of Wish Animation)</h2>
                        </div>
                        <div className="comment__form ui-mb-6x ui-pr-2x">
                            <div className="user_avatar ui-br-circle">
                                <img src={default_user} alt="" />
                            </div>
                            <div className="comment__form_wrapper ps-relative ">
                                <form id="add-new-comment--form" onSubmit={(e) => {
                                    e.preventDefault();
                                    openLoginModal(e);
                                }} className="form ui-br-4 d-inline-block w100 ps-relative" >
                                    <div className="form-field ">
                                        <textarea name="comment_body" className="form-textarea" maxlength="800" id="comment_body" style={{ textAlign: 'start', direction: 'rtl', width: '100%' }} placeholder="How was the Film">
                                        </textarea>
                                    </div>
                                    <button type="submit" id="send-comment--button">
                                        <i className="fa-solid fa-paper-plane"></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                        {/* All Comments */}
                        <section className="all__comments-sections">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 p-0">
                                        <ul className="js__user_comment-list list-unstyled">
                                            {allcomments.length > 0 ? (
                                                allcomments.map((comment, index) => (
                                                    <li className="comment__item" key={index}>
                                                        <div className="comment__right-side">
                                                            <div className="user__comment_info">
                                                                <div className="comment_pic">
                                                                    <img src={default_user} alt="" />
                                                                </div>
                                                                <div className="comment_info">
                                                                    <span className="contact_date">
                                                                        {formatDate(comment.created_at)}
                                                                    </span>
                                                                    <span className="contact_name">
                                                                        {comment.user.name}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="rate_comment">
                                                                <span>
                                                                    <i className="fa-solid fa-thumbs-up" onClick={() => likeComment(comment.id)}></i>
                                                                    <span className="like_count">
                                                                        {likesData[comment.id]?.like_count || 0}
                                                                    </span>
                                                                </span>
                                                                <span>
                                                                    <i className="fa-solid fa-thumbs-down" onClick={() => dislikeComment(comment.id)}></i>
                                                                    <span className="dislike_count">
                                                                        {likesData[comment.id]?.dislike_count || 0}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="comment_body">
                                                            <p>{comment.content}</p>
                                                        </div>
                                                    </li>
                                                ))
                                            ) : (
                                                <span className="no-comments">No Comments here</span>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
            {
                loading &&
                <div className="full-screen_loader">
                    <div class="spinner-border spinner_color mt-2 text-center" role="status"></div>
                </div>
            }

            {/* Login popup */}
            <section className={`login__popup-wrapper ${openModal ? 'active_modal_login' : ''}`}>
                <div className="container">
                    <div className="row justify-content-center px-3">
                        <div className="modal_login_popup">
                            <img src={lock2} className="lock-img" alt="" />
                            <p className="lock-text">To write your opinion and critique about This movie you must first enter in NewFlix</p>
                            <Link to="/login" id="login-button" href="#" class="ui-btn-primary ui-btn lock-link js_login-modal-btn">Login or Register</Link>
                            <div className="close_modal" onClick={closeLoginModal}>
                                <i class="fa-solid fa-xmark"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Message popup */}
            <div className={`message_popup ${CommentMessage ? 'message_popup_active' : ''}`}>{CommentMessage}</div>
            {/* Cinema Coupun Code Popup */}
            <section className={`cenima_discount-popup ${isActiveDiscountModal ? 'cenima_discount-popup-active' : ''}`}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="modal_subscription">
                            <div className="top_suscription d-flex">
                                <div className="cinema_img">
                                    <img src={showDetail.show_poster} alt="" className="w-100" />
                                </div>
                                <div className="info_sub">
                                    {/* <p dangerouslySetInnerHTML={{
                                        __html: showDetail.description
                                            ? movieDetail.description.split(' ').slice(0, 40).join(' ') + '...'
                                            : ''
                                    }} /> */}
                                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt atque placeat eligendi explicabo ullam quaerat porro, quod fugit ex repellat ratione odio</p>

                                </div>
                            </div>
                            <div className="deep_info_cinema">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam animi aut ut.</p>
                                <div className="deep_info_list">
                                    <ul className="list-unstyled">
                                        <li><span>Ticket Price</span> <span style={{ direction: 'ltr' }}>{showDetail.show_ticket_price} IQd</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="discount_code_cinema">
                                <div className="discount_uper_head" onClick={toggleActiveForm}>
                                    <p>Enter the Subscription code below.</p>
                                    <i className="fa-solid fa-angle-down"></i>
                                </div>

                                {/* Conditionally add the active class */}
                                <div className={`subscription_discount_form ${isActiveDiscount ? "active_subscription_discount_form" : ""}`}>
                                    <input type="text" placeholder="Enter the discount code" className="input_filed_subscription_discount" value={couponCode} onChange={handleInputChange} />
                                    <input type="button" value="code verify" className="subscription_discount_btn" onClick={saveSubscriptionCode} />
                                </div>
                                <div className={`alert_msg_subscription ${alertMsg != null ? 'active_msg_sub' : ''} `}>
                                    {loading && alertMsg &&
                                        <div class="spinner-border spinner_color mb-3" role="status"></div>
                                    }
                                    <p className='m-0'>{alertMsg}</p>
                                </div>
                            </div>
                            <div className="details_actions-left d-flex ui-ai-c mt-3" data-anl="watch-cta">
                                <div className="ps-relative parent_watch-btn">
                                    <Link
                                        to="#"
                                        id="cinema_movie_btn"
                                        className="ui-btn-purchase ui-btn-small ui-btn ui-br-24 js_show_restricted ui-mr-2x sm-ui-mr-0 sm-ui-mb-2x mobile_btn nar_btn js__hover-show-tooltip ui-br-24"
                                        style={{ direction: 'ltr' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            redirectCinemaPage();
                                        }}
                                    >
                                        <i className="fa-solid fa-play"></i>
                                        <span>watch Cinema Movie</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="close_discount_code" onClick={closeMoviecinema}>
                                <i class="fa-regular fa-circle-xmark"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )

}
export default Showsdetail;