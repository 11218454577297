import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import caster1 from '../../Images/home_images/profile.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
import lock from '../../Images/home_images/lock.webp';
import Movieplayer from "../../Components/Movieplayer";
import { API_BASE_URL } from '../../config';
import FlutterPlayer from "../../Components/FlutterPlayer";

const FlutterWatchEpisode = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const UserId = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const seasonId = searchParams.get('season_id');
    const [EpisodeBySeasons, setEpisodeBySeasons] = useState([]);
    // Use camelCase for the JavaScript variables

    // console.log('Season ID:', seasonId);

    const [watchepisode, setEpisodewatch] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checkplansuscription, setcheckplansuscription] = useState('');

    useEffect(() => {

        setLoading(true);
        axios.post(`${API_BASE_URL}api/v1/episodes_details`, {
            user_id: UserId || '',
            season_id: seasonId,
            episode_id: id,
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                setLoading(false);
                setEpisodewatch(response.data.VIDEO_STREAMING_APP[0] || []);
                // console.log(response.data.VIDEO_STREAMING_APP[0])
            })
            .catch(err => {
                setLoading(false);
                console.error("Something went wrong:", err);
            });

        if (UserId) {
            axios.post(`${API_BASE_URL}api/v1/check_user_plan`, {
                user_id: UserId
            })
                .then(response => {
                    let checkPlan = response.data.VIDEO_STREAMING_APP;
                    setcheckplansuscription(checkPlan.msg);
                    // console.log(checkPlan);
                }).catch(err => {
                    console.log(err);
                })
        }
        axios.post(`${API_BASE_URL}api/v1/episodes`, {
            user_id: UserId,
            season_id: seasonId
        }).then(response => {
            setEpisodeBySeasons(response.data.VIDEO_STREAMING_APP)
            // console.log(response.data.VIDEO_STREAMING_APP);
        }).catch(err => {
            console.log("fetching all episodes error" + err);
        })

    }, [UserId, seasonId, id]);

    const [suggestionShows, setSuggestionShows] = useState([]);

    useEffect(() => {
        // JavaScript to add Font Awesome icons
        const prevButtons = document.querySelectorAll('.swiper-button-prev');
        const nextButtons = document.querySelectorAll('.swiper-button-next');
        prevButtons.forEach(button => {
            button.innerHTML = '<i class="fas fa-chevron-left"></i>'; // Font Awesome left chevron
        });
        nextButtons.forEach(button => {
            button.innerHTML = '<i class="fas fa-chevron-right"></i>'; // Font Awesome right chevron
        });
    }, []);

    useEffect(() => {
        setLoading(true);

        axios.post(`${API_BASE_URL}api/v1/shows`, {
            filter: 'new'
            // filter: 'rand'
        })
            .then(response => {
                let random_shows = response.data.VIDEO_STREAMING_APP.shows.slice(0, 8);
                setSuggestionShows(random_shows); // Use setSuggestionMovies to update the state
                console.log(random_shows)
            })
            .catch(err => {
                console.log('There was an error making the request:', err);
            });

    }, [id]);


    return (
        <>
            <section className="watch__movie videos-inner-wrapper p-0">
                <div className="container-fluid p-0">
                    <div className="p-0" style={{ padding: 0 }}>
                        <div className="row">
                            <div className="col-md-12 p-0">
                                {

                                    (checkplansuscription == 'My Subscription' || watchepisode.video_access == 'Free') ? (
                                        <div className="video__screen video_screen_on_mobile">
                                            {/* <FlutterPlayer data={movie_detail} sourceUrl={movie_detail.video_url} poster={movie_detail.movie_image} /> */}
                                            <FlutterPlayer data={watchepisode} sourceUrl={watchepisode.video_url} poster={watchepisode.watchepisode} episodesSeasons={EpisodeBySeasons} />
                                            
                                        </div>
                                    ) : (
                                        <>
                                            {checkplansuscription === 'Renew subscription plan' && watchepisode.video_access === 'Paid' && (
                                                ()=>navigate('/flutter-subscription')
                                               // <div className="lock_premium">
                                                //     <div className="locker_img">
                                                //         <img src={lock} alt="Locked Content" loading="lazy" />
                                                //     </div>

                                                //     <div className="lock__title">
                                                //         <Link to="/flutter-subscription">Renew your Subscription</Link>
                                                //     </div>
                                                // </div>
                                            )}

                                            {checkplansuscription === 'Please select subscription plan' && watchepisode.video_access === 'Paid' && (
                                                ()=>navigate('/flutter-subscription')
                                               // <div className="lock_premium">
                                                //     <div className="locker_img">
                                                //         <img src={lock} alt="Locked Content" loading="lazy" />
                                                //     </div>
                                                //     <div className="lock__title">
                                                //         <Link to="/flutter-subscription">Please buy a subscription</Link>
                                                //     </div>
                                                // </div>
                                            )}
                                        </>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                loading &&
                <div className="full-screen_loader">
                    <div className="spinner-border spinner_color mt-2 text-center" role="status"></div>
                </div>
            }
        </>
    )
}
export default FlutterWatchEpisode;