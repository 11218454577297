import NewFlixLogo from '../Images/newflix-logo.png';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import 'swiper/css';
import '../Components/css/style.css';
import { API_BASE_URL } from '../config';
import axios from 'axios';

const MultiProfiles = () => {
    const [subAccountList, setAccountList] = useState([]);
    const userId = localStorage.getItem("user_id");
    const [loading, setLoading] = useState(false);
    const [subAccountId, setSubAccountId] = useState(localStorage.getItem("sub_account_id") || '');
    const [subImage, setSubImage] = useState('');

    useEffect(() => {
        document.body.style.background = 'radial-gradient(93.39% 118.9% at 50% 118.9%, rgb(47, 31, 8) 0%, rgb(0, 0, 0) 100%)';

        return () => {
            document.body.style.boxShadow = '';
            document.body.style.margin = '';
            document.body.style.maxWidth = '';
            document.body.style.minHeight = '';
            document.body.style.background = '';
        };
    }, []);


    function reloadMultiProfile() {
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }

    useEffect(() => {
        let subAccountImage = localStorage.getItem('sub_account_img') || '';
        setSubImage(subAccountImage);
    }, []);

    useEffect(() => {
        setLoading(true);
        axios.post(`${API_BASE_URL}api/v1/sub_account_list`, {
            user_id: userId,
        }).then(response => {
            setLoading(false);
            setAccountList(response.data.VIDEO_STREAMING_APP);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }, [userId]);

    const profileData = (subAccountData) => {
        setSubAccountId(subAccountData.id);
        localStorage.setItem("sub_account_id", subAccountData.id);
        localStorage.setItem("sub_account_name", subAccountData.name);
        localStorage.setItem("sub_account_img", subAccountData.user_image);
        localStorage.setItem("sub_account_age", subAccountData.age);
        localStorage.setItem("child_mode", 1);
        window.location.href = '/kids';
        // reloadMultiProfile();
    };

    const deleteSubAccount = (subaccount_id) => {
        axios.post(`${API_BASE_URL}api/v1/sub_account_delete`, {
            user_id: userId,
            sub_account_id: subaccount_id
        }).then(response => {
            console.log(response);
            localStorage.removeItem("sub_account_name");
            localStorage.removeItem("sub_account_id");
            localStorage.removeItem("sub_account_img");
            localStorage.removeItem("sub_account_age");
            reloadMultiProfile();
        }).catch(err => {
            console.log(err);
        });
    };

    return (
        <section className="multi__profiles-section">
            <div className="container-fluid">
                <div className="custom-container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 text-center d-flex justify-content-center">
                            <div className="multi__profile-img">
                                <img src={NewFlixLogo} alt="NewFlix Logo" />
                            </div>
                        </div>
                    </div>
                    <div className="multi__profile__heading-main">
                        <div className="row justify-content-center">
                            <div className="col-md-6 text-center">
                                <div className="multi__profiles_head">
                                    <h2>Who is watching</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="multiple_profiles__cards">
                        <div className="row justify-content-center align-items-start">
                            <div className="col-md-2">
                                <Link to="/multiple-profile/add-new" style={{ textDecoration: "none" }}>
                                    <div className="multiple__profile-card">
                                        <div className="multi_profile__avatar">
                                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M34.5 16.5h-15v-15A1.5 1.5 0 0 0 18 0a1.5 1.5 0 0 0-1.5 1.5v15h-15A1.5 1.5 0 0 0 0 18a1.5 1.5 0 0 0 1.5 1.5h15v15a1.5 1.5 0 1 0 3 0v-15h15a1.5 1.5 0 1 0 0-3z" fill="#fff"></path>
                                            </svg>
                                        </div>
                                        <div className="multi__profile__detail">
                                            <h2>Create New Member</h2>
                                            <p>Apart from yourself, you can add 2 other members to watch the movie.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-2">
                                <Link to="#" style={{ textDecoration: "none" }}>
                                    <div
                                        className={`multiple__profile-card ${!subAccountId ? 'active_profile' : ''}`}
                                        onClick={() => {
                                            if (localStorage.getItem('child_mode') == 1) {
                                                window.location.href = '/exit-child-mode';
                                            }
                                        }}>
                                        <div className="multi_profile__avatar">
                                            <img src={localStorage.getItem('user_image')} className='admin__image' alt="" />
                                        </div>
                                        <div className="multi__profile__detail">
                                            <h2>{localStorage.getItem('user_name')}</h2>
                                            <p>Admin</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            {loading && (
                                <div className="loader_section" style={{ display: "flex", justifyContent: "center" }}>
                                    <div className="spinner-border spinner_color mt-2 text-center" role="status"></div>
                                </div>
                            )}

                            {!loading && subAccountList.map((account, index) => (
                                <div className="col-6 col-sm-4 col-md-2" key={index}>
                                    <div
                                        className={`multiple__profile-card ${subAccountId == account.id ? 'active_profile' : ''}`}
                                        onClick={() => profileData(account)}
                                    >
                                        <div className="multi_profile__avatar">
                                            {account.user_image !== '' ? (
                                                <img src={account.user_image} alt={account.name} sizes="" srcSet="" />
                                            ) : (
                                                <svg fill="white" width="24" height="24" viewBox="0 0 24 24">
                                                    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
                                                </svg>
                                            )}
                                        </div>
                                        <div className="multi__profile__detail">
                                            <h2>{account.name}</h2>
                                            <p>Age-{account.age}</p>
                                            {subAccountId == account.id && <p>Active</p>}
                                            <button className='delete_sub_account' onClick={() => deleteSubAccount(account.id)}>
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MultiProfiles;
