import '../Components/css/style.css';
import { React, useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { API_BASE_URL } from '../config';
import AllSerialsComponent from '../Components/AllSerialsComponent';

const AllSerialsPage = () => {
    const { prams_id } = useParams();
    let childMode = parseInt(localStorage.getItem('child_mode'));
    const [serialsBygenre, setserialsBygenre] = useState([]);
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();
    const location = useLocation();
    const subUserSelectedAge = parseInt(localStorage.getItem('sub_account_age'));
    const [navGenres, setNavGenres] = useState([]);

    useEffect(() => {
        setLoading(true);
        axios.post(`${API_BASE_URL}api/v1/genres`, {})
            .then(response => {
                setNavGenres(response.data.VIDEO_STREAMING_APP);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was an error making the request:', error);
                setLoading(false);
            });
    }, []);
    const filterGenres = (type) => {
        return navGenres.filter(genre => genre.genre_type && genre.genre_type.includes(type));
    };

    // useEffect(() => {
    //     setLoading(true);
    //     // Movies By Genre 
    //     axios.post(`${API_BASE_URL}api/v1/shows_by_genre`, {
    //         filter: "new",
    //         genre_id: prams_id.id
    //     }).then((response) => {
    //         setLoading(false);

    //         if(subUserSelectedAge){
    //             let filteredSeasons = response.data.VIDEO_STREAMING_APP.filter(shows => {
    //                 if (shows.content_rating === '') {
    //                     return false;
    //                 }
    //                 return parseInt(shows.content_rating) <= subUserSelectedAge;
    //             });
    //             let filteredShow = filteredSeasons.slice(0, 8);
    //             // console.log(filteredShow)
    //             setserialsBygenre(filteredShow);
    //         }else if(childMode === 1){
    //             let filteredShow = response.data.VIDEO_STREAMING_APP.filter(shows => {
    //                 if (shows.child_mode === '') {
    //                     return false;
    //                 }
    //                 return parseInt(shows.child_mode) == childMode;
    //             });
    //             setserialsBygenre(filteredShow);
    //         }
    //         else{
    //              let random_movies = response.data.VIDEO_STREAMING_APP.slice(0, 8);
    //              setserialsBygenre(random_movies);
    //         }   
    //         // const latestSixMovies = response.data.VIDEO_STREAMING_APP.slice(0, 6);
    //         // setserialsBygenre(latestSixMovies);
    //     }).catch((error) => {
    //         console.error('Error fetching movies:', error);
    //     });

    // }, [prams_id.id]);

    const [isActive, setIsActive] = useState(false);
    const dropdownRef = useRef(null);

    const handleFilterClick = () => {
        setIsActive(!isActive); // Toggle the active state
    };

    // // Modify handleClickOutside to add a delay and prevent immediate closure
    // const handleClickOutside = (event) => {
    //     event.preventDefault();
    //     // Check if the dropdownRef or the toggle button was clicked
    //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //         setTimeout(() => {
    //             setIsActive(false); // Close the dropdown if click is outside
    //         }, 10); // Allow slight delay
    //     }
    // };

    // Add the event listener for click outside
    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);

    // Open Advance Filter
    const [isActiveAdvanceFilter, setIsActiveAdvanceFilter] = useState(false);
    const handleAdvanceFilter = () => {
        setIsActiveAdvanceFilter(!isActiveAdvanceFilter); // Toggle the active state
    }

        // All genres
        const [openDropdown, setOpenDropdown] = useState(null);
        const [allGenre, setAllGenre] = useState([]);
        // Toggle dropdown visibility
        const toggleDropdown = (dropdown) => {
            setOpenDropdown(openDropdown === dropdown ? null : dropdown);
        };
    
        useEffect(() => {
            axios.post(`${API_BASE_URL}api/v1/genres`).then(response => {
                setAllGenre(response.data.VIDEO_STREAMING_APP);
            }).catch(err => {
                console.log("Genre fetching Error when fetching Filters " + err);
            })
        }, []);
        // All Languages 
        const [languages, allanguages] = useState([]);
        const [countries, setCountries] = useState([]);
        useEffect(() => {
            axios.post(`${API_BASE_URL}api/v1/languages`).then(response => {
                allanguages(response.data.VIDEO_STREAMING_APP);
            }).catch(err => {
                console.log("Genre fetching Error when fetching Filters " + err);
            })
            axios.post(`${API_BASE_URL}api/v1/countries`).then(response => {
                setCountries(response.data.VIDEO_STREAMING_APP);
            }).catch(err => {
                console.log("Genre fetching Error when fetching Filters " + err);
            })
        }, []);
    
        // Handle applying the filter
        const [selectedGenre, setSelectedGenre] = useState({ id: '', name: 'Select genre' });
        const [selectedcountry, setSelectedcountry] = useState({ id: '', country_name: 'Select country' });
        const [selectedLanguage, setSelectedLanguage] = useState({ id: '', language_name: 'Select language' });
        const [selectedAge, setSelectedAge] = useState('Select age');
    
        useEffect(() => {
            const params = new URLSearchParams(location.search);
        
            const genre_id = params.get('genre_id');
            const genre_name = params.get('genre_name');
            const language_id = params.get('language_id');
            const language_name = params.get('language_name');
            const age = params.get('age');
            const country_id = params.get('country_id');
            const country_name = params.get('country_name');
        
            if (genre_id && genre_name) {
              setSelectedGenre({ id: genre_id, name: genre_name });
            }
        
            if (language_id && language_name) {
              setSelectedLanguage({ id: language_id, language_name });
            }
        
            if (age) {
              setSelectedAge(age);
            }
        
            if (country_id && country_name) {
              setSelectedcountry({ id: country_id, country_name });
            }
          }, [location.search]); // Trigger on URL change
    
          const clearFilter = (filterType) => {
            const params = new URLSearchParams(location.search);
        
            switch (filterType) {
                case 'genre':
                    setSelectedGenre({ id: '', name: 'Select genre' });
                    params.delete('genre_id');
                    params.delete('genre_name');
                    break;
                case 'language':
                    setSelectedLanguage({ id: '', language_name: 'Select language' });
                    params.delete('language_id');
                    params.delete('language_name');
                    break;
                case 'age':
                    setSelectedAge('Select age');
                    params.delete('age');
                    break;
                case 'country':
                    setSelectedcountry({ id: '', country_name: 'Select country' });
                    params.delete('country_id');
                    params.delete('country_name');
                    break;
                default:
                    break;
            }  
            // applyFilter();
     
        
            // Navigate to the new URL without the cleared filter
            navigate(`/shows-by-filter?${params.toString()}`);
            // navigate(`/movies?${params.toString()}`);
        };
        
    
        const applyFilter = () => {
            const params = new URLSearchParams();
            if (selectedGenre.id && selectedGenre.id !== 'Select genre') {
                params.append('genre_id', selectedGenre.id);
                params.append('genre_name', selectedGenre.name);
            }
            if (selectedLanguage.id && selectedLanguage.language_name !== 'Select language') {
                params.append('language_id', selectedLanguage.id);
                params.append('language_name', selectedLanguage.language_name);
            }
            if (selectedAge && selectedAge !== 'Select age') {
                params.append('age', selectedAge);
            }
            if (selectedcountry.id && selectedcountry.country_name !== 'Select country') {
                params.append('country_id', selectedcountry.id);
                params.append('country_name', selectedcountry.country_name);
            }
            navigate(`/shows-by-filter?${params.toString()}`);
            // navigate(`/movies?${params.toString()}`);
        };
    
    return (
        <section className="movies__main-section videos-inner-wrapper">
            <div className="container-fluid">
                <div className="inner_video_wrapper">
                    <div className="row">
                        <div className="col-6 col-md-6">
                            <div className="filter_videos_main">
                                <ul className='filter_main_box'>
                                    <li className={`filter_li ${isActiveAdvanceFilter ? 'active_li_color' : ''}`} onClick={handleAdvanceFilter}>Filter <i class="fa-solid fa-filter"></i></li>

                                    {/* Display selected Genre filter if applied */}
                                    {selectedGenre.name !== 'Select genre' && (
                                        <li className='filter_li active_li_color'>
                                            {selectedGenre.name}
                                            <button className="clear_filter_btn" onClick={() => clearFilter('genre')}>
                                                &times;
                                            </button>
                                        </li>
                                    )}

                                    {/* Display selected Language filter if applied */}
                                    {selectedLanguage.language_name !== 'Select language' && (
                                        <li className='filter_li active_li_color'>
                                            {selectedLanguage.language_name}
                                            <button className="clear_filter_btn" onClick={() => clearFilter('language')}>
                                                &times;
                                            </button>
                                        </li>
                                    )}

                                    {/* Display selected Age filter if applied */}
                                    {selectedAge !== 'Select age' && (
                                        <li className='filter_li active_li_color'>
                                            {selectedAge}
                                            <button className="clear_filter_btn" onClick={() => clearFilter('age')}>
                                                &times;
                                            </button>
                                        </li>
                                    )}

                                    {/* Display selected Country filter if applied */}
                                    {selectedcountry.country_name !== 'Select country' && (
                                        <li className='filter_li active_li_color'>
                                            {selectedcountry.country_name}
                                            <button className="clear_filter_btn" onClick={() => clearFilter('country')}>
                                                &times;
                                            </button>
                                        </li>
                                    )}


                                    {/* <li className={`filter_li ${isActive ? 'active_li_color' : ''}`} onClick={handleFilterClick}>
                                        <i className="fa-solid fa-angle-down"></i> sorting
                                        <div className={`filter_dropdown ${isActive ? 'active_filter' : ''}`}>
                                            <ul className='m-0 p-0'>
                                                <li><Link href="#">default</Link></li>
                                                <li><Link href="#">NewFlix news</Link></li>
                                                <li><Link href="#">new (year of manufacture)</Link></li>
                                                <li><Link href="#">old (year of manufacture)</Link></li>
                                                <li><Link href="#">Highest</Link></li>
                                                <li><Link href="#">IMDB rating</Link></li>
                                            </ul>
                                        </div>
                                    </li> */}

                                </ul>
                            </div>
                        </div>
                        <div className="col-6 col-md-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'end' }}>
                            <div className="Movies__main-title text-right" data-aos="fade-up" data-aos-duration="2000">
                                <h2 className='allmovies_title'>Watch movies and series </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className={`advance__filter ${isActiveAdvanceFilter ? 'filter-visible' : 'filter-hidden'}`}>
                <div className="inner_video_wrapper">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-md-12 column_filter" style={{ display: 'flex', direction: 'rtl' }}>
                                <ul className='advance__filter_ul'>
                                    {/* Genre Dropdown */}
                                    <li className={`advance__filter_li ${openDropdown === 'genre' ? 'active_advance_filter' : ''}`} onClick={() => toggleDropdown('genre')}>
                                        {selectedGenre.name}
                                        <i className="fa-solid fa-angle-down"></i>
                                        <ul className={`filter_dropdown ${openDropdown === 'genre' ? 'show' : ''}`}>
                                            {allGenre && allGenre.length > 0 ? (
                                                allGenre.map((genre) => (
                                                    <li key={genre.id || genre.genre_id} onClick={() => setSelectedGenre({ id: genre.genre_id, name: genre.genre_name })}>
                                                        <Link to='#'>{genre.genre_name}</Link>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>No genres available</li>
                                            )}
                                        </ul>
                                    </li>


                                    {/* Language Dropdown */}
                                    <li className={`advance__filter_li ${openDropdown === 'language' ? 'active_advance_filter' : ''}`} onClick={() => toggleDropdown('language')}>
                                        {selectedLanguage.language_name}
                                        <i className="fa-solid fa-angle-down"></i>
                                        <ul className={`filter_dropdown ${openDropdown === 'language' ? 'show' : ''}`}>
                                            {languages && languages.length > 0 ? (
                                                languages.map((language) => (
                                                    <li key={language.language_id || language.language_id} onClick={() => setSelectedLanguage({ id: language.language_id, language_name: language.language_name })}>
                                                        <Link to='#'>{language.language_name}</Link>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>No languages available</li>
                                            )}
                                        </ul>
                                    </li>

                                    {/* Country Dropdown */}
                                    <li className={`advance__filter_li ${openDropdown === 'country' ? 'active_advance_filter' : ''}`} onClick={() => toggleDropdown('country')}>
                                        {selectedcountry.country_name}
                                        <i className="fa-solid fa-angle-down"></i>
                                        <ul className={`filter_dropdown ${openDropdown === 'country' ? 'show' : ''}`}>
                                            {countries && countries.length > 0 ? (
                                                countries.map((country) => (
                                                    <li key={country.country_id || country.country_id} onClick={() => setSelectedcountry({ id: country.country_id, country_name: country.country_name })}>
                                                        <Link to='#'>{country.country_name}</Link>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>No genres available</li>
                                            )}
                                        </ul>
                                    </li>

                                    {/* Age Dropdown */}
                                    <li className={`advance__filter_li ${openDropdown === 'age' ? 'active_advance_filter' : ''}`} onClick={() => toggleDropdown('age')}>
                                        {selectedAge}
                                        <i className="fa-solid fa-angle-down"></i>
                                        <ul className={`filter_dropdown ${openDropdown === 'age' ? 'show' : ''}`}>
                                            <li onClick={() => setSelectedAge('3')}>
                                                <Link to='#'>up to 3</Link>
                                            </li>
                                            <li onClick={() => setSelectedAge('5')}>
                                                <Link to='#'>up to 5</Link>
                                            </li>
                                            <li onClick={() => setSelectedAge('12')}>
                                                <Link to='#'>up to 12</Link>
                                            </li>
                                            <li onClick={() => setSelectedAge('15')}>
                                                <Link to='#'>up to 15</Link>
                                            </li>
                                            <li onClick={() => setSelectedAge('18')}>
                                                <Link to='#'>up to 18</Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                                <div className="filter__row_btn">
                                    <button className="js__submit-filterv3" aria-label="Apply filter button" onClick={applyFilter}>
                                        Apply filter
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container-fluid">
                <div className="inner_video_wrapper">
                    <div className="movies__main">
                        {filterGenres('serials').length > 0 ? (
                            filterGenres('serials').map((navLink, index) => (
                                <>
                                    <div className="top_movie_change" key={index}>
                                        <div className="row justify-content-between">
                                            <div className="col-6 col-sm-6 col-md-6">
                                                <div className="movies__view_all mb-2 text-left" data-aos="fade-up" data-aos-duration="2000">
                                                    <Link to={`/view-all-shows/${navLink.genre_id}`}>view all <i class="fa-solid fa-angle-left"></i></Link>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-6">
                                                <div className="movies__subtitle text-right" data-aos="fade-up" data-aos-duration="2000">
                                                    <h2>{navLink.genre_name}</h2>
                                                </div>
                                            </div>
                                        </div>
                                        {/*  */}
                                        <div className="row">
                                            <div className="col-md-12">
                                                <AllSerialsComponent key={navLink.genre_id} genreId={navLink.genre_id} genreName={navLink.genre_name} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))
                        ) : 'No Movies Found'}
                        <div className="row">
                            <div className="col-md-12 text-center">
                                {loading &&
                                    <div class="spinner-border spinner_color mt-2 text-center" role="status"></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default AllSerialsPage;
