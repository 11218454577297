import { useState, useEffect } from "react";
import { API_BASE_URL, Theme_Variable } from '../../config';
import movie_1 from '../../Images/movie_1.webp';
// import '../../Components/css/style.css';

// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import axios from "axios";
import hd from '../../Images/flutter/hd.png';
import { Link, useLocation } from "react-router-dom";
import download_dark from '../../Images/flutter/download_dark.png';
import download_light from '../../Images/flutter/download_light.png';
import FlutterNavTabs from "./flutter_component/FlutterNavTabs";
import FlutterMainNavTwo from "./flutter_component/FlutterMainNavTwo";
import FlutterBottomBar from "./flutter_component/FlutterBottomBar";
import { useTranslation } from "react-i18next";

const FlutterGalleryOffline = () => {

    const UserId = localStorage.getItem('user_id') || ''; // Check if user is logged in
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);
    const [watchlist, setWatchlist] = useState([]);
    const {t} = useTranslation();

    // Function to toggle the overlay
    const toggleActiveClass = () => {
        setIsActive(!isActive);
    };

    useEffect(() => {
        // Check the current route and apply/remove the class accordingly
        if (location.pathname == '/flutter-offline-movies') {
            document.body.classList.toggle('no-black-background');
            document.body.classList.toggle('black-background', Theme_Variable == 'Dark');
        } else {
            document.body.classList.remove('no-black-background', 'black-background');
        }
    }, [location.pathname]);

    useEffect(() => {
        // setpreLoading(true)
        axios.post(`${API_BASE_URL}api/v1/my_watchlist`, {
            user_id: UserId
        })
            .then(response => {
                // setpreLoading(false);
                setWatchlist(response.data.VIDEO_STREAMING_APP);
            }).catch(err => {
                console.log("Watchlist fetching error:" + err);
            });
    }, []);

    return (
        <>
            <FlutterMainNavTwo />

            <FlutterNavTabs />
            {/* Show the first section only if user is not logged in */}
            {!UserId ? (
                <section className={`fluttered_user_detailed_section ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="detailed_user_main">
                                    <div className="detailed_image_user">
                                        <img src={Theme_Variable == 'Dark' ? download_dark  : download_light} alt="Not Found" />
                                    </div>
                                    <div className="detailed_title">
                                        <h2 className={Theme_Variable == 'Dark' ? 'white_text_mode' : ''}>{t("You haven't watched any movies yet!")}</h2>
                                        <p className={Theme_Variable == 'Dark' ? 'white_text_mode' : ''}>{t("To access the features and movies of NewFlix, you must first log in.")}</p>
                                        <Link to="/flutter-login">{t("Login Or SignUp")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <section className={`fluttered_user_detailed_section ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="detailed_user_main">
                                    <div className="detailed_image_user">
                                        <img src={Theme_Variable == 'Dark' ? download_dark : download_light } alt="Not Found" />
                                    </div>
                                    <div className="detailed_title">
                                        <p className={Theme_Variable == 'Dark' ? 'white_text_mode' : ''}>{t("You can download movies for offline viewing so you can watch them later when you don't have internet.")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            {/* <FlutterBottomBar /> */}
        </>
    );
};

export default FlutterGalleryOffline;
