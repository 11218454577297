import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
import '../../../Components/css/style.css';
import { API_BASE_URL } from '../../../config';
import lock from '../../../Images/home_images/lock.webp';
import LiveTvOther from '../../LiveTvother';

const LiveTvPlayerFlutter = () => {

    const { id } = useParams();
    const UserId = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
    const [livetvdetail, setlivetvdetail] = useState({});
    const [livetvsuggestion, setlivetvsuggestion] = useState([]);
    const [url, seturl] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checkplansuscription, setcheckplansuscription] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        setLoading(true);
        axios.post(`${API_BASE_URL}api/v1/livetv_details`, {
            user_id: UserId,
            tv_id: id,
        })
            .then(response => {
                setLoading(false);
                const data = response.data.VIDEO_STREAMING_APP;
                setlivetvdetail(data);
                seturl(data.tv_url);
                setData(data)
            }).catch(err => {
                console.log("Errors while redirecting to detail TV page: " + err);
            });
        axios.post(`${API_BASE_URL}api/v1/livetv`, {
            filter: 'rand'
        })
            .then(response => {
                let random_suggesion = response.data.VIDEO_STREAMING_APP.livetv.slice(0, 8)
                setlivetvsuggestion(random_suggesion)
            }
            ).catch(err => {
                console.log("error fetching all live tv suggestions" + err)
            });
        // Check User plan
        if (UserId) {
            axios.post(`${API_BASE_URL}api/v1/check_user_plan`, {
                user_id: UserId
            })
                .then(response => {
                    let checkPlan = response.data.VIDEO_STREAMING_APP;
                    setcheckplansuscription(checkPlan.msg);
                }).catch(err => {
                    console.log(err);
                })
        }

    }, [id, UserId]);

    const handleserver = (url) => {
        seturl(url);
    }
    const livetvDetail = (live_channel_id) => {
        navigate(`/live-tv-detail/${live_channel_id}`)

    }

    useEffect(() => {
        // JavaScript to add Font Awesome icons
        const prevButtons = document.querySelectorAll('.swiper-button-prev');
        const nextButtons = document.querySelectorAll('.swiper-button-next');
        prevButtons.forEach(button => {
            button.innerHTML = '<i class="fas fa-chevron-left"></i>';
        });
        nextButtons.forEach(button => {
            button.innerHTML = '<i class="fas fa-chevron-right"></i>';
        });
    }, []);
    return (

        <>
            <section className="live__tv-detail-section videos-inner-wrapper">
                <div className="container-fluid">
                    <div className="inner_video_wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="live__tv-screen on_mobile_live_tv">

                                    {(checkplansuscription == 'My Subscription' || livetvdetail.tv_access == 'Free') && (
                                        <LiveTvOther data={data} poster={livetvdetail.tv_logo} sourceUrl={url} />
                                        // <div className="video__screen">
                                        //     <video controls src={url} poster={livetvdetail.tv_logo} className="live_tv__video"></video>
                                        // </div>
                                    )}

                                    {checkplansuscription == 'Renew subscription plan' || livetvdetail.tv_access == 'Paid' && (
                                       ()=> navigate('/flutter-subscription')
                                       // <div className="lock_premium_flutter">
                                        //     <div className="lock__title">
                                        //     <Link to="/flutter-subscription">Renew your Subscription</Link>
                                        //     </div>
                                        // </div>
                                    )}

                                     {checkplansuscription == 'Please select subscription plan' || livetvdetail.tv_access == 'Paid' && (
                                       ()=> navigate('/flutter-subscription')
                                       // <div className="lock_premium_flutter">
                                        //     <div className="lock__title">
                                        //         <Link to="/flutter-subscription">Please buy a subscription</Link>
                                        //     </div>
                                        // </div>
                                    )} 

                                    {
                                        !UserId && (
                                            ()=> navigate('/flutter-login')
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        {/* <div className="live__tv-details__info">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="live__tv-title">
                                        <p>{livetvdetail.tv_title}</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="live__tv-more-info">
                            <ul className='list-unstyled m-0 d-flex'>
                                <li>
                                    <i className="fa-solid fa-layer-group"></i>
                                    Name: {livetvdetail.category_name}
                                </li>
                                <li>
                                    <i className="fa-regular fa-eye"></i>
                                    Views: {livetvdetail.views}
                                </li>
                                <li>
                                    <i className="fa-solid fa-money-check"></i>
                                    Access: {livetvdetail.tv_access}
                                </li>
                            </ul>
                            <div className="server__link">
                                <ul className='d-flex m-0 p-0'>
                                    <li>
                                        <i className="fa-solid fa-display"></i>
                                        <button className='server_btn' onClick={() => handleserver(livetvdetail.tv_url)}>
                                            Server 1
                                        </button>
                                    </li>
                                    {livetvdetail.tv_url2 && (
                                        <li>
                                            <i className="fa-solid fa-display"></i>
                                            <button className='server_btn' onClick={() => handleserver(livetvdetail.tv_url2)}>
                                                Server 2
                                            </button>
                                        </li>
                                    )}
                                    {livetvdetail.tv_url3 && (
                                        <li>
                                            <i className="fa-solid fa-display"></i>
                                            <button className='server_btn' onClick={() => handleserver(livetvdetail.tv_url3)}>
                                                Server 3
                                            </button>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>


            {
                loading &&
                <div className="full-screen_loader">
                    <div class="spinner-border spinner_color mt-2 text-center" role="status"></div>
                </div>
            }
        </>
    );

}

export default LiveTvPlayerFlutter;
