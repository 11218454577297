import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Theme_Variable } from '../../../config';
import { API_BASE_URL } from '../../../config';
import axios from "axios";
// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
// Import icons
import newflix_logo from '../../../Images/newflix-logo.png';
import nav1 from '../../../Images/flutter/nav1.png';
import search_white from '../../../Images/flutter/NewFlix Images/Icons/search_white.png';
import nav2 from '../../../Images/flutter/nav2.png';
import flame from '../../../Images/flutter/NewFlix Images/Icons/fire-icn 1.png';
import flame_black from '../../../Images/flutter/NewFlix Images/Icons/fire-icon-black.png';
import { useTranslation } from "react-i18next";

const FlutterHomeScreenNav = ({ user_id, detail_id, type }) => {

    const [isScrolled, setIsScrolled] = useState(false);
    let UserId = localStorage.getItem('user_id') || 0;
    let [userplan, setUserplan] = useState('');
    const [checkplansuscription, setcheckplansuscription] = useState('');
    const [showNotification, setShowNotification] = useState(true);
    const {t} = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 350); // Adjust the scroll threshold as needed
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const profileBtnFlutter = () => {
        if (UserId) {
            // /flutter-profile-page
            // window.location.href = `/flutter-dashboard/?user_id=${UserId}`;
            window.location.href = `flutter-profile-page`;
        } else {
            window.location.href = `/flutter-before-login-screen`;
        }
    }

    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/check_user_plan`, {
            user_id: UserId
        })
            .then(response => {
                let checkPlan = response.data.VIDEO_STREAMING_APP;
                setcheckplansuscription(checkPlan.msg);
                if (sessionStorage.getItem('notificationShown')) {
                    setShowNotification(true);
                    setTimeout(() => {
                        sessionStorage.setItem('notificationShown', 'false');
                        setShowNotification(false);
                    }, 10000);
                }
                sessionStorage.setItem('notificationShown', 'true');
                setUserplan(response.data.VIDEO_STREAMING_APP);
                // console.log(response)
            }).catch(err => {
                console.log(err);
            });
    }, []);
    return (
        <>
            {/* Nav */}
            {/* <section className={`Flutter_nav  */}
            {/* // ${Theme_Variable == 'Dark' ? (isScrolled ? 'scrolled_dark_nav' : 'scrolled') : ''}`}> */}
            <section className={`Flutter_nav  ${Theme_Variable == 'Dark' ? isScrolled ? 'scrolled_dark' : '' : isScrolled ? 'scrolled' : ''}`}>
                <div className="flutter__logo">
                    <div className="flutter__logo_main">
                        <img src={newflix_logo} alt="" />
                    </div>
                </div>
                <div className="newFlix_links_nav_flutter">
                    <ul className="list-unstyled d-flex">
                        <li>
                            <Link to="#" onClick={profileBtnFlutter}>
                                <div className="icons__nav_main profile_nav_flutter">
                                    <img src={nav1} alt="" />
                                    {
                                        UserId !=0 && (
                                            <>
                                                <div className="notification__dot"></div>
                                                {checkplansuscription && showNotification && (
                                                    <div className="notification__flutter_spot_main">
                                                        <div className="notification__flutter_spot">
                                                            <span className={`plan_msg ${checkplansuscription === 'My Subscription' ? 'plan_subcribed_color' : ''}`}>
                                                                {checkplansuscription === 'Renew subscription plan' && `${t("Renew subscription plan")}`}
                                                                {checkplansuscription === 'Please select subscription plan' && `${t("You do not have a subscription")}`}
                                                                {checkplansuscription === 'My Subscription' && `${userplan.current_plan} ${t("subscribed")}`}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )
                                    }

                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/flutter-newest-shows-player">
                                <div className="icons__nav_main flame_nav_flutter">
                                    {/* <img src={flame} alt="" /> */}
                                    <img src={`${Theme_Variable == 'Dark' ? isScrolled ? flame : flame : isScrolled ? flame_black : flame}`} alt="" />
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/flutter-search-page">
                                <div className="icons__nav_main search_nav_flutter">
                                    <img src={`${Theme_Variable == 'Dark' ? isScrolled ? search_white : search_white : isScrolled ? nav2 : search_white}`} alt="" />
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </section>
            {/* Nav */}
        </>
    );
};

export default FlutterHomeScreenNav;
