import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import myVideo from '../asstes/TheSociety.mp4';
// import audio from '../asstes/audio.mp3';
import subtitle from '../asstes/sample.vtt';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import MovieIcon from '@mui/icons-material/Movie'; // Icon for the button
// import subtitle from '../asstes/subtitle.srt';
import { IconButton, Slider, Menu, MenuItem, Button } from '@mui/material';
import { PlayArrow, Pause, VolumeUp, VolumeOff, Fullscreen, Forward10, Replay10, PictureInPictureAlt, Speed, Subtitles } from '@mui/icons-material';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import '../Components/css/style.css';

const LiveTvother = ({ data, sourceUrl, poster }) => {

    // const [url,setSourceUrl] = useState([]);
    const [url, setSourceUrl] = useState(data.tv_url || ""); // Set Server 1 URL as the default


    const handleLiveServer = (live_url) =>{
        setSourceUrl(live_url);
        console.log(live_url);
    }


    const playerRef = useRef(null);
    const audioRef = useRef(null);  // Create a ref for the audio element

    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0.6);
    const [muted, setMuted] = useState(false);
    const [played, setPlayed] = useState(0);
    const [fullscreen, setFullscreen] = useState(false);
    const [pip, setPip] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [speed, setSpeed] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [setPlayer_loader, setsetPlayer_loader] = useState(true);
    const [buffering, setBuffering] = useState(false); // Track buffering state
    const [hasPlayed, setHasPlayed] = useState(false);

    const togglePlayPause = () => setPlaying(!playing);


    const toggleMute = () => {
        setMuted(!muted);
    };

    const handleVolumeChange = (e, newValue) => {
        const normalizedVolume = newValue / 100; // Convert 0-100 to 0-1
        setVolume(normalizedVolume);
    };

    const handleSeekChange = (e, newValue) => {
        const seekTo = newValue / 100;
        setPlayed(seekTo);
        playerRef.current.seekTo(seekTo);
    };

    const handleFullscreen = () => {
        const playerElement = playerRef.current.wrapper;
        if (fullscreen) {
            document.exitFullscreen();
        } else {
            playerElement.requestFullscreen();
        }
        setFullscreen(!fullscreen);
    };

    const handleForward = () => {
        const currentTime = playerRef.current.getCurrentTime();
    };

    const handleBackward = () => {
        const currentTime = playerRef.current.getCurrentTime();
        playerRef.current.seekTo(currentTime - 10, 'seconds');
    };

    const handlePiP = async () => {
        try {
            if (pip) {
                // Exit Picture-in-Picture
                await document.exitPictureInPicture();
                setPip(false);
            } else {
                // Enter Picture-in-Picture
                const videoElement = playerRef.current.getInternalPlayer();
                if (videoElement) {
                    await videoElement.requestPictureInPicture();
                    setPip(true);
                }
            }
        } catch (error) {
            console.error('Failed to toggle Picture-in-Picture:', error);
        }
    };


    const handleSpeedChange = (newSpeed) => {
        setSpeed(newSpeed);
        playerRef.current.getInternalPlayer().playbackRate = newSpeed;
        setAnchorEl(null);
    };

    const handleClickSpeedMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseSpeedMenu = () => {
        setAnchorEl(null);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    // Mouse Enter Control Showing Work
    const [showControls, setShowControls] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const handleMouseEnter = () => {
        setShowControls(true);
        clearTimeout(timeoutId);
    };
    const handleMouseLeave = () => {
        const id = setTimeout(() => 
            handleCloseEpisode(),
            setShowControls(false), 2000); // Hide after 3 seconds
        setTimeoutId(id);
    };
    const handleTouchStart = () => {
        setShowControls(true);
        clearTimeout(timeoutId);
        const id = setTimeout(() => setShowControls(false), 3000); // Hide after 3 seconds
        setTimeoutId(id);
    };
    // Mouse Enter Control Showing Work
    const [anchorElEpisodes, setAnchorElEpisodes] = useState(null);

    const handleClickEpisode = (event) => {
        setAnchorElEpisodes(event.currentTarget); // Opens the menu
    };

    const handleCloseEpisode = () => {
        setAnchorElEpisodes(null); // Closes the menu
    };

    const handleBuffer = () => {
        setBuffering(true);
        setPlaying(false);
        setHasPlayed(false);
        setsetPlayer_loader(true);

        if (playerRef.current) {
            try {
                // Access the internal player (assuming it has a method to get it)
                const internalPlayer = playerRef.current.getInternalPlayer();
                const promiseVideoBuffer = internalPlayer.pause(); // Change this to internalPlayer
                // const promiseAudioBuffer = audioRef.current ? audioRef.current.pause() : Promise.resolve();
                if (promiseVideoBuffer && typeof promiseVideoBuffer.then == 'function') {
                    promiseVideoBuffer
                        .then(() => {
                            console.log("Video paused successfully during buffering");
                        })
                        .catch((error) => {
                            console.log("Error pausing video:", error);
                        });
                } else {
                    console.log("Video paused successfully during buffering (no promise returned)");
                }

            } catch (error) {
                console.log("Error while trying to pause video or audio:", error);
            }
        } else {
            console.log("No video reference available to pause.");
        }

        // Reset buffering state when done
        setBuffering(false);
    };

    
    const handleBufferEnd = () => {
        setBuffering(false);
        setsetPlayer_loader(false);
        if (playerRef.current && audioRef.current) {
            try {
                const internalPlayer = playerRef.current.getInternalPlayer();
                const promiseVideoPlay = internalPlayer.play();
                // const promiseAudioPlay = audioRef.current.src ? audioRef.current.play() : Promise.resolve(); // Ensure valid audio source
                if (promiseVideoPlay && typeof promiseVideoPlay.then === 'function') {
                    promiseVideoPlay
                        .then(() => {
                            setHasPlayed(true);
                            setPlaying(true);
                            console.log("Video resumed successfully after buffering ended.");
                        })
                        .catch((error) => {
                            console.log("Error resuming video:", error);
                        });
                } else {
                    console.log("Video resumed successfully after buffering ended (no promise returned)");
                }
            } catch (error) {
                console.log("Error while trying to resume video or audio:", error);
            }
        } else {
            if (!playerRef.current) {
                console.log("No video reference available to play.");
            }
            // if (!audioRef.current || !audioRef.current.src) {
            //     console.log("No valid audio reference or source available to play.");
            // }
        }
        setBuffering(false);
    };


    return (
        <div className={`player-wrapper ${showControls ? 'show_controls' : ''}`} style={{ position: 'relative', width: '100%', height: '100%' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleTouchStart}>

            <ReactPlayer
                ref={playerRef}
                url={url}
                playing={playing}
                volume={volume}
                muted={muted}
                onProgress={({ played, playedSeconds }) => {
                    setPlayed(played * 100);
                    setCurrentTime(playedSeconds);
                }}
                onDuration={setDuration}
                controls={false} // Hide default controls to customize
                onPlay={() => audioRef.current && audioRef.current.play()}
                onPause={() => audioRef.current && audioRef.current.pause()}
                onSeek={time => audioRef.current && (audioRef.current.currentTime = time)}
                // onBuffer={handleBuffer}      // Handle video buffering (pause audio)
                // onBufferEnd={handleBufferEnd} // Handle end of buffering (resume audio)
                width="100%"
                height="100%"
                config={{
                    file: {
                        attributes: {
                            controlsList: 'nodownload', // Disable download button
                        },
                    },
                }}
            />

            <div className="controls">
                <IconButton onClick={togglePlayPause} className='play_pause_btn'>
                    {playing ? <Pause /> : <PlayArrow />}
                </IconButton>

                <IconButton onClick={handleBackward} className='back_ward_btn' >
                    <Replay10 />
                </IconButton>

                <IconButton onClick={handleForward} className='for_ward_btn'>
                    <Forward10 />
                </IconButton>

                <Slider
                    value={played}
                    onChange={handleSeekChange}
                    aria-labelledby="continuous-slider"
                    style={{ width: '50%' }}
                />

                <IconButton onClick={toggleMute}>
                    {muted ? <VolumeOff /> : <VolumeUp />}
                </IconButton>

                <Slider
                    value={volume * 100} // Convert 0-1 volume to 0-100 slider value
                    onChange={handleVolumeChange}
                    aria-labelledby="continuous-slider"
                    style={{ width: '100px' }}
                />
                <div className="wrap_up">
                    <div className="time-info">
                        <span>{formatTime(currentTime)}</span> / <span>{formatTime(duration)}</span>
                    </div>
                    <IconButton
                        aria-controls="speed-menu"
                        aria-haspopup="true"
                        onClick={handleClickSpeedMenu}
                    >
                        <Speed />
                    </IconButton>

                    <IconButton
                        aria-controls="episodes-menu"
                        aria-haspopup="true"
                        onClick={handleClickEpisode} // Opens the menu when clicked
                    >
                        <MovieIcon /> {/* Icon for the episodes button */}
                    </IconButton>
                    <div className='All_episodes_in_player'>
                        <Menu
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            id="episodes-menu"
                            anchorEl={anchorElEpisodes}
                            keepMounted
                            open={Boolean(anchorElEpisodes)}
                            onClose={handleCloseEpisode}
                        >
                            {/* Optional disabled item */}
                            {/* <MenuItem disabled style={{ color: 'white' }}>No Episodes Available</MenuItem> */}

                            {/* Static menu items with white text and icons */}
                            <MenuItem onClick={()=>handleLiveServer(data.tv_url)} style={{ color: 'white' }}>
                                <PlayCircleOutlineIcon style={{ marginRight: '10px', color: 'white' }} />
                                Server 1
                            </MenuItem>
                            {
                                data.tv_url2 && (
                                <MenuItem onClick={()=>handleLiveServer(data.tv_url2)} style={{ color: 'white' }}>
                                    <PlayCircleOutlineIcon style={{ marginRight: '10px', color: 'white' }} />
                                    Server 2
                                </MenuItem>
                                )
                            }
                            {
                                data.tv_url2 && (
                                <MenuItem onClick={()=>handleLiveServer(data.tv_url3)} style={{ color: 'white' }}>
                                    <PlayCircleOutlineIcon style={{ marginRight: '10px', color: 'white' }} />
                                    Server 3
                                </MenuItem>
                                )
                            }
                        </Menu>
                    </div>
                    <Menu
                        id="speed-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseSpeedMenu}
                    >
                        {[0.25, 0.5, 1, 1.5, 1.75, 2].map((rate) => (
                            <MenuItem key={rate} onClick={() => handleSpeedChange(rate)}>
                                {rate}x
                            </MenuItem>
                        ))}
                    </Menu>

                    <IconButton onClick={handlePiP}>
                        <PictureInPictureAlt />
                    </IconButton>

                    <IconButton onClick={handleFullscreen}>
                        <Fullscreen />
                    </IconButton>
                </div>
            </div>
            {/* {
                setPlayer_loader &&
                <div className="section_player_loading">
                    <div class="loading"></div>
                </div>
            } */}


            {/* <div className="overlay"> */}
            {/* <img src={poster} alt="Logo" className="logo" style={{ position: 'absolute', top: 10, left: 10 }} /> */}
            {/* <div className="custom-controls" style={{ position: 'absolute', bottom: 10, left: 10 }}>
                    <button onClick={handlePlay}>Play</button>
                    <button onClick={handlePause}>Pause</button>
                </div> */}
            {/* </div> */}
        </div>
    );
};

// Helper function to parse time format into seconds
const parseTime = (time) => {
    const [hours, minutes, seconds] = time.split(':');
    const [sec, ms] = seconds.split('.');
    return parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseFloat(`${sec}.${ms}`);
};

export default LiveTvother;