import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { API_BASE_URL } from '../../config';
import { Theme_Variable } from '../../config';
import axios from "axios";
// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

// Import icons
import download_black from '../../Images/flutter/nav/cloud-icon.png';
import watchlist from '../../Images/flutter/nav/watchlist.png';
import shareIcon from '../../Images/flutter/nav/share-icon.png';

import white1 from '../../Images/flutter/nav/white1.png';
import white2 from '../../Images/flutter/nav/white2.png';
import white3 from '../../Images/flutter/nav/white3.png';

import arrwhite from '../../Images/flutter/nav/arr_white.png';
import arrblack from '../../Images/flutter/nav/arr_black.png';

import subscribe from '../../Images/flutter/subscribe.png';
import dark_about from "../../Images/flutter/about.png";

import dark_contact from "../../Images/flutter/contact.png";
import dark_moon from "../../Images/flutter/moon_dark.png";
import dark__arr from "../../Images/flutter/dark__ar.png";

import delete_device from "../../Images/flutter/delete.png";
import white_device from "../../Images/flutter/white_device.png";
import logout from "../../Images/flutter/logout.png";
import FlutterBackHeader from "./FlutterBackHeader";
import { useTranslation } from "react-i18next";

const FlutterProfileDevice = () => {

    const location = useLocation();
    const [DeviceHistory, setUserHistory] = useState([]);
    const UserId = localStorage.getItem('user_id') || '';
    const [pages_spinner, setpagesSpinnerFlutter] = useState(false);
    const {t} = useTranslation();

    // useEffect(() => {
    //     // Check the current route and apply/remove the class accordingly

    //     if (location.pathname == '/flutter-profile-your-device' && Theme_Variable == 'Light') {
    //         document.body.classList.add('no-black-background');
    //     } else if (location.pathname == '/flutter-profile-your-device' && Theme_Variable == 'Dark') {
    //         document.body.classList.add('black-background');
    //     } else {
    //         document.body.classList.remove('no-black-background');
    //     }
    // }, [location.pathname]);

    useEffect(() => {
        setpagesSpinnerFlutter(true);
        axios.post(`${API_BASE_URL}api/v1/user_active_device_list`, {
            user_id: UserId,
        })
            .then(response => {
                setpagesSpinnerFlutter(false);;
                setUserHistory(response.data.VIDEO_STREAMING_APP);
            })
            .catch(err => {
                console.error("Error fetching device data:", err);
                alert('An error occurred while fetching device data. Please try again later.');
            });
    }, []);

    return (
        <>
            <FlutterBackHeader/>
            <section className="flutter__mobile__device">
                {/* Table */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="table__main__profile__device">
                                <div className="heading__device_profile__main">
                                    <div className="icon__devices__profile">
                                        <img src={white_device} alt="Device Icon" />
                                    </div>
                                    <p>
                                        {t("Currently, your account is active on the following devices.")}
                                    </p>
                                </div>

                                <div className="table__flutter__device table-responsive">
                                    <table className="table table-dark table-bordered">
                                        <thead style={{ background: '#3A3A3A' }}>
                                            <tr>
                                                <th scope="col">{t("Active Date")}</th>
                                                <th scope="col">{t("Device Name")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                DeviceHistory.map((histories, index) => (
                                                    <tr key={index}>
                                                        <td>{histories.active_date}</td>
                                                        <td>{histories.device_name}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {pages_spinner && (
                <div className="flutter_detail_page_spinner">
                    <div className="flutter_section_spinner">
                        <div class="flutter_spinned_sections"></div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FlutterProfileDevice;
