import '../Components/css/style.css';
import { React, useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import Allmovies from '../Components/Allmovies';
import { API_BASE_URL } from '../config';

const MoviesByFilter = () => {

    const prams_id = useParams();
    const [moviesBygenre, setMovieBygenre] = useState([]);
    const [genreList, genreListformovies] = useState([]);
    const [genre_id, genreListId] = useState([]);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const subUserSelectedAge = parseInt(localStorage.getItem('sub_account_age'));

    const { id } = useParams();
    const [moviesByGenre, setMoviesByGenre] = useState([]);
    const [navGenres, setNavGenres] = useState([]);

    const navigate = useNavigate();



    const [isActive, setIsActive] = useState(false);
    const dropdownRef = useRef(null);

    const handleFilterClick = () => {
        setIsActive(!isActive); // Toggle the active state
    };


    // Open Advance Filter
    const [isActiveAdvanceFilter, setIsActiveAdvanceFilter] = useState(false);
    const handleAdvanceFilter = () => {
        setIsActiveAdvanceFilter(!isActiveAdvanceFilter); // Toggle the active state
    }
    // All genres
    const [openDropdown, setOpenDropdown] = useState(null);
    const [allGenre, setAllGenre] = useState([]);
    // Toggle dropdown visibility
    const toggleDropdown = (dropdown) => {
        setOpenDropdown(openDropdown === dropdown ? null : dropdown);
    };

    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/genres`).then(response => {
            setAllGenre(response.data.VIDEO_STREAMING_APP);
        }).catch(err => {
            console.log("Genre fetching Error when fetching Filters " + err);
        })
    }, []);
    // All Languages 
    const [languages, allanguages] = useState([]);
    const [countries, setCountries] = useState([]);
    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/languages`).then(response => {
            allanguages(response.data.VIDEO_STREAMING_APP);
        }).catch(err => {
            console.log("Genre fetching Error when fetching Filters " + err);
        })
        axios.post(`${API_BASE_URL}api/v1/countries`).then(response => {
            setCountries(response.data.VIDEO_STREAMING_APP);
        }).catch(err => {
            console.log("Genre fetching Error when fetching Filters " + err);
        })
    }, []);

    // Handle applying the filter
    const [selectedGenre, setSelectedGenre] = useState({ id: '', name: 'Select genre' });
    const [selectedcountry, setSelectedcountry] = useState({ id: '', country_name: 'Select country' });
    const [selectedLanguage, setSelectedLanguage] = useState({ id: '', language_name: 'Select language' });
    const [selectedAge, setSelectedAge] = useState('Select age');

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const genre_id = params.get('genre_id');
        const genre_name = params.get('genre_name');
        const language_id = params.get('language_id');
        const language_name = params.get('language_name');
        const age = params.get('age');
        const country_id = params.get('country_id');
        const country_name = params.get('country_name');

        if (genre_id && genre_name) {
            setSelectedGenre({ id: genre_id, name: genre_name });
        }

        if (language_id && language_name) {
            setSelectedLanguage({ id: language_id, language_name });
        }

        if (age) {
            setSelectedAge(age);
        }

        if (country_id && country_name) {
            setSelectedcountry({ id: country_id, country_name });
        }
    }, [location.search]); // Trigger on URL change

    const clearFilter = (filterType) => {
        const params = new URLSearchParams(location.search);

        switch (filterType) {
            case 'genre':
                setSelectedGenre({ id: '', name: 'Select genre' });
                params.delete('genre_id');
                params.delete('genre_name');
                break;
            case 'language':
                setSelectedLanguage({ id: '', language_name: 'Select language' });
                params.delete('language_id');
                params.delete('language_name');
                break;
            case 'age':
                setSelectedAge('Select age');
                params.delete('age');
                break;
            case 'country':
                setSelectedcountry({ id: '', country_name: 'Select country' });
                params.delete('country_id');
                params.delete('country_name');
                break;
            default:
                break;
        }

        navigate(`/movies-by-filter?${params.toString()}`);
    };


    const applyFilter = () => {
        const params = new URLSearchParams();
        if (selectedGenre.id && selectedGenre.id !== 'Select genre') {
            params.append('genre_id', selectedGenre.id);
            params.append('genre_name', selectedGenre.name);
        }
        if (selectedLanguage.id && selectedLanguage.language_name !== 'Select language') {
            params.append('language_id', selectedLanguage.id);
            params.append('language_name', selectedLanguage.language_name);
        }
        if (selectedAge && selectedAge !== 'Select age') {
            params.append('age', selectedAge);
        }
        if (selectedcountry.id && selectedcountry.country_name !== 'Select country') {
            params.append('country_id', selectedcountry.id);
            params.append('country_name', selectedcountry.country_name);
        }
        navigate(`/movies-by-filter?${params.toString()}`);

    };


    const getFiltersFromURL = () => {
        const params = new URLSearchParams(location.search);
        const filters = {
            genre: params.get('genre_id'),
            age: params.get('age'),
            language: params.get('language_id'),
            country: params.get('country_id'),
        };
        return filters;
    };
    // Function to check if any filter is applied (age, language, country)
    const isFilterApplied = () => {
        const filters = getFiltersFromURL();
        return filters.age || filters.language || filters.country || filters.genre;
    };

    useEffect(() => {
        const filters = getFiltersFromURL();
        // console.log(filters.country)
        if (isFilterApplied()) {
            const payload = {};
            if (filters.genre) payload.genre = parseInt(filters.genre);
            if (filters.age) payload.age = filters.age;
            if (filters.language) payload.language = filters.language;
            if (filters.country) payload.country = filters.country;

            // Fetch from /movies API when filters are applied
            axios.post(`${API_BASE_URL}api/v1/movies`, payload)
                .then((response) => {
                    setLoading(false);
                    setMovieBygenre(response.data.VIDEO_STREAMING_APP.movies);
                })
                .catch((error) => {
                    setMovieBygenre([]);
                    console.error('Error fetching filtered movies:', error);
                });
        }
    }, [location]);

    return (

        <section className="movies__main-section">
            <div className="container-fluid">
                <div className="inner_video_wrapper">
                    <div className="row">
                        <div className="col-6 col-md-6">
                            <div className="filter_videos_main">
                                <ul className='filter_main_box'>
                                    <li className={`filter_li ${isActiveAdvanceFilter ? 'active_li_color' : ''}`} onClick={handleAdvanceFilter}>Filter <i class="fa-solid fa-filter"></i></li>

                                    {/* Display selected Genre filter if applied */}
                                    {selectedGenre.name !== 'Select genre' && (
                                        <li className='filter_li active_li_color'>
                                            {selectedGenre.name}
                                            <button className="clear_filter_btn" onClick={() => clearFilter('genre')}>
                                                &times;
                                            </button>
                                        </li>
                                    )}

                                    {/* Display selected Language filter if applied */}
                                    {selectedLanguage.language_name !== 'Select language' && (
                                        <li className='filter_li active_li_color'>
                                            {selectedLanguage.language_name}
                                            <button className="clear_filter_btn" onClick={() => clearFilter('language')}>
                                                &times;
                                            </button>
                                        </li>
                                    )}

                                    {/* Display selected Age filter if applied */}
                                    {selectedAge !== 'Select age' && (
                                        <li className='filter_li active_li_color'>
                                            {selectedAge}
                                            <button className="clear_filter_btn" onClick={() => clearFilter('age')}>
                                                &times;
                                            </button>
                                        </li>
                                    )}

                                    {/* Display selected Country filter if applied */}
                                    {selectedcountry.country_name !== 'Select country' && (
                                        <li className='filter_li active_li_color'>
                                            {selectedcountry.country_name}
                                            <button className="clear_filter_btn" onClick={() => clearFilter('country')}>
                                                &times;
                                            </button>
                                        </li>
                                    )}

{/* 
                                    <li className={`filter_li ${isActive ? 'active_li_color' : ''}`} onClick={handleFilterClick}>
                                        <i className="fa-solid fa-angle-down"></i> sorting
                                        <div className={`filter_dropdown ${isActive ? 'active_filter' : ''}`}>
                                            <ul className='m-0 p-0'>
                                                <li><Link href="#">default</Link></li>
                                                <li><Link href="#">NewFlix news</Link></li>
                                                <li><Link href="#">new (year of manufacture)</Link></li>
                                                <li><Link href="#">old (year of manufacture)</Link></li>
                                                <li><Link href="#">Highest</Link></li>
                                                <li><Link href="#">IMDB rating</Link></li>
                                            </ul>
                                        </div>
                                    </li> */}

                                </ul>
                            </div>
                        </div>
                        <div className="col-6 col-md-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'end' }}>
                            <div className="Movies__main-title text-right" data-aos="fade-up" data-aos-duration="2000">
                                <h2 className='allmovies_title'>Watch movies and series </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className={`advance__filter ${isActiveAdvanceFilter ? 'filter-visible' : 'filter-hidden'}`}>
                <div className="inner_video_wrapper">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-md-12 column_filter" style={{ display: 'flex', direction: 'rtl' }}>
                                <ul className='advance__filter_ul'>
                                    {/* Genre Dropdown */}
                                    <li className={`advance__filter_li ${openDropdown === 'genre' ? 'active_advance_filter' : ''}`} onClick={() => toggleDropdown('genre')}>
                                        {selectedGenre.name}
                                        <i className="fa-solid fa-angle-down"></i>
                                        <ul className={`filter_dropdown ${openDropdown === 'genre' ? 'show' : ''}`}>
                                            {allGenre && allGenre.length > 0 ? (
                                                allGenre.map((genre) => (
                                                    <li key={genre.id || genre.genre_id} onClick={() => setSelectedGenre({ id: genre.genre_id, name: genre.genre_name })}>
                                                        <Link to='#'>{genre.genre_name}</Link>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>No genres available</li>
                                            )}
                                        </ul>
                                    </li>


                                    {/* Language Dropdown */}
                                    <li className={`advance__filter_li ${openDropdown === 'language' ? 'active_advance_filter' : ''}`} onClick={() => toggleDropdown('language')}>
                                        {selectedLanguage.language_name}
                                        <i className="fa-solid fa-angle-down"></i>
                                        <ul className={`filter_dropdown ${openDropdown === 'language' ? 'show' : ''}`}>
                                            {languages && languages.length > 0 ? (
                                                languages.map((language) => (
                                                    <li key={language.language_id || language.language_id} onClick={() => setSelectedLanguage({ id: language.language_id, language_name: language.language_name })}>
                                                        <Link to='#'>{language.language_name}</Link>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>No languages available</li>
                                            )}
                                        </ul>
                                    </li>

                                    {/* Country Dropdown */}
                                    <li className={`advance__filter_li ${openDropdown === 'country' ? 'active_advance_filter' : ''}`} onClick={() => toggleDropdown('country')}>
                                        {selectedcountry.country_name}
                                        <i className="fa-solid fa-angle-down"></i>
                                        <ul className={`filter_dropdown ${openDropdown === 'country' ? 'show' : ''}`}>
                                            {countries && countries.length > 0 ? (
                                                countries.map((country) => (
                                                    <li key={country.country_id || country.country_id} onClick={() => setSelectedcountry({ id: country.country_id, country_name: country.country_name })}>
                                                        <Link to='#'>{country.country_name}</Link>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>No genres available</li>
                                            )}
                                        </ul>
                                    </li>

                                    {/* Age Dropdown */}
                                    <li className={`advance__filter_li ${openDropdown === 'age' ? 'active_advance_filter' : ''}`} onClick={() => toggleDropdown('age')}>
                                        {selectedAge}
                                        <i className="fa-solid fa-angle-down"></i>
                                        <ul className={`filter_dropdown ${openDropdown === 'age' ? 'show' : ''}`}>
                                            <li onClick={() => setSelectedAge('3')}>
                                                <Link to='#'>up to 3</Link>
                                            </li>
                                            <li onClick={() => setSelectedAge('5')}>
                                                <Link to='#'>up to 5</Link>
                                            </li>
                                            <li onClick={() => setSelectedAge('12')}>
                                                <Link to='#'>up to 12</Link>
                                            </li>
                                            <li onClick={() => setSelectedAge('15')}>
                                                <Link to='#'>up to 15</Link>
                                            </li>
                                            <li onClick={() => setSelectedAge('18')}>
                                                <Link to='#'>up to 18</Link>
                                            </li>
                                        </ul>
                                    </li>

                                </ul>

                                <div className="filter__row_btn">
                                    <button className="js__submit-filterv3" aria-label="Apply filter button" onClick={applyFilter}>
                                        Apply filter
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container-fluid">
                <div className="inner_video_wrapper">
                    <div className="movies__main">

                        <div className="top_movie_change">
                            <div className="row">
                                <div className="col-md-12">

                                    <div className="all__movies-main">
                                        <div className="videos__container">
                                            {moviesBygenre && moviesBygenre.length > 0 ? (
                                                moviesBygenre.map((movie, index) => (
                                                    <div className="videos_item" key={index}>
                                                        <div className="movie" onClick={() => navigate(`/movie-page/${movie.movie_id}`)} >
                                                            <div className="movie_img">
                                                                <img src={movie.movie_poster} alt={movie.movie_title} loading="lazy" />
                                                                <div className="overlay__movie">
                                                                    <div className="movie__info">
                                                                        {/* <p className='duration'>Duration: {movie.movie_duration}</p>
                                                                                 <p className='rating duration d-block text-center'>{movie.movie_access}</p>
                                                                            <p className='categ_related'>{genreName}</p> */}
                                                                        <p>
                                                                            {
                                                                                movie.genre_list && movie.genre_list.length > 0
                                                                                    ? movie.genre_list.slice(0, 2).map(genres => genres.genre_name).join(' - ')
                                                                                    : 'No genres available'
                                                                            }

                                                                        </p>
                                                                        <p className="movie_sun">{movie.country_name} - {movie.release_date}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="movie__title">
                                                                <p>
                                                                    {movie.movie_title.split(' ').length > 2
                                                                        ? movie.movie_title.split(' ').slice(0, 2).join(' ') + '...'
                                                                        : movie.movie_title}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <p style={{ color: "white", fontFamily: 'i-med' }}>No movies available in this Category.</p>
                                            )}
                                        </div>
                                    </div>
                                    {/* <Allmovies key={navLink.genre_id} selec genreId={navLink.genre_id} genreName={navLink.genre_name} /> */}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            {
                                loading && <div class="spinner-border spinner_color mt-2 text-center" role="status"></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
export default MoviesByFilter;