import { useState, useEffect } from "react";
import { Theme_Variable } from '../../config';
import axios from "axios";
import { API_BASE_URL } from '../../config';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import hd from '../../Images/flutter/hd.png'; // Ensure this image is required
import FlutterBackHeader from "./FlutterBackHeader";

const FlutterListGenreMoviesShows = () => {
    const { id } = useParams();
    const location = useLocation();
    const [listMoviesShowsGenre, setListMoviesShowsGenre] = useState([]); // Default empty array
    const navigate = useNavigate();
    const [pages_spinner, setpagesSpinnerFlutter] = useState(true);
    let childMode = parseInt(localStorage.getItem('child_mode'));
    const subUserSelectedAge = parseInt(localStorage.getItem('sub_account_age'));
    // Apply or remove class based on the route
    useEffect(() => {
        if (location.pathname ==  `/flutter-genre-all-movie-serials/${id}` && Theme_Variable == 'Light') {
            document.body.classList.add('no-black-background');
        } else if (location.pathname ==  `/flutter-genre-all-movie-serials/${id}` && Theme_Variable == 'Dark') {
            document.body.classList.add('black-background');
        } else {
            document.body.classList.remove('no-black-background');
        }
        return () => {
            // Clean up the class when the component unmounts
            document.body.classList.remove('no-black-background');
        };
    }, [location.pathname]);

    // Fetch featured movies and shows
    useEffect(() => {
        setpagesSpinnerFlutter(true)
        axios.post(`${API_BASE_URL}api/v1/flutter_genre_all_movie_serials`, {
            genre_id: id
        })
            .then(response => {


                if (childMode === 1 && subUserSelectedAge) {
                    let Filtered_movies_shows = response.data.VIDEO_STREAMING_APP.genre_movie_show
                        .filter(moviesShowsGenre => {
                            if (moviesShowsGenre.content_rating === '' || parseInt(moviesShowsGenre.content_rating) > subUserSelectedAge) {
                                return false;
                            }
                            const genreKey = Object.keys(moviesShowsGenre)[1];
                            console.log(genreKey);
                            const items = moviesShowsGenre[genreKey];
                            // console.log("Items " + items[0].genre_id);
                            // items.map((item,index)=>(
                            //     console.log(item)
                            // ));
                            // Ensure `items` array exists and filter it based on content rating
                            if (Array.isArray(items) && items.length > 0) {
                                const filteredItems = items.filter(item =>
                                    item.content_rating && parseInt(item.content_rating) <= subUserSelectedAge
                                );
                                // console.log(filteredItems)
                                // moviesShowsGenre[genreKey] = filteredItems; // Update items to only contain filtered items
                                // return filteredItems.length > 0; // Return true if there are items after filtering
                                setListMoviesShowsGenre(filteredItems);
                            }
                            console.log("items"+items)
                            return false; // Skip if `items` is empty or doesn’t exist
                        });

                    // console.log(Filtered_movies_shows);
                    // setListMoviesShowsGenre(Filtered_movies_shows);

                } else {
                    const genreData = response.data?.VIDEO_STREAMING_APP?.genre_movie_show[0];
                    if (genreData) {
                        const movies = genreData[Object.keys(genreData)[1]] || []; // Access the second key (Drama)
                        setListMoviesShowsGenre(movies);
                        console.log(movies);
                    } else {
                        setListMoviesShowsGenre([]); // Reset if no genre data found
                    }
                }


            })
            .catch(err => {
                console.error('Error fetching featured movies/shows:', err);
                setListMoviesShowsGenre([]); // Reset to empty on error
            }).finally(() => {
                setpagesSpinnerFlutter(false)
            })
    }, [id]); // Include `id` in the dependency array


    const handleFlutterMovieShows = (movieShow) => {
        const { movie_id, show_id } = movieShow;
        if (movie_id) {
            navigate(`/flutter-movie-detail-screen/${movie_id}`); // Adjust this path to your movie page route
        } else if (show_id) {
            navigate(`/flutter-show-detail-screen/${show_id}`); // Adjust this path to your show page route
        }
    };
    return (
        <>
        <FlutterBackHeader />
            <section className="all_episodes_fetch">
                <div className={`episodes_row ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                    {listMoviesShowsGenre && listMoviesShowsGenre.length > 0 ? ( // Ensure that listMoviesShowsGenre is defined
                        listMoviesShowsGenre.map((movieShow, index) => (
                            <div className="episodes_main" key={index} onClick={() => handleFlutterMovieShows(movieShow)}>
                                <div className="flutter_movie_show_img flutter_movie_show_featured">
                                    {movieShow.show_id && (
                                        <>
                                            <div
                                                className="serial__over_flutter"
                                                style={{ backgroundImage: `url(${movieShow.show_poster})` }} // Correct usage
                                            ></div>
                                            <div
                                                className="serial__over_2_flutter"
                                                style={{ backgroundImage: `url(${movieShow.show_poster})` }} // Correct usage
                                            ></div>
                                        </>
                                    )}

                                    <img src={movieShow.movie_poster || movieShow.show_poster} alt={movieShow.movie_title || movieShow.show_poster} />
                                </div>
                                <div className="hd_icon">
                                    <img src={hd} alt="HD icon" />
                                </div>
                                <div className="episode_title_flutter">
                                    <div className="episode_title_flutter">
                                        <p className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                            {(() => {
                                                const title = movieShow.movie_title || movieShow.show_title;

                                                if (title && title.length > 0) {
                                                    const words = title.split(' ');
                                                    return words.length > 2 ? `${words.slice(0, 2).join(' ')}...` : title;
                                                }

                                                return ''; // Return an empty string if title length is zero
                                            })()}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="alert_inside_pages">Movies and shows are Not Found</p>
                    )}
                </div>
            </section>

            {pages_spinner && (

                <div className="flutter_section_spinner">
                    <div class="flutter_spinned_sections"></div>
                </div>
            )}


        </>
    );
};

export default FlutterListGenreMoviesShows;
