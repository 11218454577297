import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Translation files
import ar from './Components/locales/ar/ar.json';
import ku from './Components/locales/ku/ku.json';
// import ku from './locales/ku.json';
// import ar from './locales/ar.json';

i18n
  .use(LanguageDetector) // Automatically detect user language
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    resources: {
    //   en: { translation: en },
      ku: { translation: ku },
      ar: { translation: ar },
    },
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false, // React already escapes content
    },
  });

export default i18n;
