import { useState, useEffect } from "react";
import { Theme_Variable } from '../../config';
import { API_BASE_URL } from '../../config';
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import edit_profile from "../../Images/flutter/edit_profile.png";
import login from "../../Images/flutter/login.png";
import dark_about from "../../Images/flutter/about.png";
import dark_contact from "../../Images/flutter/contact.png";
import dark_moon from "../../Images/flutter/moon_dark.png";
import dark__arr from "../../Images/flutter/dark__ar.png";
// White Icons
import white_not_found from '../../Images/flutter/White_icons/black_not_found.png';
import moon_icon_white from '../../Images/flutter/White_icons/white__moon.png';
import about_icon_white from '../../Images/flutter/White_icons/about.png';
import white_arr from '../../Images/flutter/White_icons/white_arr.png'
import phone_icon_white from '../../Images/flutter/White_icons/phone_white.png';
import language_white from '../../Images/flutter/White_icons/internet1.png';
import language_Dark from '../../Images/flutter/internetdark.png';

import { useTranslation } from "react-i18next";

const FlutterBeforeLoginScreen = () => {

    const location = useLocation();
    const UserId = localStorage.getItem('user_id') || '';
    const navigate = useNavigate();
    const { id } = useParams();
    const { t } = useTranslation();
    const [isToggled, setIsToggled] = useState(localStorage.getItem('theme') == 'Dark');

    // useEffect(() => {
    //     if (location.pathname == '/flutter-before-login-screen' && Theme_Variable == 'Light') {
    //         document.body.classList.add('no-black-background');
    //     } else if (location.pathname == '/flutter-before-login-screen' && Theme_Variable == 'Dark') {
    //         document.body.classList.add('black-background');
    //     } else {
    //         document.body.classList.remove('no-black-background');
    //     }
    // }, [location.pathname]);

    useEffect(() => {
        // Apply the theme based on localStorage
        if (isToggled) {
            document.body.classList.add('dark-theme');
            localStorage.setItem('theme', 'Dark');
        } else {
            document.body.classList.remove('dark-theme');
            localStorage.setItem('theme', 'Light');
        }
    }, [isToggled]);

    const handleToggle = () => {
        setIsToggled(prevState => !prevState);
        setTimeout(() => {
            window.location.reload();
        }, 1000)
    };

    const { i18n } = useTranslation();
    const [showOverlay, setShowOverlay] = useState(false);
    // Load the saved language from localStorage
    const openLanguageModal = () => {
        setShowOverlay(true); // Show the overlay only on the flutter-home-screen page
    }
    useEffect(() => {
        const savedLanguage = localStorage.getItem("selectedLanguage");
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
        //  else if (location.pathname == "/flutter-home-screen") {
            // setShowOverlay(true); // Show the overlay only on the flutter-home-screen page
        // }
    }, [i18n, location.pathname]);

    // Handle language change and save it to localStorage
    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem("selectedLanguage", lang);
        setTimeout(() => {
            setShowOverlay(false); // Hide the overlay after selection
            window.location.reload();
        }, 1000);
    };

    const forgetItLanguage = () => {
        setShowOverlay(false); // Show the overlay only on the flutter-home-screen page
    }
   
    return (
        <>
            <section
                className="flutter__before-login_screen"
                style={{ background: Theme_Variable === 'Dark' ? '#262626' : '#FFFFFF' }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="not__logged_img">
                                {
                                    Theme_Variable == 'Dark' ? (
                                        <img src={white_not_found} alt="" />) :
                                        (<img src={login} alt="" />)
                                }
                            </div>
                            <div className="flutter_button__login">
                                <Link to="/flutter-login">{t("Login Or SignUp")}</Link>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className={`links__before_login_flutter ${Theme_Variable == 'Dark' ? 'links_on_profile_flutter_white' : ''}`}>
                                <ul className="list-unstyled p-0 m-0">
                                    <li>
                                        <Link onClick={handleToggle}>
                                            <span className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                                <div className="icon__main_login dark__moon_i">
                                                    {
                                                        Theme_Variable == 'Dark' ? (
                                                            <img src={moon_icon_white} alt="" />) :
                                                            (<img src={dark_moon} alt="" />)
                                                    }
                                                </div>
                                                {isToggled ? `${t("Light Theme")}` : `${t("Dark Theme")}`}
                                            </span>
                                            <div className={`toggle_dark_mode ${isToggled ? 'active active_dark_mode_color' : ''}`}></div>
                                        </Link>
                                    </li>
                                    <li>
                            <Link to="#" onClick={openLanguageModal}>
                                <span className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                    <div className="icon__main_login dark__contact__flutter">
                                        {
                                            Theme_Variable == 'Light' ? (
                                                <img src={language_Dark} alt="" />) :
                                                (<img src={language_white} alt="" />)
                                        }
                                    </div>
                                    {t("selectLanguage", { defaultValue: 'Select Language' })}
                                </span>
                                <div className="left__icon__login__flutter">
                                    {
                                        Theme_Variable == 'Dark' ? (
                                            <img src={white_arr} alt="" />) :
                                            (<img src={dark__arr} alt="" />)
                                    }
                                </div>
                            </Link>
                        </li>
                                    <li>
                                        <Link to="/flutter-about-us">
                                            <span className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                                <div className="icon__main_login dark_about_icon">
                                                    {
                                                        Theme_Variable == 'Dark' ? (
                                                            <img src={about_icon_white} alt="" />) :
                                                            (<img src={dark_about} alt="" />)
                                                    }
                                                </div>
                                                {t("About")}
                                            </span>
                                            <div className="left__icon__login__flutter">
                                                {
                                                    Theme_Variable == 'Dark' ? (
                                                        <img src={white_arr} alt="" />) :
                                                        (<img src={dark__arr} alt="" />)
                                                }
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/flutter-contact-us">
                                            <span className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                                <div className="icon__main_login dark__contact__flutter">
                                                    {
                                                        Theme_Variable == 'Dark' ? (
                                                            <img src={phone_icon_white} alt="" />) :
                                                            (<img src={dark_contact} alt="" />)
                                                    }
                                                </div>
                                                {t("Contact us")}
                                            </span>
                                            <div className="left__icon__login__flutter">
                                                {
                                                    Theme_Variable == 'Dark' ? (
                                                        <img src={white_arr} alt="" />) :
                                                        (<img src={dark__arr} alt="" />)
                                                }
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="show__profile__flutter"></div>
            </section>
            {showOverlay && (
                <section className={`language_dropdown_overlay`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className={`inside_box_main_language ${Theme_Variable == 'Dark' ? 'inside_box_main_language_Dark' : ''}`}>
                                    <div className="head__inside_box">
                                        <p className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{t("selectLanguage", { defaultValue: "Select Language" })}</p>
                                    </div>
                                    <div className="list_langauge_button">
                                        <ul className="list-unstyled p-0 m-0">
                                            <li>
                                                <label htmlFor="input_ku" className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                                    <input
                                                        type="radio"
                                                        name="language"
                                                        value="ku"
                                                        onChange={() => handleLanguageChange("ku")}
                                                        id="input_ku"
                                                    />
                                                    {t("Kurdish")}
                                                </label>
                                            </li>
                                            <li>
                                                <label htmlFor="input_ar" className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                                    <input
                                                        type="radio"
                                                        name="language"
                                                        onChange={() => handleLanguageChange("ar")}
                                                        value="ar"
                                                        id="input_ar"
                                                    />
                                                    {t("Arabic")}
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="cancel__modal__flutter">
                                        <p onClick={() => forgetItLanguage()}>{t("Forgetit", { defaultValue: "Forget it" })}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>

    );
}

export default FlutterBeforeLoginScreen;
