import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import imbd from '../Images/imdb.png';
import caster1 from '../Images/home_images/profile.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import lock from '../Images/home_images/lock.webp';
import { Navigation, Pagination } from 'swiper/modules';
import Movieplayer from "../Components/Movieplayer";
import { API_BASE_URL } from '../config';

const WatchMovie = () => {
    let [userplan, setUserplan] = useState('');
    const { id } = useParams();;
    let UserId = localStorage.getItem('user_id')||0;
    const [suggestionMovies, setMovieSuggestion] = useState([]);
    const [movie_detail, setMovieDetail] = useState([]);
    const [checkplansuscription, setcheckplansuscription] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const subUserSelectedAge = parseInt(localStorage.getItem('sub_account_age'));

    useEffect(() => {
        setLoading(true);
        axios.post(`${API_BASE_URL}api/v1/movies_details`, {
            'user_id':UserId,
            'movie_id': id,
        })
            .then(response => {
                setLoading(false);
                setMovieDetail(response.data.VIDEO_STREAMING_APP)
                // console.log(response.data.VIDEO_STREAMING_APP);
            })
            .catch(error => {
                console.log('There was an error making the request:', error);
            });

        axios.post(`${API_BASE_URL}api/v1/check_user_plan`, {
            user_id: UserId
        })
            .then(response => {
                let checkPlan = response.data.VIDEO_STREAMING_APP;
                setcheckplansuscription(checkPlan.msg);
                setUserplan(response.data.VIDEO_STREAMING_APP);
            }).catch(err => {
                console.log(err);
            })
        // Movies
        axios.post(`${API_BASE_URL}api/v1/movies`, {
            filter: subUserSelectedAge ? 'new' : 'rand',
        })
            .then(response => {

                if (subUserSelectedAge) {
                    let filteredMovies = response.data.VIDEO_STREAMING_APP.movies.filter(movie => {
                        if (movie.content_rating === '') {
                            return false;
                        }
                        return parseInt(movie.content_rating) <= subUserSelectedAge;
                    });
                    let random_movies = filteredMovies.slice(0, 8);
                    console.log(random_movies)
                    setMovieSuggestion(filteredMovies);
                } else {
                    let random_movies = response.data.VIDEO_STREAMING_APP.movies.slice(0, 8);
                    setMovieSuggestion(random_movies);
                }
            })
            .catch(err => {
                console.log('There was an error making the request:', err);
            });

    }, [UserId]);

    // console.log(checkplansuscription);
    const [checkUserCinemaHas, SetcheckUserCinemaHas] = useState(0);

    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/shows_user_cinema_list`, { user_id: UserId || '' })
            .then(response => {
                let movieExists = false; // Initialize as false

                response.data.VIDEO_STREAMING_APP.movies.forEach(movie => {
                    if (movie.movie_id == movie_detail.movie_id) {
                        SetcheckUserCinemaHas(1); // Movie exists, set to 1
                    }
                });
            })
            .catch(err => {
                console.log("something went wrong:" + err);
            });
    }, [UserId, movie_detail.movie_id]);

    useEffect(() => {
        // JavaScript to add Font Awesome icons
        const prevButtons = document.querySelectorAll('.swiper-button-prev');
        const nextButtons = document.querySelectorAll('.swiper-button-next');
        prevButtons.forEach(button => {
            button.innerHTML = '<i class="fas fa-chevron-left"></i>'; // Font Awesome left chevron
        });
        nextButtons.forEach(button => {
            button.innerHTML = '<i class="fas fa-chevron-right"></i>'; // Font Awesome right chevron
        });
    }, []);
    return (
        <>
            <section className="watch__movie videos-inner-wrapper">
                <div className="container-fluid">
                    <div className="inner_video_wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                {
                                    (checkplansuscription === 'My Subscription' || movie_detail.movie_access === 'Free' || checkUserCinemaHas == 1) ? (
                                        <div className="video__screen">
                                            <Movieplayer data={movie_detail} sourceUrl={movie_detail.video_url} poster={movie_detail.movie_image} />
                                        </div>
                                    ) : (
                                        <>
                                            {checkplansuscription === 'Renew subscription plan' && movie_detail.movie_access === 'Paid' && (
                                                <div className="lock_premium">
                                                    <div className="locker_img">
                                                        <img src={lock} alt="Locked Content" loading="lazy" />
                                                    </div>
                                                    <div className="lock__title">
                                                        <Link to="/subscription">Renew your Subscription</Link>
                                                    </div>
                                                </div>
                                            )}

                                            {checkplansuscription === 'Please select subscription plan' && movie_detail.movie_access === 'Paid' && (
                                                <div className="lock_premium">
                                                    <div className="locker_img">
                                                        <img src={lock} alt="Locked Content" loading="lazy" />
                                                    </div>
                                                    <div className="lock__title">
                                                        <Link to="/subscription">Please buy a subscription</Link>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )
                                }


                            </div>
                        </div>
                        <div className="watch__movie_details-main" data-aos="fade-up" data-aos-duration="2000">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="watch_movie-title">
                                        <p>{movie_detail.movie_title}</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="movie__watch-info">
                                        <ul className="d-flex list-unstyled align-items-center m-0">
                                            <li className="imbd_rating_watch">
                                                IMBd
                                                <span>{movie_detail.imdb_rating}</span>
                                            </li>
                                            <li className="duration">
                                                <i className="fa-regular fa-clock fa-fw"></i>
                                                {movie_detail.movie_duration}
                                            </li>
                                            <li className="release_date">
                                                <i class="fa-solid fa-calendar-days"></i>
                                                release_date : {movie_detail.release_date}
                                            </li>
                                            <li className="release_date">
                                                <i class="fa-solid fa-calendar-days"></i>
                                                access : {movie_detail.movie_access}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* More Info */}
            <section className="more_info-watch-movie videos-inner-wrapper">
                <div className="container-fluid">
                    <div className="inner_video_wrapper">
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <div className="about_watch-movie" data-aos="fade-up" data-aos-duration="2000">
                                    <h2>About of the movie</h2>
                                    {/* <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam dolores cum eum.</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: movie_detail.description }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* othe Elements of Movie  */}
            <section className="film__cast-section videos-inner-wrapper">
                <div className="container-fluid">
                    <div className="inner_video_wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="film_cast-title" data-aos="fade-up" data-aos-duration="2000">
                                    <h2>Cast of the {movie_detail.movie_title}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="film_casts" data-aos="fade-up" data-aos-duration="2000">
                            <div className="casters__main">
                                {
                                    movie_detail.actor_list && movie_detail.actor_list.length > 0 ? (
                                        movie_detail.actor_list.map((actor, index) => (
                                            <div className="caster__child" key={index}>
                                                <div className="film_caster-img">
                                                    <img src={actor.ad_image} alt={actor.ad_name} loading="lazy" />
                                                </div>
                                                <div className="film_caster-title">
                                                    <p className="m-0">{actor.ad_name}</p>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="caster__child">
                                            <div className="film_caster-img">
                                                <img src={caster1} />
                                            </div>
                                            <div className="film_caster-title">
                                                <p className="m-0">No Actor Found</p>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* othe Elements of Movie */}
            <section className="other__element-of-movie videos-inner-wrapper">
                <div className="container-fluid">
                    <div className="inner_video_wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="o__element-title" data-aos="fade-up" data-aos-duration="2000">
                                    <h2>Other Elements of the {movie_detail.movie_title} Movie</h2>
                                </div>
                            </div>
                        </div>
                        <div className="other__element-names">
                            <div className="elements_main">
                                {
                                    movie_detail.director_list && movie_detail.director_list.length > 0 ? (
                                        movie_detail.director_list.map((director, index) => (
                                            <div className="elements__child" key={index}>
                                                <div className="role">
                                                    <p>Director Name</p>
                                                </div>
                                                <div className="name_element">
                                                    <p>{director.ad_name}</p>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="elements__child">
                                            <div className="role">
                                                <p>Director Name</p>
                                            </div>
                                            <div className="name_element">
                                                <p>No director found</p>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* You may Also like */}
            <section className="like_section videos-inner-wrapper">
                <div className="container-fluid">
                    <div className="video-inner-wrapper">
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <div className="like_title">
                                    <h2>You may also like</h2>
                                </div>
                            </div>
                        </div>
                        <div className="slider_may_like" data-aos="fade-up" data-aos-duration="2000">
                            <div className="swiper-navigation">
                                <div className="swiper-button-prev"></div>
                                <div className="swiper-button-next"></div>
                            </div>
                            <Swiper
                                slidesPerView={5}
                                spaceBetween={30}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={{
                                    prevEl: '.swiper-button-prev',
                                    nextEl: '.swiper-button-next',
                                }}
                                breakpoints={{
                                    100: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    480: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    992: {
                                        slidesPerView: 5,
                                        spaceBetween: 10,
                                    },
                                }}
                                autoplay={{
                                    delay: 500,
                                }}
                                modules={[Navigation]}
                                className="recommendation_swiper"
                            >
                                {

                                    suggestionMovies.map((suggestions, index) => (
                                        <SwiperSlide>
                                            {/* <div className="you_may_card" onClick={() => navigate(`/movie-page/${suggestions.movie_id}`)}>
                                                <div className="may__movie-img">
                                                    <img src={suggestions.movie_poster} alt="" loading="lazy" />
                                                    <div className="may__movie-overlay">
                                                        <div className="may__movie__info">
                                                            <p className='duration'>Duration: {suggestions.movie_duration}</p>
                                                            <p className='rating d-block text-center'>{suggestions.movie_access}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="like_title_recommend">
                                                    <p>{suggestions.movie_title}</p>
                                                </div>
                                            </div> */}


                                            <div className="recommendation_card" onClick={() => navigate(`/movie-page/${suggestions.movie_id}`)}>
                                                <div className="recommend__movie-img">
                                                    <img src={suggestions.movie_poster} alt="" loading="lazy" />
                                                    <div className="recommend__movie-overlay">
                                                        <div className="recommend-movie__info">
                                                            {/* <p className='duration'>Duration: {suggestions.movie_duration}</p>
                                                            <p className='rating d-block text-center'>{suggestions.movie_access}</p> */}
                                                            <p>
                                                                {
                                                                    suggestions.genre_list && suggestions.genre_list.length > 0
                                                                        ? suggestions.genre_list.slice(0, 2).map(genres => genres.genre_name).join(' - ')
                                                                        : 'No genres available'
                                                                }
                                                            </p>
                                                            <p className="movie_sun">{suggestions.country_name != "" ? suggestions.country_name : ''} - {suggestions.release_date != '' ? suggestions.release_date : ''}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="movie_recommend">
                                                    <p>
                                                        {suggestions.movie_title.split(' ').length > 2
                                                            ? suggestions.movie_title.split(' ').slice(0, 2).join(' ') + '...'
                                                            : suggestions.movie_title}
                                                    </p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))

                                }

                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            {
                loading &&
                <div className="full-screen_loader">
                    <div class="spinner-border spinner_color mt-2 text-center" role="status"></div>
                </div>
            }
        </>
    );
};

export default WatchMovie;
