import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import '../Components/css/style.css';
import { API_BASE_URL } from '../config';
import LiveTvComponent from '../Components/LiveTvComponent';

const AllLiveTvPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const categoryName = queryParams.get('name');
    const { id } = useParams();
    const [livetvvideos, setlivechannel] = useState([]);
    const [loading, setLoading] = useState(false);
    const UserId = localStorage.getItem('user_id');
    const [liveTv, Setlivecategory] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/livetv_category`, {
            filter: 'rand',
        })
            .then(response => {
                console.log(response.data.VIDEO_STREAMING_APP)
                Setlivecategory(response.data.VIDEO_STREAMING_APP);
            }).catch(err => {
                console.log("Live Category is not fetching due to some issues" + err);
            })
    }, []);
    // const livetvDetail = (live_channel_id) => {
    //     navigate(`/live-tv-detail/${live_channel_id}`)

    // }
    return (
        <>
            <section className="live__tv-section videos-inner-wrapper">
                <div className="container-fluid">
                    <div className="inner_video_wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="live_tv__category_name">
                                    <h2>{categoryName}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="live__channels">
                            {
                                liveTv.map((tv, index) => (
                                    <>
                                        <div className="row justify-content-between">
                                            <div className="col-6 col-sm-6 col-md-6">
                                                <div className="live__view_all mb-2 text-left" data-aos="fade-up" data-aos-duration="2000">
                                                    <Link to={`/view-all-live-tv/${tv.category_id}`}>view all <i class="fa-solid fa-angle-left"></i></Link>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-6">
                                                <div className="live_tv__subtitle text-right" data-aos="fade-up" data-aos-duration="2000">
                                                    <h2>{tv.category_name}</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="all_tv__main">
                                            <div className="row">
                                                <LiveTvComponent key={tv.category_id} genreId={tv.category_id} genreName={tv.category_name} />
                                            </div>
                                        </div>
                                    </>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>
            {
                loading &&
                <div className="full-screen_loader">
                    <div class="spinner-border spinner_color mt-2 text-center" role="status"></div>
                </div>
            }
        </>
    );
};

export default AllLiveTvPage;
