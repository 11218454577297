import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";

const FlutterDashboard = () => {
    useEffect(()=> {
        setInterval(()=>{
            window.location.href = '/flutter-profile-page'
        },2000)
    },[])
    return (
        <section className="flutter_dashboard">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="flutter_dashboard_pg">
                            <div className="flutter_section_spinner">
                                <div className="flutter_spinned_sections"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default FlutterDashboard;