import { useState, useEffect } from "react";
import { Theme_Variable } from '../../config';
import { API_BASE_URL } from '../../config';
import movie_1 from '../../Images/movie_1.webp';
// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import axios from "axios";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import photoSlider from '../../Images/photo.png';
import actor_logo from '../../Images/actor.png';
import play_icon from '../../Images/flutter/play.png';
import logo_channel from '../../Images/flutter/logo_ch.png';
import free_movie from '../../Images/flutter/free.png';
import detail_head from '../../Images/flutter/detail_pg.png';
import detail from '../../Images/flutter/detail.png';
import play2 from '../../Images/flutter/play2.png';
import comment from '../../Images/flutter/img.png';
import like from '../../Images/flutter/like.png';
import dis_like from '../../Images/flutter/dislike.png';
import profile from '../../Images/flutter/profile.png';
import forward from '../../Images/flutter/forward.png';
import imdb from '../../Images/flutter/imdb.png';
import icon_list from '../../Images/flutter/icon_list.png';
import play_trailer from '../../Images/flutter/play_trailer.png';
import FlutterNavdetail from "./flutter_component/FlutterNavdetail";
import white_burger from '../../Images/flutter/White_icons/burger_white.png';
import { useTranslation } from "react-i18next";

const FlutterEpisodeDetailPage = () => {

    const location = useLocation();
    const { id } = useParams();
    const [showDetail, setShowDetail] = useState({});
    const [seasons, setseasons] = useState([]);
    const [suggestionShows, setSuggestionShows] = useState([]);
    const navigate = useNavigate();
    const UserId = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0;
    const [allcomments, setallcomments] = useState([]);
    const [pages_spinner, setpagesSpinnerFlutter] = useState(true);
    const [episodeFlutter, setEpisodeFlutter] = useState('');
    const [checkplansuscription, setcheckplansuscription] = useState('');
    let [userplan, setUserplan] = useState('');
    const searchParams = new URLSearchParams(location.search);
    const showDetailId = searchParams.get('show_detail_id');
    const seasonId = searchParams.get('season_id');
    const [nextEpisode, SetNextEpisode] = useState(null);
    const [isQueryLoaded, setIsQueryLoaded] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        if (location.pathname == `/flutter-episode-detail/${id}` && Theme_Variable == 'Light') {
            document.body.classList.add('no-black-background');
        } else if (location.pathname == `/flutter-episode-detail/${id}` && Theme_Variable == 'Dark') {
            document.body.classList.add('black-background');
        } else {
            document.body.classList.remove('no-black-background');
        }
    }, [location.pathname]);

    useEffect(() => {
        const storedQuery = localStorage.getItem('episodeQueryParams');
        if (storedQuery) {
            navigate(`${location.pathname}${storedQuery}`, { replace: true });
            localStorage.removeItem('episodeQueryParams'); // Clean up after using
            setIsQueryLoaded(true);
        } else {
            // If no stored query (e.g., direct load), proceed with API calls immediately
            setIsQueryLoaded(true);
        }
    }, []);
    
    useEffect(() => {
        if (isQueryLoaded) {
            axios.post(`${API_BASE_URL}api/v1/episodes_details`, {
                user_id: UserId || '',
                season_id: seasonId,
                episode_id: id,
            })
                .then(response => {
                    const videoStreamingAppData = response.data.VIDEO_STREAMING_APP[0];
                    setEpisodeFlutter(videoStreamingAppData);
                    setpagesSpinnerFlutter(false);
                })
                .catch(error => {
                    setpagesSpinnerFlutter(false);
                    console.error('Error fetching episodes:', error);
                    setCommentMessage('Error fetching episodes.');
                });
    
            axios.post(`${API_BASE_URL}api/v1/show_details`, {
                show_id: showDetailId,
            })
                .then(response => {
                    let setShowDetails = response.data.VIDEO_STREAMING_APP;
                    setShowDetail(setShowDetails);
                })
                .catch(error => {
                    console.error('There was an error making the request:', error);
                });
    
            axios.post(`${API_BASE_URL}api/v1/shows`, {
                filter: 'new',
            })
                .then(response => {
                    let random_shows = response.data.VIDEO_STREAMING_APP.shows.slice(0, 8);
                    setSuggestionShows(random_shows);
                })
                .catch(err => {
                    console.log('There was an error making the request:', err);
                });
        }
    }, [id, isQueryLoaded]);


    useEffect(() => {
        // Show loading spinner
        setpagesSpinnerFlutter(true);

        axios.post(`${API_BASE_URL}api/v1/episodes`, {
            user_id: UserId,
            season_id: seasonId,
        })
            .then(response => {
                const episodes = response.data.VIDEO_STREAMING_APP;
                const currentEpisodeId = id; // Example current episode ID
                const currentEpisodeIndex = episodes.findIndex(episode => episode.episode_id == currentEpisodeId);

                if (currentEpisodeIndex !== -1 && currentEpisodeIndex < episodes.length - 1) {
                    // Get the next episode
                    const nextEpisode = episodes[currentEpisodeIndex + 1];
                    SetNextEpisode(nextEpisode);
                    // console.log("Next episode details:", nextEpisode);
                } else {
                    SetNextEpisode(null); // No next episode available
                    console.log("No next episode available.");
                }
            })
            .catch(err => {
                console.log(err);
            });

    }, [id]);


    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/check_user_plan`, {
            user_id: UserId
        })
            .then(response => {
                let checkPlan = response.data.VIDEO_STREAMING_APP;
                setcheckplansuscription(checkPlan.msg);
                setUserplan(response.data.VIDEO_STREAMING_APP);
            }).catch(err => {
                console.log(err);
            })
    }, []);

    const [commentBody, setCommentBody] = useState(''); // Controlled input state for the comment
    const [CommentMessage, setCommentMessage] = useState('');
    const [allComments, setAllComments] = useState([]); // State to store all comments

    const listComments = () => {
        axios.post(`${API_BASE_URL}api/v1/list_comment`, {
            commentable_id: id,
            commentable_type: 'Episodes',
            user_id: UserId,
        })
            .then(response => {
                let all_comments = response.data.VIDEO_STREAMING_APP;
                setallcomments(all_comments);
            })
            .catch(err => {
                console.log('There was an error making the request:', err);
            });
    };
    // listComments();

    useEffect(() => {
        listComments(); // Load comments when the component is mounted
    }, [id, UserId]);


    // Open login or add comment
    const openLogin = (e) => {
        e.preventDefault();
        if (UserId) {
            saveCommentToAPI();
        } else {
            navigate('/flutter-login');
        }
    };

    // Function to save comment to the API
    const saveCommentToAPI = () => {
        if (commentBody.trim() == '') {
            setCommentMessage('Comment cannot be empty.');
            return;
        }

        axios.post(`${API_BASE_URL}api/v1/add_comment`, {
            content: commentBody,
            commentable_id: id,
            commentable_type: 'Episodes',
            user_id: UserId,
        })
            .then(response => {
                setCommentMessage(response.data.VIDEO_STREAMING_APP[0].msg);
                const newComment = {
                    content: commentBody, // Adding the new comment's content
                    user: { name: 'You' }, // You can replace this with actual user info if available
                    created_at: new Date().toISOString(), // Add the timestamp for display
                    // Add other fields if necessary
                };
                // Add the new comment to the top of the comments list without refreshing
                setAllComments([newComment, ...allcomments]);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
                // Clear the comment input
                setCommentBody('');
            })
            .catch(error => {
                console.error('Error saving comment:', error);
                setCommentMessage('Error saving comment.');
            });
    };
    // Like Comment Work

    const [likesData, setLikesData] = useState({});

    // Dislike Comment
    const dislikeComment = (comment_id) => {
        axios.post(`${API_BASE_URL}api/v1/unlike_comment`, {
            comment_id: comment_id,
            user_id: UserId || '', // Ensure user ID is passed correctly
        })
            .then(response => {
                // After disliking, fetch the updated counts
                LikeDislikeCount(comment_id);
                setCommentMessage('Comment disliked');
                setTimeout(() => {
                    setCommentMessage('');
                }, 2000);
            })
            .catch(error => {
                console.error('Error disliking comment:', error);
            });
    };

    // Like Comment (assuming you have a similar function for likes)
    const likeComment = (comment_id) => {
        axios.post(`${API_BASE_URL}api/v1/like_comment`, {
            comment_id: comment_id,
            user_id: UserId || '',
        })
            .then(response => {
                // After liking, fetch the updated counts
                LikeDislikeCount(comment_id);
                setCommentMessage('Comment liked');
                setTimeout(() => {
                    setCommentMessage('');
                }, 2000);
            })
            .catch(error => {
                console.error('Error liking comment:', error);
            });
    };

    // Fetch Like and Dislike Count for a specific comment
    const LikeDislikeCount = (comment_id) => {
        axios.post(`${API_BASE_URL}api/v1/list_comment_like_unlike`, {
            commentable_id: comment_id,
            commentable_type: 'Episodes',
        })
            .then(response => {
                // Extract like and dislike counts from the response
                const data = response.data.VIDEO_STREAMING_APP;
                const like_count = data[0]?.total_likes || 0;
                const dislike_count = data[1]?.total_unlikes || 0;

                // Update the state with the new like/dislike count for the comment
                setLikesData(prevData => ({
                    ...prevData,
                    [comment_id]: { like_count, dislike_count }
                }));
            })
            .catch(error => {
                console.error('Error fetching like/dislike count:', error);
            });
    };

    // Ensure Like and Dislike count is fetched for all comments when they are loaded
    useEffect(() => {
        allComments.forEach(comment => {
            LikeDislikeCount(comment.id);
        });
    }, [allComments]);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        // Options to format the date as "MonthName - Day - Year"
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options).replace(',', ' -');
    };
  
    const handleNavigateEpisodes = () => {
        // if (showDetail && showDetail.season_list.length > 0) {
        if (showDetail) {
            navigate(`/flutter-other-episodes?season_id=${showDetail.season_list[0].season_id}&show_detail_id=${showDetailId}`);
        } else {
            console.error('No season list available to navigate.');
        }
    };
    const PlayMovieShowTrailer = (showDetail) => {
        navigate(`/flutter-show-trailer-page/${showDetail.show_id}`);
    }
    const similarRecommendClick = (suggestions) => {
        navigate(`/flutter-show-detail-screen/${suggestions.show_id}`);
    }
    // console.log(showDetail.season_list[0].seasos_id);
    const handleNextEpisode = (next_episode_id) => {
        navigate(`/flutter-episode-detail/${next_episode_id}?season_id=${seasonId}&show_detail_id=${showDetailId}`);
    }

    const handleWatchEpisode = () => {

        if (!UserId) {
            window.location.href = "/flutter-login";
        } else if (episodeFlutter.video_access == 'Free' || checkplansuscription == 'My Subscription') {
            // window.location.href = `/flutter-movie-player/${id}`;
            const queryParams = `?season_id=${showDetail.season_list?.[0]?.season_id}&show_detail_id=${showDetailId}`;
            localStorage.setItem('episodeQueryParams', queryParams); // Save parameters
            window.location.href = `/flutter-watch-episode/${episodeFlutter.episode_id}${queryParams}`;

            return null;
        } else if (['Renew subscription plan', 'Please select subscription plan'].includes(checkplansuscription)) {
            window.location.href = '/flutter-subscription';
            return null;
        }

    }

    return (
        <>
            <FlutterNavdetail user_id={UserId} detail_id={id} type="Episodes" />

            <section className="flutter_detail_page_top" style={{ backgroundImage: `url(${episodeFlutter.episode_image})`, }}>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-12">
                            <div className="detail_page_main">
                                <div className="detail_banner_content">
                                    <h2>{episodeFlutter.episode_title}</h2>
                                    <p>Over  {episodeFlutter.series_content_rating != '' ? showDetail.content_rating : '18'} years old-  Year {episodeFlutter.release_date} - HD</p>
                                </div>
                                <div className="detail_banner_img">
                                    <div className="detail_page_image">
                                        <img src={episodeFlutter.episode_movie_thumb} loading="lazy" alt="" className="w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rating_movie_flutter">
                    <div className="rating_count">
                        <span>{showDetail.imdb_rating}</span>
                    </div>
                    <div className="imbd_logo_flutter">
                        <img src={imdb} alt="" />
                    </div>
                </div>
            </section>

            {/* Flutter Detail movie or show */}
            <section className={`detail_define_movie_show ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="subscription_btn_flutter">
                                <Link to="#" onClick={() => handleWatchEpisode()}>
                                    {
                                        localStorage.getItem('user_id') ? ( // Check if the user is logged in
                                            (checkplansuscription == 'My Subscription' || episodeFlutter.video_access == 'Free') ? (
                                               `${t("Watch Now")}`
                                            ) : (
                                                (checkplansuscription == 'Renew subscription plan' && episodeFlutter.video_access == 'Paid') ? (
                                                      `${t("Buy subscription and watch")}`
                                                ) : (
                                                    (checkplansuscription == 'Please select subscription plan' && episodeFlutter.video_access == 'Paid') ? (
                                                      `${t("Buy subscription and watch")}`
                                                    ) : (
                                                        `${t("Watch")}` // If logged in but not subscribed
                                                    )
                                                )
                                            )
                                        ) : (
                                            `${t("Loginandwatch",{defaultValue:'Login and watch'})}`
                                        )
                                    }
                                    <img src={play2} alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className={`intro_detail ${Theme_Variable == 'Dark' ? 'paratext_dark_mode' : ''}`}>
                                <h2>{t("StoryoftheEpisode",{defaultValue:"Story of the Episode"})}</h2>
                                <p dangerouslySetInnerHTML={{ __html: episodeFlutter.description }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Seasons */}
            {nextEpisode && (
                <section className={`seasons_flutter_section ${Theme_Variable === 'Dark' ? 'section_dark_color' : ''}`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div
                                    className={`seasons_list_flutter ${Theme_Variable === 'Dark' ? 'seasons_list_darkMode_flutter' : ''}`}
                                    onClick={() => handleNextEpisode(nextEpisode.episode_id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div className="season_heading">
                                        <h2 className={`${Theme_Variable === 'Dark' ? 'white_text_mode' : ''}`}>
                                            {t("NextEpisode",{defaultValue:"Next Episode"})}
                                            <span>{nextEpisode.episode_title}</span>
                                        </h2>
                                        <div className="next_episdode_img_flutter">
                                            <img src={episodeFlutter.episode_movie_thumb} alt="" />
                                        </div>
                                    </div>
                                    <i className={`fa-solid fa-angle-left ${Theme_Variable === 'Dark' ? 'white_text_mode' : ''}`}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            <section className={`seasons_flutter_section ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className={`seasons_list_flutter ${Theme_Variable == 'Dark' ? 'seasons_list_darkMode_flutter' : ''}`} onClick={handleNavigateEpisodes} style={{ cursor: 'pointer' }}>
                                <div className="season_heading">
                                    <h2 className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                        {t("otherepisodes",{defaultValue:"Other episodes"})}
                                        </h2>
                                    <div className="season_list_icon">
                                        {
                                            Theme_Variable == 'Light' ? (
                                                <img src={icon_list} alt="" />) :
                                                (<img src={white_burger} alt="" />)
                                        }
                                    </div>
                                </div>
                                <i className={`fa-solid fa-angle-left ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}></i>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Seasons */}
            {/* Flutter Detail movie or show */}
            <section className={`similar_recommendation_flutter ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                <div className="container-fluid">
                    <div className="recomment_movie_title">
                        <h2 className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                        {t("similarrecommendations",{defaultValue:'Similar Recommendations'})}</h2>
                    </div>
                    <Swiper
                        slidesPerView={'auto'}
                        spaceBetween={6}
                        className="similar_movies_main"
                    >
                        {
                            suggestionShows.map((suggestions, index) => (
                                <SwiperSlide className="similar_movies_recommend_flutter"
                                    onClick={() =>
                                        similarRecommendClick(suggestions)
                                    } style={{ cursor: 'pointer' }}>
                                    <div className="free_movie_img">
                                        <img src={suggestions.show_poster} alt="" />
                                    </div>
                                    <div className="movie_show_title title_movie_show">
                                        <p>
                                            {
                                                suggestions.genre_list && suggestions.genre_list.length > 0
                                                    ? suggestions.genre_list.slice(0, 2).map(genres => genres.genre_name).join(' - ')
                                                    : 'No genres available'
                                            }
                                        </p>
                                    </div>
                                </SwiperSlide>
                            ))

                        }
                    </Swiper>
                </div>
            </section>
            {/* Flutter Movie show trailer */}

            {/* <section className={`flutter_trailer_movie_show ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="trailer_flutter_title">
                                <h2 className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>Show Preview</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="trailer_image_flutter" style={{ backgroundImage: `url(${showDetail.show_poster})` }}>
                    <div className="play_trailer_icon"
                        onClick={() => PlayMovieShowTrailer(showDetail)}
                    >
                        <img src={play_trailer} alt="" />
                    </div>
                </div>
            </section> */}

            {/* Flutter Movie show trailer */}
            {/* Production Team Cast And Crew */}

            {/* <section className={`cast_and_crew_flutter ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title_crew">
                                <h2 className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>Production Team</h2>
                            </div>
                            <div className="actors_flutter_movie_show">
                                <div className="title_actor_flutter">
                                    <h2 className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>Actor</h2>
                                    <ul className="actor_names_flutter list-unstyled d-flex">

                                        {
                                            showDetail.actor_list && showDetail.actor_list.length > 0 ? (
                                                showDetail.actor_list.map((actor, index) => (
                                                    <li className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{actor.ad_name}</li>
                                                ))
                                            ) : (
                                                <li className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>No Actor Found</li>
                                            )
                                        }
                                    </ul>
                                </div>

            <div className="title_actor_flutter">
                                    <h2 className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>Director</h2>
                                    <ul className="actor_names_flutter list-unstyled d-flex">
                                        {
                                            showDetail.director_list && showDetail.director_list.length > 0 ? (
                                                showDetail.director_list.map((director, index) => (
                                                    <li className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{director.ad_name}</li>
                                                ))
                                            ) : (
                                                <li className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>No director found</li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* section comments Flutter */}
            {/* section comments Flutter */}
            <section className={`comments_main_flutter ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                <div className="container-fluid">
                    <div className="comments_heading_flutter">
                        <div className="row">
                            <div className="col-6">
                                <p className={`text-left all_comments_clr ${Theme_Variable == 'Dark' ? 'Yellow_color' : ''}`}><i className={`fa-solid fa-angle-left ml-2 ${Theme_Variable == 'Dark' ? 'Yellow_color' : ''}`}></i> {t("allcomments",{defaultValue:'All Comments'})}</p>
                            </div>
                            <div className="col-6">
                                <p className={`text-right ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{t("comments",{defaultValue:'Comments'})}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {allcomments.length > 0 ? (
                                allcomments.map((comment, index) => (
                                    <div className="comments_flutter" key={index}>
                                        <div className="comment_info_main_flutter">
                                            <div className="comment_user_image" style={{
                                                maxWidth: '27px',
                                                minWidth: '27px'
                                            }}>
                                                <img src={profile} alt="User profile" />
                                            </div>
                                            <div className="comment_user_name">
                                            <p className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                                {comment?.user?.name || 'Guest'}
                                            </p>
                                                <span className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{formatDate(comment.created_at)}</span>
                                            </div>
                                        </div>
                                        <div className="comment_body_flutter">
                                            <p className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{comment.content}</p>
                                        </div>
                                        <div className="actions_comments">
                                            {/* Dislike Button */}
                                            <span className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`} onClick={() => dislikeComment(comment.id)}>
                                                <img src={dis_like} alt="Dislike" /> {likesData[comment.id]?.dislike_count || 0}
                                            </span>

                                            {/* Like Button */}
                                            <span className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`} onClick={() => likeComment(comment.id)}>
                                                <img src={like} alt="Like" /> {likesData[comment.id]?.like_count || 0}
                                            </span>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <span className={`no-comments ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{t("nocommentshere",{defaultValue:'No Comments here'})}</span>
                            )}
                        </div>
                    </div>
                    {/*  */}
                    <div className="row">
                        <div className="col-12">
                            <div className="input_comment_flutter">
                                <div className="profile_image_input">
                                    <img src={profile} alt="" />
                                </div>
                                <div className="input_comment">
                                    <form
                                        id="add-new-comment--form"
                                        onSubmit={openLogin}
                                        className="form ui-br-4 d-inline-block w100 ps-relative"
                                    >
                                        <input
                                            name="comment_body"
                                            maxLength="800"
                                            id="comment_body"
                                            type="text"
                                            placeholder={t("writeyourcommentaboutthefilmhere", { defaultValue: "Write your comment about the film here"+"..." })}
                                            value={commentBody}
                                            onChange={(e) => setCommentBody(e.target.value)} // Controlled input
                                        />
                                        {/* Use a button for submit */}
                                        <button type="submit" className="input_icon" style={{ border: 'none', background: 'none', minWidth: '33px' }}>
                                            <img src={forward} alt="Submit" />
                                        </button>
                                    </form>
                                    {CommentMessage && <p className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{CommentMessage}</p>} {/* Display success/error messages */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {pages_spinner && (
                <div className="flutter_detail_page_spinner">
                    <div className="flutter_section_spinner">
                        <div class="flutter_spinned_sections"></div>
                    </div>
                </div>
            )}
        </>
    )
}
export default FlutterEpisodeDetailPage;