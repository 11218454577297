import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from 'react-player';
import axios from "axios";
import { API_BASE_URL } from '../../config';
import save_icon from '../../Images/flutter/newest_player/save-icon.png';
import play_icon from '../../Images/flutter/newest_player/play.png';
import volume_icon from '../../Images/flutter/newest_player/volume.png';
import close_icon from '../../Images/flutter/newest_player/close-button.png';
import volume_on from '../../Images/flutter/newest_player/volume-icon.png';
import volume_off from '../../Images/flutter/newest_player/volume-off.png';
import '../../Components/css/style.css';
import watchListBlackFilled from '../../Images/flutter/watchlistFilledblack.png';
import watchListWhiteFilled from '../../Images/flutter/watchlistFilledwhite.png';
import white3 from '../../Images/flutter/nav/white3.png';

const FlutterNewestShowsPlayer = () => {
    const playerRefs = useRef([]);
    const videoContainerRef = useRef(null);
    const [playing, setPlaying] = useState(true);
    const [videoSources, setVideoSources] = useState([]);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [videoMuted, setVideoMuted] = useState([]);
    const [pages_spinner, setpagesSpinnerFlutter] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [watchlistloading, setwatchlistloading] = useState(false);
    const [preLoading, setpreLoading] = useState(true);
    const UserId = localStorage.getItem('user_id') || 0;
    const [watchListAdded, setWatchListAdded] = useState(false);
    let childMode = parseInt(localStorage.getItem('child_mode'));
    const subUserSelectedAge = parseInt(localStorage.getItem('sub_account_age'));
    // Custom Axios instance with timeout and retries
    const axiosInstance = axios.create({
        baseURL: API_BASE_URL,
        timeout: 4000, // 4 seconds
    });

    useEffect(() => {
        const fetchVideoData = async () => {
            setpagesSpinnerFlutter(true);
    
            try {
                const response = await axiosInstance.post('/api/v1/shows', { filter: 'new' });
                const { VIDEO_STREAMING_APP } = response.data;
                let videoList = [];
    
                // Check child mode and age filtering
                if (childMode === 1 && subUserSelectedAge) {
                    console.log("Child mode active.");
                    videoList = VIDEO_STREAMING_APP.shows.filter(show => {
                        const rating = parseInt(show.content_rating);
                        return show.content_rating && !isNaN(rating) && rating <= subUserSelectedAge;
                    });
                } else {
                    console.log("Adult mode active.");
                    videoList = VIDEO_STREAMING_APP.shows.filter(show => show.show_trailer_url);
                }
    
                const formattedVideos = videoList.map(show => ({
                    show_id: show.show_id,
                    url: show.show_trailer_url,
                    title: show.show_title,
                    logo: show.show_logo,
                }));
    
                setVideoSources(formattedVideos);
                setVideoMuted(new Array(formattedVideos.length).fill(true));
    
            } catch (err) {
                console.error("Error fetching video sources:", err);
            } finally {
                setpagesSpinnerFlutter(false);
            }
        };
    
        fetchVideoData(); // Fetch data whenever childMode changes
    }, [childMode, subUserSelectedAge]); // Dependencies to ensure re-running on mode change
    
    
    useEffect(() => {
        if (videoSources.length === 0) return;

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const index = Number(entry.target.dataset.index);
                if (entry.isIntersecting) {
                    setCurrentVideoIndex(index);
                    setPlaying(true);
                } else if (index === currentVideoIndex) {
                    setPlaying(false);
                }
            });
        }, { threshold: 0.6 });

        const videoElements = videoContainerRef.current.querySelectorAll('.video-item');
        videoElements.forEach((video) => observer.observe(video));

        return () => {
            videoElements.forEach((video) => observer.unobserve(video));
        };
    }, [videoSources, currentVideoIndex]);

    const toggleMute = (index) => {
        setVideoMuted((prevMuted) => {
            const newMuted = [...prevMuted];
            newMuted[index] = !newMuted[index];
            return newMuted;
        });
    };

    const togglePlayPause = () => {
        setPlaying(prev => !prev);
    };

    const handleVideoEnd = () => {
        if (currentVideoIndex < videoSources.length - 1) {
            setCurrentVideoIndex(prevIndex => prevIndex + 1);
            const nextVideo = videoContainerRef.current.querySelector(
                `.video-item[data-index="${currentVideoIndex + 1}"]`
            );
            nextVideo?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleDetailShowNewest = (show_id) => {
        navigate(`/flutter-show-detail-screen/${show_id}`);
    }

    useEffect(() => {
        setpreLoading(true);
        axios
            .post(`${API_BASE_URL}api/v1/my_watchlist`, { user_id: 32 })
            .then(response => {
                const watchlistData = response.data.VIDEO_STREAMING_APP;
                let itemInWatchlist;

                if (videoSources.length > 0) {
                    const currentShowId = videoSources[currentVideoIndex]?.show_id;
                    itemInWatchlist = watchlistData.find(item => item.episode_id == currentShowId && item.post_type == 'Shows');
                    setWatchListAdded(itemInWatchlist);
                }
                setpreLoading(false);
            })
            .catch(err => {
                setpreLoading(false);
                console.error("Watchlist fetching error:", err);
            });
    }, [UserId, currentVideoIndex, videoSources]);

    const WatchLater = () => {
        setWatchListAdded(true);
        setwatchlistloading(true);
        axios.post(`${API_BASE_URL}api/v1/watchlist_add`, {
            user_id: UserId,
            post_id: videoSources[currentVideoIndex]?.show_id,
            post_type: 'Shows'
        })
            .then(() => setwatchlistloading(false))
            .catch(err => {
                setWatchListAdded(false);
                setwatchlistloading(false);
                console.error("Add to watchlist error: ", err);
            });
    };

    const watchlistRemove = () => {
        setwatchlistloading(true);
        axios.post(`${API_BASE_URL}api/v1/watchlist_remove`, {
            user_id: 32,
            post_id: videoSources[currentVideoIndex]?.show_id,
            post_type: 'Shows'
        })
            .then(response => {
                setwatchlistloading(false);
                setWatchListAdded(false);
            })
            .catch(err => {
                setwatchlistloading(false);
                console.log("Delete watchlist error: " + err);
            });
    };

    return (
        <>
            <div ref={videoContainerRef} className="player-wrapper">
                {videoSources.length > 0 ? (
                    videoSources.map((video, index) => (
                        <div key={index} className="video-item" data-index={index}>
                            <ReactPlayer
                                ref={el => playerRefs.current[index] = el}
                                url={video.url}
                                playing={currentVideoIndex === index && playing}
                                volume={videoMuted[index] ? 0 : 0.8}
                                muted={videoMuted[index]}
                                width="100%"
                                height="auto"
                                controls={false}
                                onEnded={handleVideoEnd} // Trigger next video on end
                            />
                            <div className="controls_of_newest_shows">
                                <div className="more_controls_newest_shows">
                                    <div className="volume__icon_flutter" onClick={() => toggleMute(index)}>
                                        <img
                                            src={videoMuted[index] ? volume_off : volume_on}
                                            alt="Volume Control"
                                        />
                                    </div>
                                    <div className="close_newest_movies_shows_icon" onClick={()=>navigate(-1)}>
                                        <img src={close_icon} alt="" />
                                    </div>
                                </div>
                                <div className="navigation_controls_trailers">

                                    <div className="icons_Controls">
                                        <div className="saved_icon_control">
                                            {
                                                !watchListAdded ? (
                                                    !watchlistloading ? (
                                                        <>
                                                            <Link to="#" onClick={WatchLater}>
                                                                <div>
                                                                    <img src={white3} loading="lazy" alt="" />
                                                                </div>
                                                            </Link>

                                                        </>
                                                    ) : (
                                                        <div className="flutter_section_spinner">
                                                            <div className="flutter_spinned_sections" style={{ width: '20px', height: '20px' }}></div>
                                                        </div>
                                                    )
                                                ) : (
                                                    !watchlistloading ? (
                                                        <Link to="#" onClick={watchlistRemove}>
                                                            <div>
                                                                <img src={watchListWhiteFilled} loading="lazy" alt="" />
                                                            </div>
                                                        </Link>
                                                    ) : (
                                                        <div className="flutter_section_spinner">
                                                            <div className="flutter_spinned_sections"style={{ width: '20px', height: '20px' }}></div>
                                                        </div>
                                                    )
                                                )
                                            }
                                            {/* <img src={save_icon} alt="Save" onClick={() => alert(video.show_id)} /> */}
                                        </div>
                                        <div className="play_icon_control" onClick={() => handleDetailShowNewest(video.show_id)}>
                                            {/* <div className="play_icon_control" onClick={togglePlayPause}> */}
                                            <img src={play_icon} alt="Play/Pause" />
                                        </div>
                                    </div>
                                    <div className="title_flutter_videos_actions">
                                        <div className="logo_traier_newest">
                                            <img src={video.logo} alt="" />
                                        </div>
                                        <div className="info_content_newest">
                                            <p>{video.title}</p>
                                            {
                                                childMode == 1 ? (
                                                    <Link to="#">
                                                         Newflix Child
                                                    </Link>
                                                ):(
                                                    ''
                                                )
                                            }
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="section_flutter_newest_loader">
                        <div className="flutter_section_spinner">
                            <div class="flutter_spinned_sections"></div>
                        </div>
                    </div>
                    // <p>Loading videos...</p>
                )}
            </div>
            {
                pages_spinner && (
                    <div className="section_flutter_newest_loader">
                        <div className="flutter_section_spinner">
                            <div class="flutter_spinned_sections"></div>
                        </div>
                    </div>
                )
            }
        </>
    );
}
export default FlutterNewestShowsPlayer;