import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Theme_Variable } from '../../../config';
import { API_BASE_URL } from '../../../config';
import axios from "axios";
// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
// Import icons
import newflix_logo from '../../../Images/newflix-logo.png';
import nav1 from '../../../Images/flutter/nav1.png';
import search_white from '../../../Images/flutter/NewFlix Images/Icons/search_white.png';
import nav2 from '../../../Images/flutter/nav2.png';
import flame from '../../../Images/flutter/NewFlix Images/Icons/fire-icn 1.png';
import flame_black from '../../../Images/flutter/NewFlix Images/Icons/fire-icon-black.png';

const FlutterMainNavTwo = ({ user_id, detail_id, type }) => {

    const [isScrolled, setIsScrolled] = useState(false);
    let UserId = localStorage.getItem('user_id') || 0;
    let [userplan, setUserplan] = useState('');
    const [checkplansuscription, setcheckplansuscription] = useState('');
    const [showNotification, setShowNotification] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 350); // Adjust the scroll threshold as needed
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const profileBtnFlutter = (e) => {
        e.preventDefault();
        if (UserId) {
            navigate('/flutter-profile-page');
        } else {
            navigate('/flutter-before-login-screen');
        }
    };

    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/check_user_plan`, {
            user_id: UserId
        })
            .then(response => {
                let checkPlan = response.data.VIDEO_STREAMING_APP;
                setcheckplansuscription(checkPlan.msg);
                if (sessionStorage.getItem('notificationShown')) {
                    setShowNotification(true);
                    setTimeout(() => {
                        sessionStorage.setItem('notificationShown', 'false');
                        setShowNotification(false);
                    }, 10000);
                }
                sessionStorage.setItem('notificationShown', 'true');
                setUserplan(response.data.VIDEO_STREAMING_APP);
                // console.log(response)
            }).catch(err => {
                console.log(err);
            });
    }, []);
    return (
        <>
            {/* Nav */}
            {/* <section className={`Flutter_nav  */}
            {/* // ${Theme_Variable == 'Dark' ? (isScrolled ? 'scrolled_dark_nav' : 'scrolled') : ''}`}> */}
            <section className={`Flutter_nav  ${Theme_Variable == 'Dark' ? 'scrolled_dark' : ''}`} style={{ background: '#FFBE00',position:'relative'}}>
                <div className="flutter__logo">
                    <div className="flutter__logo_main" onClick={()=>navigate('/flutter-home-screen')} style={{cursor:'pointer'}}>
                        <img src={newflix_logo} alt="" />
                    </div>
                </div>
                <div className="newFlix_links_nav_flutter">
                    <ul className="list-unstyled d-flex">
                        <li>
                            <Link to="#" onClick={profileBtnFlutter}>
                                <div className="icons__nav_main profile_nav_flutter">
                                    <img src={nav1} alt="" />
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/flutter-search-page">
                                <div className="icons__nav_main search_nav_flutter">
                                    <img src={`${Theme_Variable == 'Dark' ? isScrolled ? search_white : search_white : isScrolled ? nav2 : search_white}`} alt="" />
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </section>
            {/* Nav */}
        </>
    );
};

export default FlutterMainNavTwo;
