import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import hd from '../../Images/flutter/hd.png'; // Ensure this image is required
import i9 from '../../Images/flutter/NewFlix Images/i9.png';
import screen from '../../Images/flutter/screen.png';
import eds from '../../Images/flutter/eds.png';
import headphone from '../../Images/flutter/headphone.png';
import plygon from '../../Images/flutter/Polygon.png';
import email from '../../Images/flutter/email.png';
import phone from '../../Images/flutter/phone.png';
import question from '../../Images/flutter/question.png';
import { API_BASE_URL } from '../../config';
import FlutterBackHeader from "./FlutterBackHeader";
import { useTranslation } from "react-i18next";

const FlutterLoginOtp = () => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [alert_otp, setOtpalert] = useState('');
    const [isInputInvalid, setIsInputInvalid] = useState(false);
    const [loading, setLoading] = useState(false);
    const phoneNumber = localStorage.getItem("phoneNumberVerification");
    const [pages_spinner, setpagesSpinnerFlutter] = useState(true);

    // State for OTP code
    const [code, setCode] = useState(Array(6).fill('')); // Initializes an array of 6 empty strings

    // Apply or remove class based on the route
    useEffect(() => {
        document.body.classList.toggle('no-black-background', location.pathname === `/flutter-login-otp`);

        return () => {
            document.body.classList.remove('no-black-background');
        };
    }, [location.pathname]);

    const [isListOpen, setIsListOpen] = useState(false);
    const handleToggle = () => {
        setIsListOpen(!isListOpen);
    };

    // Otp input references
    const inputRefs = useRef([]);
    useEffect(() => {
        inputRefs.current[0].focus();
    }, []);

    const handleChange = (e, index) => {
        const value = e.target.value;

        // Check if the input value is a digit (0-9)
        if (!/^\d*$/.test(value)) {
            return; // Do nothing if it's not a digit
        }

        // Update the code array
        const updatedCode = [...code];
        updatedCode[index] = value;
        setCode(updatedCode);

        if (value && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }

        // If all inputs are filled, trigger verification
        if (updatedCode.every(digit => digit !== '')) {
            handleVerifyClick(updatedCode); // Pass the updated code to the verification function
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && index > 0 && !e.target.value) {
            inputRefs.current[index - 1].focus();
        }
    };

    // Timer functionality
    const [timer, setTimer] = useState(60);
    const [showResendMessage, setShowResendMessage] = useState(false);

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
            return () => clearInterval(interval); // Clear interval when component unmounts or timer changes
        } else {
            setShowResendMessage(true); // Show the "Send code again" message when timer reaches 0
        }
    }, [timer]);

    // Verification OTP work
    const handleVerifyClick = (codeToVerify) => {
        setpagesSpinnerFlutter(true);
        setLoading(true);
        setOtpalert(''); // Clear previous alerts
        setIsInputInvalid(false); // Reset input validation state

        const codeConverted = parseInt(codeToVerify.join(''), 10);
        axios.post(`${API_BASE_URL}api/v1/verify_code`, {
            phone: `+${phoneNumber}`,
            code: codeConverted
        })
            .then(response => {
                setLoading(false);
                const result = response.data.VIDEO_STREAMING_APP[0];
                setOtpalert(result.msg);
                console.log(response);
                setpagesSpinnerFlutter(false);

                // Check if the response is successful
                if (result.success == 1) {
                    const user = response.data.VIDEO_STREAMING_APP[1];
                    localStorage.setItem('user_id', user['user_id']);
                    localStorage.setItem('user_image', user['user_image']);
                    localStorage.setItem('session_name', user['user_session_name']);
                    localStorage.setItem('user_name', 'Admin');
                    localStorage.setItem('age', 1000);
                    const planName = localStorage.getItem('planName');
                    if (!planName) {
                        window.location.href = `/flutter-profile-page`;
                    } else {
                        window.location.href = '/payment-process';
                    }
                } else {
                    setIsInputInvalid(true); // Set input as invalid
                    setOtpalert('Invalid code. Please try again.');
                    setpagesSpinnerFlutter(false);
                }
            })
            .catch(error => {
                console.error(error);
                setOtpalert('An error occurred. Please try again.');
                setIsInputInvalid(true); // Set input as invalid
                setLoading(false);
            });
    };

    // Resend OTP functionality
    const handleSendCodeAgain = () => {
        setTimer(60); // Reset the timer to 60 seconds
        setShowResendMessage(false); // Hide the "Send code again" message
        setLoading(true); // Show loading indicator

        axios.post(`${API_BASE_URL}api/v1/signup`, {
            phone: `+${phoneNumber}`
        })
            .then(response => {
                setLoading(false); // Hide loading indicator
                if (response.data.VIDEO_STREAMING_APP[0].success === 0) {
                    setOtpalert(response.data.VIDEO_STREAMING_APP[0].msg);
                } else {
                    setOtpalert("A new code has been sent to your phone.");
                    setShowResendMessage(false); // Hide the resend message to wait for another timer interval
                    setTimer(60); // Start a new timer for 60 seconds
                }
            })
            .catch(error => {
                setLoading(false); // Hide loading indicator
                console.error("Error resending OTP:", error);
                setOtpalert("An error occurred. Please try again.");
            });
    };

    return (
        <>
            <FlutterBackHeader />

            <section className="flutter_login_main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="title_login_flutter">
                                <h2>{t("Enter the code sent for")} {phoneNumber} </h2>
                            </div>

                            <div className="login__form_flutter_main">
                                <div className="login__form_flutter" style={{ direction: 'ltr' }}>
                                    <div className="otp_boxes_main">
                                        {Array(6).fill().map((_, index) => (
                                            <input
                                                key={index}
                                                type="text"
                                                size={1}
                                                maxLength={1}
                                                className={`input_fileds_otp ${isInputInvalid ? 'invalid' : ''}`}
                                                ref={(el) => (inputRefs.current[index] = el)}
                                                onChange={(e) => handleChange(e, index)}
                                                onKeyDown={(e) => handleKeyDown(e, index)}
                                            />
                                        ))}
                                    </div>
                                    <div className="opt_timer_flutter">
                                        {showResendMessage ? (
                                            <p onClick={handleSendCodeAgain}>Didn't receive the code? Send code again.</p>
                                        ) : (
                                            <p>Please wait {timer} seconds before requesting a new code.</p>
                                        )}
                                    </div>
                                    {pages_spinner && (
                                        <div className="flutter_section_spinner">
                                            <div className="flutter_spinned_sections"></div>
                                        </div>
                                    )}
                                    {alert_otp && (
                                        <div className={`${isInputInvalid ? 'err_flutter_login' : 'err_flutter_login_success'}`} role="alert">
                                            {alert_otp}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="social_contact_flutter_login">
                                <p className="m-0" onClick={handleToggle}>
                                    <div className="head_phone_icon">
                                        <img src={headphone} alt="" />
                                    </div>
                                    Support
                                    <div className={`polygon ${isListOpen ? 'polygon_icon_rotate' : ''}`}>
                                        <img src={plygon} alt="" />
                                    </div>
                                </p>
                                <div className={`social_contact_list_flutter ${isListOpen ? 'social_contact_list_flutter_active' : ''}`}>
                                    <ul>
                                        <li>
                                            <div className="list_icons_flutter">
                                                <img src={email} alt="" />
                                            </div> Email : <Link>newflix@gmail.com</Link>
                                        </li>
                                        <li>
                                            <div className="list_icons_flutter">
                                                <img src={phone} alt="" />
                                            </div> Phone : <Link>0215652455</Link>
                                        </li>
                                        <li>
                                            <div className="list_icons_flutter">
                                                <img src={question} alt="" />
                                            </div> FAQ : <Link>newflix@gmail.com</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FlutterLoginOtp;
