import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import myVideo from '../asstes/TheSociety.mp4';
// import audio from '../asstes/audio.mp3';
import subtitle from '../asstes/sample.vtt';
// import subtitle from '../asstes/subtitle.srt';
import { IconButton, Slider, Menu, MenuItem, Button, ListItemIcon, ListItemText } from '@mui/material';
import { PlayArrow, Pause, VolumeUp, VolumeOff, Fullscreen, Forward10, Replay10, PictureInPictureAlt, Speed, Subtitles } from '@mui/icons-material';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import MovieIcon from '@mui/icons-material/Movie'; // Icon for the button
import HighQualityIcon from '@mui/icons-material/HighQuality';

import '../Components/css/style.css';
import { Link, useLocation } from 'react-router-dom'; // Assuming you're using react-router for linking

const Movieplayer = ({ data, sourceUrlFromParameter, poster, episodesSeasons }) => {
    // Ensure it's always an array
    const [EpisodeBySeasons, setEpisodeBySeasons] = useState([]);

    useEffect(() => {
        // Safeguard: If `episodesSeasons` is undefined or null, default to an empty array
        setEpisodeBySeasons(episodesSeasons || []);
    }, [episodesSeasons]);

    const [anchorElEpisodes, setAnchorElEpisodes] = useState(null);

    // console.log(EpisodeBySeasons);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const seasonId = searchParams.get('season_id');

    const parseSubtitles = (subtitleText) => {
        // Remove the WEBVTT header if it exists
        const subtitleContent = subtitleText.trim().startsWith('WEBVTT')
            ? subtitleText.trim().substring(6).trim()
            : subtitleText;

        const subtitleArray = subtitleContent.split('\n\n').map((subtitle) => {

            const lines = subtitle.split('\n');

            // Extract the time and text lines
            const timeLine = lines[0];
            const textLines = lines.slice(1);

            // Ensure time format and parse
            if (timeLine && timeLine.includes(' --> ')) {
                const [startTime, endTime] = timeLine.split(' --> ');

                return {
                    startTime: parseTime(startTime),
                    endTime: parseTime(endTime),
                    text: textLines.join(' ')
                };
            } else {
                console.error('Invalid time format:', timeLine);
            }

            return null;
        }).filter(subtitle => subtitle !== null);

        return subtitleArray;
    };

    const parseTime = (time) => {

        const [hours, minutes, secondsWithMs] = time.split(':');
        const [seconds, milliseconds] = secondsWithMs.split('.');

        const totalSeconds =
            parseInt(hours, 10) * 3600 +  // Convert hours to seconds
            parseInt(minutes, 10) * 60 +  // Convert minutes to seconds
            parseInt(seconds, 10) +        // Add seconds
            parseInt(milliseconds, 10) / 1000; // Convert milliseconds to seconds and add

        return totalSeconds;
    };

    const playerRef = useRef(null);
    const audioRef = useRef(null);  // Create a ref for the audio element
    const containerRef = useRef(null); // Reference to the container for fullscreen toggle

    const [volume, setVolume] = useState(0.8);
    const [muted, setMuted] = useState(false);
    const [played, setPlayed] = useState(0);
    const [fullscreen, setFullscreen] = useState(false);
    const [pip, setPip] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [speed, setSpeed] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [subtitlesIndex, setSubtitlesIndex] = useState(null);
    const [anchorElSubtitles, setAnchorElSubtitles] = useState(null);

    const [subtitles, setSubtitles] = useState([]);
    const [currentSubtitle, setCurrentSubtitle] = useState('');

    const [selectedSubtitleUrl, setSelectedSubtitleUrl] = useState(null);

    const [audioTrackIndex, setAudioTrackIndex] = useState(null);
    const [anchorElAudio, setAnchorElAudio] = useState(null);
    const [audioTracks, setAudioTracks] = useState([]);
    const [audioReady, setAudioReady] = useState(false); // Track if audio is ready to play
    const [buffering, setBuffering] = useState(false); // Track buffering state
    const [seeking, setSeeking] = useState(false); // Handle manual seeking
    const [isFullscreen, setIsFullscreen] = useState(false); // Track fullscreen state

    // State for managing video quality options
    const [videoQualities, setVideoQualities] = useState([]);  // Array of video qualities (e.g., 720p, 1080p, etc.)

    // State for keeping track of the currently selected video quality index
    const [videoQualityIndex, setVideoQualityIndex] = useState(null); // Index of the selected quality

    // State to store the anchor element for the video quality menu (used to open/close the menu)
    const [anchorElQuality, setAnchorElQuality] = useState(null); // Element to anchor the quality selection menu

    // State for the video source URL (this will change depending on selected quality)
    const [sourceUrl, setSourceUrl] = useState(sourceUrlFromParameter); // URL for the currently selected video quality

    const [loading, setLoading] = useState(true);
    const [isPlayerReady, setIsPlayerReady] = useState(false);
    const [hasPlayed, setHasPlayed] = useState(false);
    const [playing, setPlaying] = useState(false);

    // Fullscreen toggle function
    const handleFullscreenToggle = () => {
        if (!isFullscreen) {
            if (containerRef.current.requestFullscreen) {
                containerRef.current.requestFullscreen();
            } else if (containerRef.current.mozRequestFullScreen) { // Firefox
                containerRef.current.mozRequestFullScreen();
            } else if (containerRef.current.webkitRequestFullscreen) { // Chrome, Safari, and Opera
                containerRef.current.webkitRequestFullscreen();
            } else if (containerRef.current.msRequestFullscreen) { // IE/Edge
                containerRef.current.msRequestFullscreen();
            }
            setIsFullscreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { // Firefox
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { // Chrome, Safari, and Opera
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { // IE/Edge
                document.msExitFullscreen();
            }
            setIsFullscreen(false);
        }
    };


    // // Handle seeking (Slider) for video progress
    // const handleSeekChange = (e, newValue) => {
    //     console.log(newValue);
    //     setPlayed(newValue); // Update played state (percentage)
    //     setCurrentTime(newValue);
    // };


    // Handle seek bar change
    // const handleSeekChange = (e, newValue) => {
    //     console.log("Seek Change:", newValue);
    //     setPlayed(newValue); // Update played state (percentage)
    //     const internalPlayer = playerRef.current;

    //     if (newValue === 100) {
    //         // Video has reached the end, stop playback
    //         setPlaying(false); // Update the playing state
    //         setHasPlayed(false); // Mark that playback has stopped

    //         // Pause video playback
    //         if (playerRef.current) {
    //             const internalPlayer = playerRef.current.getInternalPlayer();
    //             internalPlayer.pause(); // Pause video when it reaches 100%
    //         }

    //         // Pause audio playback
    //         if (audioRef.current) {
    //             audioRef.current.pause(); // Pause audio when video reaches 100%
    //         }

    //         // Seek video back to the start (0 seconds)
    //         internalPlayer.seekTo(0);
    //         setPlayed(0); // Reset the played percentage to 0
    //         setCurrentTime(0); // Optionally reset the current time to 0
    //     } else {
    //         // Update the current time based on the new value (played percentage)
    //         const duration = playerRef.current.getDuration();
    //         const currentTime = (newValue / 100) * duration;
    //         setCurrentTime(currentTime); // Update current time
    //     }
    // };

    // // Handle the seek bar release (mouse up) after dragging the seek bar
    // const handleSeekMouseUp = () => {
    //     console.log("handleSeekMouseUp: Played percentage is " + played);

    //     if (!loading && isPlayerReady) {
    //         // Get the total duration of the video
    //         const duration = playerRef.current.getDuration();

    //         // Handle the case where the played percentage reaches 100%
    //         if (played === 100) {
    //             setPlayed(0); // Reset the played percentage to 0
    //             setCurrentTime(0); // Optionally reset the current time to 0
    //             playerRef.current.seekTo(0, 'seconds'); // Seek video back to 0 seconds

    //             // Sync audio if it exists
    //             if (audioRef.current) {
    //                 audioRef.current.currentTime = 0; // Reset audio's current time to 0
    //             }
    //         } else {
    //             // Calculate the time to seek to based on the played percentage
    //             const seekTo = (played / 100) * duration;
    //             playerRef.current.seekTo(seekTo, 'seconds'); // Seek video based on seconds

    //             // Sync audio if it exists
    //             if (audioRef.current) {
    //                 audioRef.current.currentTime = seekTo; // Sync audio with video
    //             }
    //         }
    //     }

    //     setSeeking(false); // Stop seeking
    // };


    // const handleProgress = (state) => {
    //     console.log("handleProgress")
    //     if (!seeking) {
    //         setPlayed(state.played * 100);
    //         // Ensure the current time is fetched correctly from the internal player
    //         const currentTime = playerRef.current
    //             ? playerRef.current.getCurrentTime()
    //             : 0;
    //         // console.log(playerRef.current);
    //         setCurrentTime(currentTime); // Set the current time in state
    //     }
    // };

    // const handleSeekMouseDown = () => {
    //     setSeeking(true); // Start seeking
    // };

    const handleSeekChange = (e, newValue) => {
        console.log("Seek Change:", newValue);
        setPlayed(newValue); // Update played state (percentage)

        if (newValue === 100) {
            // Video has reached the end, stop playback
            setPlaying(false); // Update the playing state
            setHasPlayed(false); // Mark that playback has stopped

            // Pause video playback
            if (playerRef.current) {
                const internalPlayer = playerRef.current.getInternalPlayer();
                internalPlayer.pause(); // Pause video when it reaches 100%
            }

            // Pause audio playback
            if (audioRef.current) {
                audioRef.current.pause(); // Pause audio when video reaches 100%
            }

            // Keep the played percentage at 100 when the video completes
        } else {
            // Update the current time based on the new value (played percentage)
            const duration = playerRef.current.getDuration();
            const currentTime = (newValue / 100) * duration;
            setCurrentTime(currentTime); // Update current time
        }
    };
    const handleSeekMouseUp = () => {
        console.log("handleSeekMouseUp: Played percentage is " + played);

        if (!loading && isPlayerReady) {
            const duration = playerRef.current.getDuration();

            // Avoid resetting to 0 when the video naturally finishes
            if (played === 100) {
                // Keep the played percentage at 100 when the video completes
                setCurrentTime(duration); // Ensure current time reflects the video end
                playerRef.current.seekTo(duration, 'seconds'); // Seek to end

                // Sync audio if it exists
                if (audioRef.current) {
                    audioRef.current.currentTime = duration; // Sync audio with video end
                }
            } else {
                const seekTo = (played / 100) * duration;
                playerRef.current.seekTo(seekTo, 'seconds'); // Seek video based on seconds

                // Sync audio if it exists
                if (audioRef.current) {
                    audioRef.current.currentTime = seekTo; // Sync audio with video
                }
            }
        }

        setSeeking(false); // Stop seeking
    };

    const handleProgress = (state) => {
        console.log("handleProgress");
        if (!seeking) {
            setPlayed(state.played * 100);

            // Ensure the current time is fetched correctly from the internal player
            const currentTime = playerRef.current
                ? playerRef.current.getCurrentTime()
                : 0;

            // When the video reaches the end, keep it at 100%
            if (state.played === 1) {
                setPlayed(100); // Keep played percentage at 100
            }

            setCurrentTime(currentTime); // Set the current time in state
        }
    };

    const handleSeekMouseDown = () => {
        setSeeking(true); // Start seeking
    };

    // // Toggle Play/Pause
    // const handlePlayPause = () => {
    //     if (!hasPlayed) {
    //         // Play both video and audio when starting
    //         setPlaying(true);
    //         setHasPlayed(true);
    //         if (audioRef.current) {
    //             audioRef.current.play(); // Start audio playback
    //         }
    //     } else {
    //         // Pause both video and audio when stopping
    //         setPlaying(false);
    //         setHasPlayed(false);

    //         if (audioRef.current) {
    //             audioRef.current.pause(); // Pause audio playback
    //         }
    //     }
    // };


    // Toggle Play/Pause
    const handlePlayPause = () => {
        if (!playing) {
            // Play both video and audio when starting
            setPlaying(true);
            setHasPlayed(true);

            if (playerRef.current) {
                // Access the internal video player
                const internalPlayer = playerRef.current.getInternalPlayer();

                // Play the video
                const playPromise = internalPlayer.play();
                if (playPromise !== undefined) {
                    playPromise
                        .then(() => {
                            console.log("Video playback started successfully");
                        })
                        .catch((error) => {
                            console.error("Error occurred while playing the video:", error);
                        });
                }
            }

            // Play the audio if it's ready
            if (audioRef.current) {
                const audioPlayPromise = audioRef.current.play(); // Start audio playback
                if (audioPlayPromise !== undefined) {
                    audioPlayPromise
                        .then(() => {
                            console.log("Audio playback started successfully");
                        })
                        .catch((error) => {
                            console.error("Error occurred while playing the audio:", error);
                        });
                }
            }
        } else {
            // Pause both video and audio when stopping
            setPlaying(false);
            setHasPlayed(false);

            if (playerRef.current) {
                // Access the internal video player
                const internalPlayer = playerRef.current.getInternalPlayer();

                // Pause the video
                const pausePromise = internalPlayer.pause();
                if (pausePromise !== undefined) {
                    pausePromise
                        .then(() => {
                            console.log("Video paused successfully");
                        })
                        .catch((error) => {
                            console.error("Error occurred while pausing the video:", error);
                        });
                }
            }

            // Pause the audio
            if (audioRef.current) {
                const audioPausePromise = audioRef.current.pause(); // Pause audio playback
                if (audioPausePromise !== undefined) {
                    audioPausePromise
                        .then(() => {
                            console.log("Audio paused successfully");
                        })
                        .catch((error) => {
                            console.error("Error occurred while pausing the audio:", error);
                        });
                }
            }
        }
    };

    const handlePlay = async () => {
        if (!loading && isPlayerReady && !playing) { // Ensure it's not already playing
            try {
                // Play video using promise
                if (playerRef.current) {
                    const internalPlayer = playerRef.current.getInternalPlayer();
                    if (internalPlayer.paused) { // Check if the video is paused before calling play
                        const playPromise = internalPlayer.play();

                        if (playPromise !== undefined) {
                            await playPromise;
                            console.log("Video playback started successfully");
                        }
                    }
                }

                // Play audio if it's ready and not already playing
                if (audioRef.current && audioRef.current.paused) {
                    const audioPlayPromise = audioRef.current.play();

                    if (audioPlayPromise !== undefined) {
                        await audioPlayPromise;
                        console.log("Audio playback started successfully");
                    }
                }

                // After promises resolve, update state
                setPlaying(true);
                setHasPlayed(true);

            } catch (error) {
                console.error("Error occurred while starting playback:", error);
            }
        }
    };

    const handlePause = async () => {
        if (!loading && isPlayerReady && playing) { // Ensure it's currently playing
            try {
                // Pause the video using promise
                if (playerRef.current) {
                    const internalPlayer = playerRef.current.getInternalPlayer();
                    if (!internalPlayer.paused) { // Check if the video is playing before pausing
                        const pausePromise = internalPlayer.pause();

                        if (pausePromise !== undefined) {
                            await pausePromise;
                            console.log("Video paused successfully");
                        }
                    }
                }

                // Pause the audio if it's playing
                if (audioRef.current && !audioRef.current.paused) {
                    const audioPausePromise = audioRef.current.pause();

                    if (audioPausePromise !== undefined) {
                        await audioPausePromise;
                        console.log("Audio paused successfully");
                    }
                }

                // After promises resolve, update state
                setPlaying(false);
                setHasPlayed(false);

            } catch (error) {
                console.error("Error occurred while pausing playback:", error);
            }
        }
    };

    //    const handlePlay = () => {
    //     if (!loading && isPlayerReady) {
    //         setPlaying(true);
    //         setHasPlayed(true);
    //         // playerRef.current.play();
    //         if (audioRef.current) {
    //             audioRef.current.play(); // Start audio if it's ready
    //         }
    //     }
    // };


    //  const handlePause = () => {
    //     if (!loading && isPlayerReady) {
    //         setPlaying(false);
    //         setHasPlayed(false);

    //         // playerRef.current.pause();
    //         if (audioRef.current) {
    //             audioRef.current.pause(); // Pause audio if it's playing
    //         }
    //     }
    // };

    // Handle video/audio mute
    const toggleMute = () => {
        setMuted(!muted);
        if (audioRef.current) {
            audioRef.current.muted = !muted;
        }
    };

    // Handle buffering: pause audio when video buffers, resume when video resumes
    // const handleBuffer = () => {
    //     setBuffering(true);
    //     if (audioRef.current) {
    //         audioRef.current.pause(); // Pause audio while buffering
    //     }
    // };

    const handleBuffer = () => {
        setBuffering(true);
        setPlaying(false);
        setHasPlayed(false);

        if (playerRef.current) {
            try {
                // Access the internal player (assuming it has a method to get it)
                const internalPlayer = playerRef.current.getInternalPlayer();

                // Attempt to pause both video and audio while buffering
                const promiseVideoBuffer = internalPlayer.pause(); // Change this to internalPlayer
                const promiseAudioBuffer = audioRef.current ? audioRef.current.pause() : Promise.resolve();

                // Check if the video pause returned a promise
                if (promiseVideoBuffer && typeof promiseVideoBuffer.then === 'function') {
                    promiseVideoBuffer
                        .then(() => {
                            console.log("Video paused successfully during buffering");
                        })
                        .catch((error) => {
                            console.log("Error pausing video:", error);
                        });
                } else {
                    console.log("Video paused successfully during buffering (no promise returned)");
                }

                // Handle audio pause
                if (promiseAudioBuffer && typeof promiseAudioBuffer.then === 'function') {
                    promiseAudioBuffer
                        .then(() => {
                            console.log("Audio paused successfully during buffering");
                        })
                        .catch((error) => {
                            console.log("Error pausing audio:", error);
                        });
                } else {
                    console.log("Audio paused successfully during buffering (no promise returned)");
                }
            } catch (error) {
                console.log("Error while trying to pause video or audio:", error);
            }
        } else {
            console.log("No video reference available to pause.");
        }

        // Reset buffering state when done
        setBuffering(false);
    };


    const handleBufferEnd = () => {
        setBuffering(false);

        if (playerRef.current && audioRef.current) {
            try {
                // Access the internal player
                const internalPlayer = playerRef.current.getInternalPlayer();

                // Resume both video and audio after buffering ends
                const promiseVideoPlay = internalPlayer.play();
                const promiseAudioPlay = audioRef.current.src ? audioRef.current.play() : Promise.resolve(); // Ensure valid audio source

                // Handle video playback
                if (promiseVideoPlay && typeof promiseVideoPlay.then === 'function') {
                    promiseVideoPlay
                        .then(() => {
                            setHasPlayed(true);
                            setPlaying(true);
                            console.log("Video resumed successfully after buffering ended.");
                        })
                        .catch((error) => {
                            console.log("Error resuming video:", error);
                        });
                } else {
                    console.log("Video resumed successfully after buffering ended (no promise returned)");
                }

                // Handle audio playback
                if (promiseAudioPlay && typeof promiseAudioPlay.then === 'function') {
                    promiseAudioPlay
                        .then(() => {
                            console.log("Audio resumed successfully after buffering ended.");
                        })
                        .catch((error) => {
                            console.log("Error resuming audio:", error);
                        });
                } else {
                    console.log("Audio resumed successfully after buffering ended (no promise returned)");
                }
            } catch (error) {
                console.log("Error while trying to resume video or audio:", error);
            }
        } else {
            if (!playerRef.current) {
                console.log("No video reference available to play.");
            }
            if (!audioRef.current || !audioRef.current.src) {
                console.log("No valid audio reference or source available to play.");
            }
        }

        // Reset buffering state when done
        setBuffering(false);
    };

    // const handleBufferEnd = () => {
    //     setBuffering(false);
    //     if (playing && audioRef.current) {
    //         audioRef.current.play(); // Resume audio when buffering ends, if video is playing
    //     }
    // };



    const handleVolumeChange = (e, newValue) => {
        const normalizedVolume = newValue / 100; // Convert 0-100 to 0-1
        setVolume(normalizedVolume);
        if (audioRef.current) {
            audioRef.current.volume = normalizedVolume;
        }
    };



    const handleFullscreen = () => {
        const playerElement = playerRef.current.wrapper;
        if (fullscreen) {
            document.exitFullscreen();
        } else {
            playerElement.requestFullscreen();
        }
        setFullscreen(!fullscreen);
    };

    const handleForward = () => {
        const currentTime = playerRef.current.getCurrentTime();
        playerRef.current.seekTo(currentTime + 10, 'seconds');
        if (audioRef.current) {
            audioRef.current.currentTime = playerRef.current.getCurrentTime(); // Sync audio with video
        }
    };

    const handleBackward = () => {
        const currentTime = playerRef.current.getCurrentTime();
        playerRef.current.seekTo(currentTime - 10, 'seconds');
        if (audioRef.current) {
            audioRef.current.currentTime = playerRef.current.getCurrentTime(); // Sync audio with video
        }
    };

    const handlePiP = async () => {
        try {
            if (pip) {
                // Exit Picture-in-Picture
                await document.exitPictureInPicture();
                setPip(false);
            } else {
                // Enter Picture-in-Picture
                const videoElement = playerRef.current.getInternalPlayer();
                if (videoElement) {
                    await videoElement.requestPictureInPicture();
                    setPip(true);
                }
            }
        } catch (error) {
            console.error('Failed to toggle Picture-in-Picture:', error);
        }
    };


    const handleSpeedChange = (newSpeed) => {
        setSpeed(newSpeed);
        playerRef.current.getInternalPlayer().playbackRate = newSpeed;
        if (audioRef.current) {
            audioRef.current.playbackRate = playerRef.current.getInternalPlayer().playbackRate; // Sync audio with video
        }
        setAnchorEl(null);
    };

    const handleClickSpeedMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseSpeedMenu = () => {
        setAnchorEl(null);
    };

    const handleSubtitlesChange = (index) => {
        if (index === null) {
            setSelectedSubtitleUrl(null); // Turn off subtitles
        } else {
            const urls = [data.subtitle_url1, data.subtitle_url2, data.subtitle_url3];
            setSelectedSubtitleUrl(urls[index]);
        }
        setSubtitlesIndex(index);
        setAnchorElSubtitles(null);
    };

    const handleClickSubtitlesMenu = (event) => {
        setAnchorElSubtitles(event.currentTarget);
    };

    const handleCloseSubtitlesMenu = () => {
        setAnchorElSubtitles(null);
    };

    const handleAudioTrackChange = (index) => {
        setAudioTrackIndex(index);
        setAnchorElAudio(null);
    };

    const handleClickAudioMenu = (event) => {
        setAnchorElAudio(event.currentTarget);
    };

    const handleCloseAudioMenu = () => {
        setAnchorElAudio(null);
    };

    const handleAudioOff = () => {
        setAudioTrackIndex(null); // Mute audio
        setMuted(true);
        if (audioRef.current) {
            audioRef.current.muted = true;
            audioRef.current.src = ''; // Clear the audio source
        }
        setAnchorElAudio(null);
    };

    const handleClickEpisode = (event) => {
        setAnchorElEpisodes(event.currentTarget); // Opens the menu
    };

    const handleCloseEpisode = () => {
        setAnchorElEpisodes(null); // Closes the menu
    };

    // const formatTime = (seconds) => {
    //     const minutes = Math.floor(seconds / 60);
    //     const secs = Math.floor(seconds % 60);
    //     return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    // };
    const formatTime = (seconds) => {
        const hrs = Math.floor(seconds / 3600); // Calculate hours
        const mins = Math.floor((seconds % 3600) / 60); // Calculate minutes
        const secs = Math.floor(seconds % 60); // Calculate seconds
        if (hrs > 0) {
            return `${String(hrs).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
        } else {
            return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
        }
    };


    useEffect(() => {
        const loadSubtitles = async () => {
            if (selectedSubtitleUrl) {
                try {
                    const response = await fetch(selectedSubtitleUrl);
                    const text = await response.text();
                    const subtitlesArray = parseSubtitles(text);
                    setSubtitles(subtitlesArray);
                } catch (error) {
                    console.error('Error loading subtitles:', error);
                    setSubtitles([]);
                }
            }
        };

        if (data.subtitle_on_off) {
            loadSubtitles();
        }
    }, [selectedSubtitleUrl, data.subtitle_on_off]);

    useEffect(() => {
        const subtitle = subtitles.find(sub => currentTime >= sub.startTime && currentTime <= sub.endTime);
        setCurrentSubtitle(subtitle ? subtitle.text : '');
    }, [currentTime, subtitles]);

    // Prepare subtitles menu items
    const subtitleLanguages = [
        data.subtitle_language1,
        data.subtitle_language2,
        data.subtitle_language3
    ].filter(Boolean);

    // Initialize audio tracks and set the first track by default
    useEffect(() => {
        const tracks = [];
        if (data.audio_language1 && data.audio_url1) {
            tracks.push({ label: data.audio_language1, url: data.audio_url1 });
        }
        if (data.audio_language2 && data.audio_url2) {
            tracks.push({ label: data.audio_language2, url: data.audio_url2 });
        }
        if (data.audio_language3 && data.audio_url3) {
            tracks.push({ label: data.audio_language3, url: data.audio_url3 });
        }
        setAudioTracks(tracks);

        // Automatically select the first audio track
        if (tracks.length > 0) {
            setAudioTrackIndex(0);
        }
    }, [data]);

    // Handle audio track change
    useEffect(() => {
        if (audioTrackIndex !== null && audioTracks[audioTrackIndex]) {
            const audioUrl = audioTracks[audioTrackIndex].url;
            const audioElement = audioRef.current;

            if (audioElement) {
                const currentTime = playerRef.current.getCurrentTime();
                audioElement.src = audioUrl;
                audioElement.currentTime = currentTime; // Sync audio to current video time
                setAudioReady(true); // Mark audio as ready to play
            }
        }
    }, [audioTrackIndex, audioTracks]);

    // Initialize video quality options and set the default video quality
    useEffect(() => {
        const qualities = [];
        if (data.video_url) {
            qualities.push({ label: '720p', url: data.video_url });
        }
        if (data.video_url_480) {
            qualities.push({ label: '1080p', url: data.video_url_480 });
        }
        if (data.video_url_720) {
            qualities.push({ label: '2K', url: data.video_url_720 });
        }
        if (data.video_url_1080) {
            qualities.push({ label: '4K', url: data.video_url_1080 });
        }
        setVideoQualities(qualities);

        // Automatically select the first available quality
        if (qualities.length > 0) {
            setVideoQualityIndex(0);
        }
    }, [data]);

    // Handle video quality change
    useEffect(() => {
        if (videoQualityIndex !== null && videoQualities[videoQualityIndex]) {
            const videoUrl = videoQualities[videoQualityIndex].url;
            const currentTime = playerRef.current.getCurrentTime();
            playerRef.current.seekTo(currentTime, 'seconds'); // Sync video to current time

            console.log(videoUrl);
            setSourceUrl(videoUrl); // Set the new video URL
        }
    }, [videoQualityIndex, videoQualities]);
    // Sync video and audio playback



    // Function to handle quality change
    const handleVideoQualityChange = (index) => {
        setVideoQualityIndex(index); // Switch to the selected video quality
        setAnchorElQuality(null);    // Close the menu
    };


    // Mouse Enter Control Showing Work
    const [showControls, setShowControls] = useState(true);
    const [timeoutId, setTimeoutId] = useState(null);
    const handleMouseEnter = () => {
        // setShowControls(true);
        // clearTimeout(timeoutId);
    };
    const handleMouseLeave = () => {
        // const id = setTimeout(() => setShowControls(false), 2000); // Hide after 3 seconds
        // setTimeoutId(id);
    };
    const handleTouchStart = () => {
        // setShowControls(true);
        // clearTimeout(timeoutId);
        // const id = setTimeout(() => setShowControls(false), 3000); // Hide after 3 seconds
        // setTimeoutId(id);
    };
    // Mouse Enter Control Showing Work

    return (

        <div ref={containerRef} className={`player-wrapper ${showControls ? 'show_controls' : ''}`} style={{ position: 'relative', width: '100%', height: '100%' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleTouchStart}>
            <ReactPlayer
                ref={playerRef}
                url={sourceUrl}
                playing={playing}
                volume={volume}
                muted={muted}
                onProgress={handleProgress} // Update progress bar during playback
                onDuration={setDuration}
                controls={false}
                onPlay={handlePlay}  // Start audio when video plays
                onPause={handlePause} // Pause audio when video pauses
                onSeek={(time) => {
                    if (audioRef.current) {
                        audioRef.current.currentTime = time; // Sync audio with seek
                    }
                }}

                onBuffer={handleBuffer}      // Handle video buffering (pause audio)
                onBufferEnd={handleBufferEnd} // Handle end of buffering (resume audio)
                width="100%"
                height="100%"

                onReady={() => {
                    setLoading(false);
                    setPlaying(true);
                    setIsPlayerReady(true); // Mark the player as ready
                }}
                config={{
                    file: {
                        attributes: {
                            controlsList: 'nodownload', // Disable download button
                        },
                    },
                }}
            />

            <audio ref={audioRef} />
            {/* Subtitle Display */}
            <div
                style={{
                    position: 'absolute',
                    bottom: '50px',
                    width: '100%',
                    textAlign: 'center',
                    color: '#fff',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                    fontSize: '20px',
                    padding: '0 20px',
                    boxSizing: 'border-box'
                }}
            >
                {currentSubtitle}
            </div>

            <div className="controls">
                <Slider
                    value={played} // Controlled Slider based on `played` state
                    onChange={handleSeekChange}
                    onMouseDown={handleSeekMouseDown}
                    onMouseUp={handleSeekMouseUp}
                    disabled={loading} // Disable slider during loading
                    aria-labelledby="continuous-seek-slider"
                    style={{ width: '100%' }} // Adjust the width as needed
                    min={0}
                    max={100}
                />

                <div className="controls__lr">
                    <div className="left__controls">
                        <IconButton onClick={loading ? null : handlePlayPause} className='play_pause_btn'>
                            {hasPlayed ? <Pause /> : <PlayArrow />}

                        </IconButton>

                        <IconButton onClick={handleBackward} className='back_ward_btn' >
                            <Replay10 />
                        </IconButton>

                        <IconButton onClick={handleForward} className='for_ward_btn'>
                            <Forward10 />
                        </IconButton>
                        
                        <IconButton onClick={toggleMute}>
                            {muted ? <VolumeOff /> : <VolumeUp />}
                        </IconButton>

                        <Slider
                            value={volume * 100} // Convert 0-1 volume to 0-100 slider value
                            onChange={handleVolumeChange}
                            aria-labelledby="continuous-slider"
                            style={{ width: '100px' }}
                        />
                    </div>

                    <div className="right__actions">
                       
                        <div className="wrap_up">
                            <div className="time-info">
                                <span>{formatTime(currentTime)}</span> / <span>{formatTime(duration)}</span>
                            </div>

                            <IconButton
                                aria-controls="speed-menu"
                                aria-haspopup="true"
                                onClick={handleClickSpeedMenu}
                            >
                                <Speed />
                            </IconButton>
                            <Menu
                                anchorOrigin={{
                                    vertical: 'top', // Aligns the menu at the top of the button
                                    horizontal: 'center', // Centered horizontally
                                }}
                                transformOrigin={{
                                    vertical: 'bottom', // Aligns the bottom of the menu with the top of the button
                                    horizontal: 'center', // Centered horizontally
                                }}
                                id="speed-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleCloseSpeedMenu}

                            >
                                {[0.25, 0.5, 1, 1.5, 1.75, 2].map((rate) => (
                                    <MenuItem key={rate} onClick={() => handleSpeedChange(rate)}>
                                        {rate}x
                                    </MenuItem>
                                ))}
                            </Menu>

                            <IconButton aria-controls="quality-menu" aria-haspopup="true" onClick={(e) => setAnchorElQuality(e.currentTarget)}>
                                <HighQualityIcon />
                            </IconButton>
                            <Menu
                                anchorOrigin={{
                                    vertical: 'top', // Aligns the menu at the top of the button
                                    horizontal: 'center', // Centered horizontally
                                }}
                                transformOrigin={{
                                    vertical: 'bottom', // Aligns the bottom of the menu with the top of the button
                                    horizontal: 'center', // Centered horizontally
                                }}
                                id="quality-menu"
                                anchorEl={anchorElQuality}
                                keepMounted
                                open={Boolean(anchorElQuality)}
                                onClose={() => setAnchorElQuality(null)}
                            >
                                {videoQualities.map((quality, index) => (
                                    <MenuItem key={index} onClick={() => handleVideoQualityChange(index)}>
                                        {quality.label}
                                    </MenuItem>
                                ))}
                            </Menu>

                            <IconButton aria-controls="audio-menu" aria-haspopup="true" onClick={(e) => setAnchorElAudio(e.currentTarget)}>
                                <HeadphonesIcon />
                            </IconButton>
                            <Menu
                                anchorOrigin={{
                                    vertical: 'top', // Aligns the menu at the top of the button
                                    horizontal: 'center', // Centered horizontally
                                }}
                                transformOrigin={{
                                    vertical: 'bottom', // Aligns the bottom of the menu with the top of the button
                                    horizontal: 'center', // Centered horizontally
                                }}
                                id="audio-menu"
                                anchorEl={anchorElAudio}
                                keepMounted
                                open={Boolean(anchorElAudio)}
                                onClose={handleCloseAudioMenu}
                            >
                                {audioTracks.map((track, index) => (
                                    <MenuItem key={index} onClick={() => handleAudioTrackChange(index)}>{track.label}</MenuItem>
                                ))}
                            </Menu>


                            <IconButton aria-controls="subtitles-menu" aria-haspopup="true" onClick={(e) => setAnchorElSubtitles(e.currentTarget)}>
                                <Subtitles />
                            </IconButton>
                            <Menu
                                anchorOrigin={{
                                    vertical: 'top', // Aligns the menu at the top of the button
                                    horizontal: 'center', // Centered horizontally
                                }}
                                transformOrigin={{
                                    vertical: 'bottom', // Aligns the bottom of the menu with the top of the button
                                    horizontal: 'center', // Centered horizontally
                                }}
                                id="subtitles-menu" anchorEl={anchorElSubtitles} keepMounted open={Boolean(anchorElSubtitles)} onClose={() => setAnchorElSubtitles(null)}>
                                <MenuItem onClick={() => handleSubtitlesChange(null)}>Off</MenuItem>
                                {subtitleLanguages.map((lang, index) => (
                                    <MenuItem key={index} onClick={() => handleSubtitlesChange(index)}>{lang}</MenuItem>
                                ))}
                            </Menu>

                            {/* Icon button to open the episodes menu */}

                            {/* Episode Menu */}
                            {EpisodeBySeasons.length === 0 ? (
                                null
                            ) : (
                                <>
                                    <IconButton
                                        aria-controls="episodes-menu"
                                        aria-haspopup="true"
                                        onClick={handleClickEpisode} // Opens the menu when clicked
                                    >
                                        <MovieIcon /> {/* Icon for the episodes button */}
                                    </IconButton>
                                    <div className='All_episodes_in_player'>
                                        <Menu
                                            anchorOrigin={{
                                                vertical: 'top', // Aligns the menu at the top of the button
                                                horizontal: 'center', // Centered horizontally
                                            }}
                                            transformOrigin={{
                                                vertical: 'bottom', // Aligns the bottom of the menu with the top of the button
                                                horizontal: 'center', // Centered horizontally
                                            }}
                                            id="episodes-menu"
                                            anchorEl={anchorElEpisodes}
                                            keepMounted
                                            open={Boolean(anchorElEpisodes)}
                                            onClose={handleCloseEpisode} // Closes the menu when clicked outside
                                        >
                                            {EpisodeBySeasons.length === 0 ? (
                                                null
                                                // <MenuItem disabled>No Episodes Available</MenuItem>
                                            ) : (
                                                EpisodeBySeasons.map((episode, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        component={Link} // Link to the episode
                                                        to={`/watch-episode/${episode.episode_id}?season_id=${seasonId}`} // Modify this according to your routing structure
                                                        onClick={handleCloseEpisode} // Closes the menu when an episode is clicked
                                                    >
                                                        <PlayCircleOutlineIcon style={{ marginRight: '10px' }} /> {/* Icon next to episode */}
                                                        {episode.episode_title}
                                                    </MenuItem>
                                                ))
                                            )}
                                        </Menu>
                                    </div>

                                </>
                            )}

                            <IconButton onClick={handlePiP}>
                                <PictureInPictureAlt />
                            </IconButton>

                            <IconButton onClick={handleFullscreenToggle}>
                                <Fullscreen />
                            </IconButton>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
};

// Helper function to parse time format into seconds
const parseTime = (time) => {
    const [hours, minutes, seconds] = time.split(':');
    const [sec, ms] = seconds.split('.');
    return parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseFloat(`${sec}.${ms}`);
};

export default Movieplayer;