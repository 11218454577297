import { useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from '../../config';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import hd from '../../Images/flutter/hd.png'; // Ensure this image is required
import i9 from '../../Images/flutter/NewFlix Images/i9.png';
import screen from '../../Images/flutter/screen.png';

const FlutterOnlineScreenPurchase = () => {
    const { id } = useParams();
    const location = useLocation();
    const [listMoviesShowsGenre, setListMoviesShowsGenre] = useState([]); // Default empty array
    const navigate = useNavigate();

    // Apply or remove class based on the route
    useEffect(() => {
        if (location.pathname === `/flutter-purchase-online-screen`) {
            document.body.classList.add('no-black-background');
        } else {
            document.body.classList.remove('no-black-background');
        }
        return () => {
            // Clean up the class when the component unmounts
            document.body.classList.remove('no-black-background');
        };
    }, [location.pathname]);

    // Fetch featured movies and shows
    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/flutter_genre_all_movie_serials`, {
            genre_id: id
        })
            .then(response => {
                const genreData = response.data?.VIDEO_STREAMING_APP?.genre_movie_show[0];
                if (genreData) {
                    // Extract movies and shows from the genreData object
                    const movies = genreData[Object.keys(genreData)[1]] || []; // Access the second key (Drama)
                    setListMoviesShowsGenre(movies);
                    console.log(movies);
                } else {
                    setListMoviesShowsGenre([]); // Reset if no genre data found
                }
            })
            .catch(err => {
                console.error('Error fetching featured movies/shows:', err);
                setListMoviesShowsGenre([]); // Reset to empty on error
            });
    }, [id]); // Include `id` in the dependency array

    const handleFlutterMovieShows = (movieShow) => {
        const { movie_id, show_id } = movieShow;
        if (movie_id) {
            navigate(`/flutter-movie-detail-screen/${movie_id}`); // Adjust this path to your movie page route
        } else if (show_id) {
            navigate(`/flutter-show-detail-screen/${show_id}`); // Adjust this path to your show page route
        }
    };

        // State to toggle the dropdown
        const [isOpen, setIsOpen] = useState(false);

        // Toggle dropdown visibility on click
        const handleToggle = () => {
            setIsOpen(!isOpen);
        };
    return (
        <>
            <section className="flutter_purchase_cinema">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="flutter_cinema_main">
                                <div className="flutter_cinema_image_content">
                                    <div className="flutter_cinema_img">
                                        <img src={i9} alt="" />
                                    </div>
                                    <div className="flutter_content_cinema">
                                        <p>
                                            You have 8 hours to watch from home from the time of ticket purchase, and after that, the viewing time will end. Adjust your time so that you don't miss watching the entire movie in your session.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flutter_note_main">
                        <div className="row">
                            <div className="col-12">
                                <div className="note_cinema_flutter">
                                    <p className="m-0">
                                        Please note that due to copyright protection, movies currently showing in cinemas are not available for download online.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flutter_cinema_information_main">
                        <div className="row">
                            <div className="col-12">
                                <div className="flutter_cinema_info">
                                    <p>80,000 Tomans (Pakistani Rupees)
                                    </p>
                                    <p>Ticket Price</p>
                                </div>
                                <div className="flutter_cinema_info">
                                    <p>80,000 Tomans (Pakistani Rupees)
                                    </p>
                                    <p>tax
                                    </p>
                                </div>
                                <div className="flutter_cinema_info">
                                    <p>
                                        8 hours
                                    </p>
                                    <p>
                                        opportunity to watch
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flutter_copun_code_cinema_main">
                        <div className="row">
                            <div className="col-12">
                                <div className="flutter_copun_code_cinema">
                                    <div className="input_cinema_main" onClick={handleToggle}>
                                        <div className="input_filed_cinema">
                                            <p>Do you have a discount code?
                                            <i className={`fa-solid fa-angle-down ${isOpen ? 'rotate-icon' : ''}`}></i>
                                            </p>
                                            {/* inPUT fILED OPEN */}
                                            <div className={`input_open_cinema_flutter ${isOpen ? 'open_cinema_input' : ''}`}>
                                                <input type="text" placeholder="Enter your ticket code" className="inputFiled_cinema"/>
                                                <input type="submit" className="inputFiled_cinema_btn" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  */}
                    <div className="buttons_cinema">
                        <Link to="#">
                             Buy ticket and watch
                             <img src={screen} alt="" />
                        </Link>
                    </div>

                </div>
            </section>
        </>
    );
};

export default FlutterOnlineScreenPurchase;
