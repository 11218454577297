import { useLocation, useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Theme_Variable, API_BASE_URL } from '../../../config';

// Import Swiper and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import axios from "axios";

import bottom_logo from '../../../Images/flutter/NewFlix Images/Icons/mai-logo-icon.png';
import video_streaming from '../../../Images/flutter/NewFlix Images/Icons/video-streaming-icon-offwhite.png';
import category_bottom from '../../../Images/flutter/NewFlix Images/Icons/cate-icon.png';
import live_tv from '../../../Images/flutter/livetv.png';
import home_yellow from '../../../Images/flutter/home_yello (1).png';
import category_dark from '../../../Images/flutter/category_dark.png';
import my_films from '../../../Images/flutter/my_films_yello.png';
import live_tv_yellow from '../../../Images/flutter/live-png-yellow.png';
import { useTranslation } from "react-i18next";

const FlutterBottomBar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const isActive = (path) => location.pathname == path;

    return (
        <section className={`bottom_bar__flutter ${Theme_Variable === 'Dark' ? 'Bottom_bar_dark' : ''}`}>
            <div className="container-fluid">
                <div className="col-12">
                    <div className="bottom_bar-main">
                        <ul className="list-unstyled m-0 p-0">
                            <li>
                                <Link to="/flutter-home-screen">
                                    <div className={`bottom__bar__icon logo__main_newflix ${isActive('/flutter-home-screen') ? 'active' : ''}`}>
                                        <img src={`${Theme_Variable == 'Dark' ? home_yellow : bottom_logo}`} alt="" />
                                    </div>
                                    <div className={`link_name ${isActive('/flutter-home-screen') ? 'active_bottom_text' : ''} ${Theme_Variable == 'Dark' ? 'text_bottom_nav' : ''} `}>
                                        {t("home")}
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/flutter-categories">
                                    <div className={`bottom__bar__icon logo__category ${isActive('/flutter-categories') ? 'active' : ''}`}>
                                        <img src={`${Theme_Variable == 'Dark' ? category_dark : category_bottom}`} alt="" />
                                    </div>
                                    <div className={`link_name ${isActive('/flutter-categories') ? 'active_bottom_text' : ''} ${Theme_Variable == 'Dark' ? 'text_bottom_nav' : ''}`}>
                                        {t("category")}
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/flutter-livetv-all">
                                    <div className={`bottom__bar__icon logo_video_category ${isActive('/flutter-livetv-all') ? 'active' : ''}`}>
                                        <img src={`${Theme_Variable == 'Dark' ? live_tv_yellow : live_tv}`} alt="" />
                                    </div>
                                    <div className={`link_name ${isActive('/flutter-livetv-all') ? 'active_bottom_text' : ''} ${Theme_Variable == 'Dark' ? 'text_bottom_nav' : ''}`}>
                                    {t("live tv")}
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/flutter-offline-movies">
                                    <div className={`bottom__bar__icon logo_video_category ${isActive('/flutter-offline-movies') ? 'active' : ''}`}>
                                        <img src={`${Theme_Variable == 'Dark' ? my_films : video_streaming}`} alt="" />
                                    </div>
                                    <div className={`link_name ${isActive('/flutter-offline-movies') ? 'active_bottom_text' : ''} ${Theme_Variable == 'Dark' ? 'text_bottom_nav' : ''}`}>
                                        {t("my movies")}
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FlutterBottomBar;
