import { React } from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { API_BASE_URL } from '../config';

const Allmovies = ({ genreId, genreName, moviesByGenre }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const subUserSelectedAge = parseInt(localStorage.getItem('sub_account_age'));
    const [moviesBygenre, setMovieBygenre] = useState([]);

    // Helper function to get URL parameters (filters)
    const getFiltersFromURL = () => {
        const params = new URLSearchParams(location.search);
        const filters = {
            genre: params.get('genre_id'),
            age: params.get('age'),
            language: params.get('language_id'),
            country: params.get('country_id'),
        };
        return filters;
    };
    // Function to check if any filter is applied (age, language, country)
    const isFilterApplied = () => {
        const filters = getFiltersFromURL();
        return filters.age || filters.language || filters.country || filters.genre;
    };
    useEffect(() => {
        const filters = getFiltersFromURL();
        // console.log(filters.country)
        if (isFilterApplied()) {
            const payload = {};
            if (filters.genre) payload.genre = parseInt(filters.genre);
            if (filters.age) payload.age = filters.age;
            if (filters.language) payload.language = filters.language;
            if (filters.country) payload.country = filters.country;

            // Fetch from /movies API when filters are applied
            axios.post(`${API_BASE_URL}api/v1/movies`, payload)
                .then((response) => {
                    setLoading(false);
                    setMovieBygenre(response.data.VIDEO_STREAMING_APP.movies.slice(0, 8));
                })
                .catch((error) => {
                    setMovieBygenre([]);
                    console.error('Error fetching filtered movies:', error);
                });
        } else {
            axios.post(`${API_BASE_URL}api/v1/movies_by_genre`, {
                genre_id: genreId,
                filter: "new"
            })
                .then((response) => {
                    let childMode = parseInt(localStorage.getItem('child_mode'));
                    console.log(response.data.VIDEO_STREAMING_APP);
                    setLoading(false);
                    if (childMode === 1 && subUserSelectedAge) {
                        let filteredMovies = response.data.VIDEO_STREAMING_APP.filter(movie => {
                            if (movie.content_rating === '') {
                                return false;
                            }
                            return parseInt(movie.content_rating) <= subUserSelectedAge;
                        });
                        setMovieBygenre(filteredMovies);
                    } else {
                        const latestSixMovies = response.data.VIDEO_STREAMING_APP.slice(0, 8);
                        setMovieBygenre(latestSixMovies);
                    }
                })
                .catch((error) => {
                    setMovieBygenre([]);
                    console.error('Error fetching movies:', error);
                });
        }
    }, [location, genreId]);
    return (
        <>
            <div className="all__movies-main">
                <div className="videos__container">
                    {moviesBygenre && moviesBygenre.length > 0 ? (
                        moviesBygenre.map((movie, index) => (
                            <div className="videos_item" key={index}>
                                <div className="movie" onClick={() => navigate(`/movie-page/${movie.movie_id}`)} >
                                    <div className="movie_img">
                                        <img src={movie.movie_poster} alt={movie.movie_title} loading="lazy" />
                                        <div className="overlay__movie">
                                            <div className="movie__info">
                                                {/* <p className='duration'>Duration: {movie.movie_duration}</p>
                                                <p className='rating duration d-block text-center'>{movie.movie_access}</p>
                                                <p className='categ_related'>{genreName}</p> */}
                                                <p>
                                                    {
                                                        movie.genre_list && movie.genre_list.length > 0
                                                            ? movie.genre_list.slice(0, 2).map(genres => genres.genre_name).join(' - ')
                                                            : 'No genres available'
                                                    }
                                                </p>
                                                <p className="movie_sun">{movie.country_name} - {movie.release_date}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="movie__title">
                                        <p>
                                            {movie.movie_title.split(' ').length > 2
                                                ? movie.movie_title.split(' ').slice(0, 2).join(' ') + '...'
                                                : movie.movie_title}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p style={{ color: "white", fontFamily: 'i-med' }}>No movies available in this Category.</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default Allmovies;
