import { useState, useEffect } from "react";
import { API_BASE_URL, Theme_Variable } from '../../config';
import movie_1 from '../../Images/movie_1.webp';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import hd from '../../Images/flutter/hd.png';
import not_found from '../../Images/flutter/not_found.jpg';
import not_found_dark from '../../Images/flutter/err_2.png';
import FlutterBackHeader from "./FlutterBackHeader";
import FlutterNavTabs from "./flutter_component/FlutterNavTabs";
import FlutterHomeScreenNav from "./flutter_component/FlutterHomeScreenNav";
import FlutterMainNavTwo from "./flutter_component/FlutterMainNavTwo";
import FlutterBottomBar from "./flutter_component/FlutterBottomBar";
import download_dark from '../../Images/flutter/download_dark.png';
import download_light from '../../Images/flutter/download_light.png';
import { useTranslation } from "react-i18next";

const FlutterViewedPage = () => {
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);
    const {t} = useTranslation();
    const [pagesSpinner, setPagesSpinner] = useState(false);
    const [UserViewed, setUserViewed] = useState({
        movies: [],
        episodes: [],
        livetv: []
    });

    const UserId = localStorage.getItem('user_id') || ''; // Check if user is logged in

    const toggleActiveClass = () => {
        setIsActive(!isActive);
    };

    useEffect(() => {
        // Apply theme-specific background classes based on the current route
        if (location.pathname == '/flutter-viewed-page') {
            document.body.classList.toggle('no-black-background');
            document.body.classList.toggle('black-background', Theme_Variable == 'Dark');
        } else {
            document.body.classList.remove('no-black-background', 'black-background');
        }
    }, [location.pathname]);

    useEffect(() => {
        // Fetch recently watched data from API
        setPagesSpinner(true);
        axios.post(`${API_BASE_URL}api/v1/recently_watched`, { user_id: UserId })
            .then(response => {
                const { VIDEO_STREAMING_APP } = response.data;
                setUserViewed({
                    movies: VIDEO_STREAMING_APP.movies || [],
                    episodes: VIDEO_STREAMING_APP.episodes || [],
                    livetv: VIDEO_STREAMING_APP.livetv || []
                });
            })
            .catch(err => {
                console.error("Error on search: ", err);
            })
            .finally(() => {
                setPagesSpinner(false);
            });
    }, [UserId]);

    return (
        <>
            <FlutterMainNavTwo />

            <FlutterNavTabs />

            {!UserId ? (
                <section className={`fluttered_user_detailed_section`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="detailed_user_main">
                                    <div className="detailed_image_user">
                                        <img src={Theme_Variable == 'Dark' ? not_found_dark  : not_found} alt="Not Found" />
                                    </div>
                                    <div className="detailed_title">
                                        <h2 className={Theme_Variable == 'Dark' ? 'white_text_mode' : ''}>{t("You haven't watched any movies yet!")}</h2>
                                        <p className={Theme_Variable == 'Dark' ? 'white_text_mode' : ''}>{t("To access the Newflix Viewed List, please log in:")}</p>
                                        <Link to="/flutter-login">{t("Login Or SignUp")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                (UserViewed.movies.length > 0 || UserViewed.episodes.length > 0 || UserViewed.livetv.length > 0) ? (
                    <section className={`featured_fetch_flutter ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                        {UserViewed.movies.length > 0 && (
                            <>
                                <div className="row__viewed__heading">
                                    <h2 className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{t("Movies")}</h2>
                                </div>
                                <div className="featured_row">
                                    {UserViewed.movies.map((movie, index) => (
                                        <div className="featured_main" key={index}>
                                            <img src={`${API_BASE_URL}${movie.video_image_thumb}`} alt="" />
                                            <div className="hd_icon">
                                                <img src={hd} alt="HD Icon" />
                                            </div>
                                            <div className="featured_title_flutter">
                                                <p className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{movie.video_title}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {UserViewed.episodes.length > 0 && (
                            <>
                                <div className="row__viewed__heading">
                                    <h2 className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{t("Episodes")}</h2>
                                </div>
                                <div className="featured_row">
                                    {UserViewed.episodes.map((episode, index) => (
                                        <div className="featured_main" key={index}>
                                            <img src={`${API_BASE_URL}${episode.video_image}`} alt={episode.video_title} />
                                            <div className="hd_icon">
                                                <img src={hd} alt="HD Icon" />
                                            </div>
                                            <div className="featured_title_flutter">
                                                <p className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{episode.video_title}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {UserViewed.livetv.length > 0 && (
                            <>
                                <div className="row__viewed__heading">
                                    <h2 className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                        {t("live tv")}
                                    </h2>
                                </div>
                                <div className="featured_row">
                                    {UserViewed.livetv.map((livetv, index) => (
                                        <div className="featured_main" key={index}>
                                            <img src={`${API_BASE_URL}${livetv.channel_thumb}`} alt={livetv.channel_name} />
                                            <div className="hd_icon">
                                                <img src={hd} alt="HD Icon" />
                                            </div>
                                            <div className="featured_title_flutter">
                                                <p className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{livetv.channel_name}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </section>
                ) : (
                    <section className={`fluttered_user_detailed_section`}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="detailed_user_main">
                                        <div className="detailed_image_user">
                                            <img src={Theme_Variable == 'Dark' ? not_found_dark : not_found} alt="Not Found" />
                                        </div>
                                        <div className="detailed_title">
                                            <p className={Theme_Variable == 'Dark' ? 'white_text_mode' : ''}>
                                                {t("You haven't watched any movies, episodes, or live TV.")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            )}

            {pagesSpinner && (
                <div className="flutter_section_spinner">
                    <div className="flutter_spinned_sections"></div>
                </div>
            )}
            {/* <FlutterBottomBar /> */}
        </>
    );
};

export default FlutterViewedPage;
