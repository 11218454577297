import '../Components/css/style.css';
import { React, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import Allmovies from '../Components/Allmovies';
import { API_BASE_URL } from '../config';

const Childrens = () =>{
    const prams_id = useParams();
    const [moviesBygenre, setMovieBygenre] = useState([]);
    const [genreList, genreListformovies] = useState([]);
    const [genre_id, genreListId] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        // Movies By Genre 
        axios.post(`${API_BASE_URL}api/v1/movies_by_genre`, {
            genre_id: prams_id.id,
            filter: "new"
        }).then((response) => {
            setLoading(false);
            // console.log(response.data.VIDEO_STREAMING_APP);
            const latestSixMovies = response.data.VIDEO_STREAMING_APP.slice(0, 6);
            setMovieBygenre(latestSixMovies);
        }).catch((error) => {
            setMovieBygenre([]);
            console.error('Error fetching movies:', error);
        });

        // Genre List
        axios.post(`${API_BASE_URL}api/v1/genres`, {}).then((response) => {
            let p = response.data.VIDEO_STREAMING_APP;
            let genre_name = null;
            let genre_id = null;
            for (let x = 0; x < p.length; x++) {
                if (p[x].genre_id == prams_id.id) {
                    genre_name = p[x].genre_name;
                    genre_id = p[x].genre_id;
                }
            }
            genreListId(genre_id)
            genreListformovies(genre_name)
        }).catch(error => {
            console.error('Error fetching genres:', error);
        });
    }, [prams_id.id]);

    return (
        <section className="movies__main-section  videos-inner-wrapper">
            <div className="container-fluid">
            <div className="inner_video_wrapper">
                    {/* <div className="row">
                        <div className="col-md-12">
                            <div className="Movies__main-title text-right" data-aos="fade-up" data-aos-duration="2000">
                                <h2>{genreList}</h2>
                            </div>
                        </div>
                    </div> */}
                    <div className="movies__main">
                        <div className="row justify-content-between">
                            <div className="col-6 col-sm-6 col-md-6">
                                <div className="movies__view_all text-left" data-aos="fade-up" data-aos-duration="2000">
                                    <Link to={`/view-all/${genre_id}`}>view all <i class="fa-solid fa-angle-left"></i></Link>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6">
                                <div className="movies__subtitle text-right" data-aos="fade-up" data-aos-duration="2000">
                                    <h2>{genreList}</h2>
                                </div>
                            </div>
                        </div>
                        <Allmovies key={genre_id + 1} genreId={genre_id} genreName={genreList} moviesByGenre={moviesBygenre} />
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            {loading &&
                                <div class="spinner-border spinner_color mt-2 text-center" role="status"></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}   
export default Childrens;