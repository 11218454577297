import { useState, useEffect } from "react";
import { API_BASE_URL } from '../../config';
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import detail_head from '../../Images/flutter/detail_pg.png';
import arrow_right from '../../Images/flutter/arrow_right.png';
import hd from '../../Images/flutter/hd.png';
import axios from "axios";
import FlutterPlayer from "../../Components/FlutterPlayer";
import pencil_icon from "../../Images/flutter/pencilIcon.png";
import small_arrow from "../../Images/flutter/small_arrow.png";
import icon from "../../Images/flutter/icon.png";
import FlutterBackHeader from "./FlutterBackHeader";
import { useTranslation } from "react-i18next";

const FlutterMultiProfiles = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const UserId = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
    const [subAccountList, setAccountList] = useState([]);
    const [pages_spinner, setpagesSpinnerFlutter] = useState(true);
    const [subAccountId, setSubAccountId] = useState(localStorage.getItem("sub_account_id") || '');
    const {t} = useTranslation();
    // Handle season selection
    useEffect(() => {
        setpagesSpinnerFlutter(true);
        // setLoading(true);
        axios.post(`${API_BASE_URL}api/v1/sub_account_list`, {
            user_id: UserId,
        }).then(response => {
            // setLoading(false);
            setpagesSpinnerFlutter(false);
            setAccountList(response.data.VIDEO_STREAMING_APP);
        }).catch(err => {
            console.log(err);
        });
    }, [UserId]);

    const profileData = (subAccountList) => {
        setSubAccountId(subAccountList.id);
        localStorage.setItem("sub_account_id", subAccountList.id);
        localStorage.setItem("sub_account_name", subAccountList.name);
        localStorage.setItem("sub_account_img", subAccountList.user_image);
        localStorage.setItem("sub_account_age", subAccountList.age);
        localStorage.setItem("child_mode", 1);
        window.location.href = '/flutter-home-screen';
        // reloadMultiProfile();
    };

    const handleEditSubProfile = (subAccountList) => {
        navigate(`/flutter-edit-profile/${subAccountList.id}`)
    }

    return (
        <>
            {/* Season Header */}
            <FlutterBackHeader/>
            <section class="multi__profiles_flutter">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="head_multi_profile_flutter">
                                <h2>{t("Who is watching?")}</h2>
                            </div>
                            {/*  Multi Profiles*/}
                            <div className="multi__profiles_main_flutter">
                                {
                                    subAccountList.map((subAccountList, index) => (
                                        <div className="profile__main__flutter" key={index}
                                        >
                                            <div
                                                className="main__profile__flutter"
                                                onClick={() => {
                                                    if (subAccountList.msg !== 'Main Account') {
                                                        profileData(subAccountList);
                                                    } else {
                                                        if (localStorage.getItem('child_mode') == 1) {
                                                            window.location.href = '/flutter-child-mode-verification';
                                                        }
                                                    }
                                                }
                                            }
                                            >
                                                <div className="profile__content__flutter">
                                                    <div className="profile__icon__flutter">
                                                        {/* <img src={subAccountList.user_image != null ? subAccountList.user_image : icon} alt="" /> */}
                                                        <img src={icon} alt="" />
                                                    </div>
                                                    <div className="profile__name__flutter">
                                                        <p>{subAccountList.name || 'NA/NA'}</p>
                                                        {
                                                            subAccountList.msg != 'Main Account' && (
                                                                <span>{t("Age")} - {subAccountList.age}</span>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                                <div className="small__icon__flutter">
                                                    <img src={small_arrow} alt="" />
                                                </div>
                                            </div>
                                            <div className="edit__profile__flutter" onClick={() => handleEditSubProfile(subAccountList)}>
                                                <div className="edit__profile__icon">
                                                    <img src={pencil_icon} alt="" />
                                                </div>
                                            </div>
                                        </div>

                                    ))
                                }

                                <div className="profile__main__flutter" onClick={
                                    () => navigate('/flutter-add-profile')
                                }>
                                    <div className="main__profile__flutter">
                                        <div className="profile__content__flutter">
                                            <div className="profile__icon__flutter">
                                                <img src={icon} alt="" />
                                            </div>
                                            <div className="profile__name__flutter">
                                                <p>{t("Create New Member")}</p>
                                            </div>
                                        </div>
                                        <div className="small__icon__flutter">
                                            <img src={small_arrow} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {pages_spinner && (
                    <div className="flutter_detail_page_spinner">
                        <div className="flutter_section_spinner">
                            <div class="flutter_spinned_sections"></div>
                        </div>
                    </div>
                )}
            </section>
        </>
    );
}

export default FlutterMultiProfiles;
