import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import imbd from '../Images/imdb.png';
import caster1 from '../Images/home_images/profile.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import lock from '../Images/home_images/lock.webp';
import { Navigation, Pagination } from 'swiper/modules';
import Movieplayer from "../Components/Movieplayer";
import { API_BASE_URL } from '../config';
import FlutterPlayer from "../Components/FlutterPlayer";
import { Padding } from "@mui/icons-material";

const FlutterPlayerPage = () => {
    let [userplan, setUserplan] = useState('');
    const { id } = useParams();;
    let UserId = localStorage.getItem('user_id') ||0;
    const [suggestionMovies, setMovieSuggestion] = useState([]);
    const [movie_detail, setMovieDetail] = useState([]);
    const [checkplansuscription, setcheckplansuscription] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const subUserSelectedAge = parseInt(localStorage.getItem('sub_account_age'));

    useEffect(() => {
        setLoading(true);
        axios.post(`${API_BASE_URL}api/v1/movies_details`, {
            'user_id':UserId,
            'movie_id': id
        })
            .then(response => {
                setLoading(false);
                setMovieDetail(response.data.VIDEO_STREAMING_APP)
                // console.log(response.data.VIDEO_STREAMING_APP);
            })
            .catch(error => {
                console.log('There was an error making the request:', error);
            });

        axios.post(`${API_BASE_URL}api/v1/check_user_plan`, {
            user_id: UserId
        })
            .then(response => {
                let checkPlan = response.data.VIDEO_STREAMING_APP;
                setcheckplansuscription(checkPlan.msg);
                setUserplan(response.data.VIDEO_STREAMING_APP);
            }).catch(err => {
                console.log(err);
            })
        // Movies
        axios.post(`${API_BASE_URL}api/v1/movies`, {
            filter: subUserSelectedAge ? 'new' : 'rand',
        })
            .then(response => {

                if (subUserSelectedAge) {
                    let filteredMovies = response.data.VIDEO_STREAMING_APP.movies.filter(movie => {
                        if (movie.content_rating === '') {
                            return false;
                        }
                        return parseInt(movie.content_rating) <= subUserSelectedAge;
                    });
                    let random_movies = filteredMovies.slice(0, 8);
                    console.log(random_movies)
                    setMovieSuggestion(filteredMovies);
                } else {
                    let random_movies = response.data.VIDEO_STREAMING_APP.movies.slice(0, 8);
                    setMovieSuggestion(random_movies);
                }
            })
            .catch(err => {
                console.log('There was an error making the request:', err);
            });

    }, [UserId]);

    // console.log(checkplansuscription);
    const [checkUserCinemaHas, SetcheckUserCinemaHas] = useState(0);

    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/shows_user_cinema_list`, { user_id: UserId || '' })
            .then(response => {
                let movieExists = false; // Initialize as false

                response.data.VIDEO_STREAMING_APP.movies.forEach(movie => {
                    if (movie.movie_id == movie_detail.movie_id) {
                        SetcheckUserCinemaHas(1); // Movie exists, set to 1
                    }
                });
            })
            .catch(err => {
                console.log("something went wrong:" + err);
            });
    }, [UserId, movie_detail.movie_id]);

    useEffect(() => {
        // JavaScript to add Font Awesome icons
        const prevButtons = document.querySelectorAll('.swiper-button-prev');
        const nextButtons = document.querySelectorAll('.swiper-button-next');
        prevButtons.forEach(button => {
            button.innerHTML = '<i class="fas fa-chevron-left"></i>'; // Font Awesome left chevron
        });
        nextButtons.forEach(button => {
            button.innerHTML = '<i class="fas fa-chevron-right"></i>'; // Font Awesome right chevron
        });
    }, []);
    return (
        <>
            <section className="watch__movie videos-inner-wrapper p-0">
                <div className="container-fluid p-0">
                    <div className="p-0" style={{padding:0}}>
                        <div className="row">
                            <div className="col-md-12 p-0">
                                {
                                    (checkplansuscription === 'My Subscription' || movie_detail.movie_access === 'Free' || checkUserCinemaHas == 1) ? (
                                        <div className="video__screen video_screen_on_mobile">
                                            <FlutterPlayer data={movie_detail} sourceUrl={movie_detail.video_url} poster={movie_detail.movie_image} />
                                        </div>
                                    ) : (
                                        <>
                                            {checkplansuscription === 'Renew subscription plan' && movie_detail.movie_access === 'Paid' && (
                                               ()=>navigate('/flutter-subscription')
                                               // <div className="lock_premium">
                                                //     <div className="locker_img">
                                                //         <img src={lock} alt="Locked Content" loading="lazy" />
                                                //     </div>
                                                    
                                                //     <div className="lock__title">
                                                //         <Link to="/flutter-subscription">Renew your Subscription</Link>
                                                //     </div>
                                                // </div>
                                            )}

                                            {checkplansuscription === 'Please select subscription plan' && movie_detail.movie_access === 'Paid' && (
                                               ()=>navigate('/flutter-subscription')
                                                
                                                // <div className="lock_premium">
                                                //     <div className="locker_img">
                                                //         <img src={lock} alt="Locked Content" loading="lazy" />
                                                //     </div>
                                                //     <div className="lock__title">
                                                //         <Link to="/flutter-subscription">Please buy a subscription</Link>
                                                //     </div>
                                                // </div>
                                            )}
                                        </>
                                    )
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                loading &&
                <div className="full-screen_loader">
                    <div class="spinner-border spinner_color mt-2 text-center" role="status"></div>
                </div>
            }
        </>
    );
};

export default FlutterPlayerPage;
