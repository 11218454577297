import { useState, useEffect } from "react";
import { Theme_Variable } from '../../config';
import { API_BASE_URL } from '../../config';
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import hd from '../../Images/flutter/hd.png'; // Ensure this image is required

// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import FlutterBackHeader from "./FlutterBackHeader";

const FlutterFreeMoviesSerials = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [featuredMoviesShows, setFeaturedMoviesShows] = useState([]); // Default empty array
    const [pages_spinner, setpagesSpinnerFlutter] = useState(true);
    let childMode = parseInt(localStorage.getItem('child_mode'));
    const subUserSelectedAge = parseInt(localStorage.getItem('sub_account_age'));
    // Apply or remove class based on the route
    useEffect(() => {

        if (location.pathname == '/flutter-free-movies-serials' && Theme_Variable == 'Light') {
            document.body.classList.add('no-black-background');
        } else if (location.pathname == '/flutter-free-movies-serials' && Theme_Variable == 'Dark') {
            document.body.classList.add('black-background');
        } else {
            document.body.classList.remove('no-black-background');
        }


    }, [location.pathname]);
    // Fetch featured movies and shows
    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/flutter_free_movies_serials`, {})
            .then(response => {
                if (childMode === 1 && subUserSelectedAge) {
                    let FilteredFreeMoviesShows = response.data.VIDEO_STREAMING_APP.free.filter(freeMoviesShows => {
                        if (freeMoviesShows.content_rating === '') {
                            return false;
                        }
                        return parseInt(freeMoviesShows.content_rating) <= subUserSelectedAge;
                    });
                    setFeaturedMoviesShows(FilteredFreeMoviesShows);
                } else {
                    const featuredMoviesShowsData = response.data?.VIDEO_STREAMING_APP?.free || [];
                    setFeaturedMoviesShows(featuredMoviesShowsData);
                }
            })
            .catch(err => {
                console.error('Error fetching featured movies/shows:', err);
            }).finally(() => {
                setpagesSpinnerFlutter(false)
            });
    }, []);
    const handleFlutterFreeMoviesShows = (item) => {
        if (item.movie_id) {
            navigate(`/flutter-movie-detail-screen/${item.movie_id}`);
        } else if (item.show_id) {
            navigate(`/flutter-show-detail-screen/${item.show_id}`);
        }
    }
    return (
        <>
            {/* Top Bar */}
            <FlutterBackHeader />
            <section className="all_episodes_fetch">
                <div className={`episodes_row ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                    {featuredMoviesShows.length > 0 ? (
                        featuredMoviesShows.map((item, index) => (
                            <div className="episodes_main" key={index}
                            onClick={() => {
                                handleFlutterFreeMoviesShows(item);
                            }}>
                                <div className="flutter_movie_show_img flutter_movie_show_featured">
                                    {item.show_id && (
                                        <>
                                            {/* Background effect only for shows */}
                                            <div
                                                className="serial__over_flutter"
                                                style={{
                                                    backgroundImage: `url(${item.show_poster})`
                                                }}
                                            ></div>
                                            <div
                                                className="serial__over_2_flutter"
                                                style={{
                                                    backgroundImage: `url(${item.show_poster})`
                                                }}
                                            ></div>
                                        </>
                                    )}
                                    <img
                                        src={item.show_poster || item.movie_poster}
                                        alt={item.show_title || item.movie_title}
                                    />
                                </div>
                                <div className="hd_icon">
                                    <img src={hd} alt="HD icon" />
                                </div>
                                <div className="episode_title_flutter">
                                    <p className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{item.show_title || item.movie_title}</p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="alert_inside_pages">Free Movies are Not Found</p>
                    )}
                </div>
            </section>
            {pages_spinner && (

                <div className="flutter_section_spinner">
                    <div class="flutter_spinned_sections"></div>
                </div>
            )}
        </>
    );
};

export default FlutterFreeMoviesSerials;
