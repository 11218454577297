import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from '../../config';
import { Theme_Variable } from '../../config';
import axios from "axios";

// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

// Import icons
import download_black from '../../Images/flutter/nav/cloud-icon.png';
import watchlist from '../../Images/flutter/nav/watchlist.png';
import shareIcon from '../../Images/flutter/nav/share-icon.png';

import white1 from '../../Images/flutter/nav/white1.png';
import white2 from '../../Images/flutter/nav/white2.png';
import white3 from '../../Images/flutter/nav/white3.png';

import arrwhite from '../../Images/flutter/nav/arr_white.png';
import arrblack from '../../Images/flutter/nav/arr_black.png';

import subscribe from '../../Images/flutter/subscribe.png';
import dark_about from "../../Images/flutter/about.png";

import dark_contact from "../../Images/flutter/contact.png";
import dark_moon from "../../Images/flutter/moon_dark.png";
import dark__arr from "../../Images/flutter/dark__ar.png";

import profile_2 from "../../Images/flutter/profile_2.png";
import change from "../../Images/flutter/chnage.png";
import payment from "../../Images/flutter/NewFlix Images/Icons/payments.png";
// White Theme Images Files 
import payment_icon_white from '../../Images/flutter/White_icons/payments.png';
import about_icon_white from '../../Images/flutter/White_icons/about.png';
import change_viewer_icon_white from '../../Images/flutter/White_icons/change_viewer.png';
import phone_icon_white from '../../Images/flutter/White_icons/phone_white.png';
import moon_icon_white from '../../Images/flutter/White_icons/white__moon.png';
import profile_icon_white from '../../Images/flutter/White_icons/profile_white.png';
import white_arr from '../../Images/flutter/White_icons/white_arr.png'
import subscription_white from '../../Images/flutter/White_icons/subscription_white.png';
import { useTranslation } from "react-i18next";

const FlutterBackHeader = () => {
    const navigate = useNavigate(); // Initialize navigate function
    const [isScrolled, setIsScrolled] = useState(false);
    // Function to handle going back
    const handleBack = () => {
        navigate(-1); // Navigate to the previous page in history
    };
    const {t} = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 350); // Adjust the scroll threshold as needed
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <section className={`flutter__header_top ${Theme_Variable == 'Dark' ? 'dark_header' : 'light_back_header'}`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div onClick={handleBack} className={`main__header_back_heading  ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`} style={{cursor:'pointer'}} >
                                {t("back")}
                                <div className="arrow_icon_back" style={{ marginTop:'-2px'}}>
                                    <img src= {`${Theme_Variable == 'Dark' ?  arrwhite : arrblack}`} loading="lazy" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FlutterBackHeader;
