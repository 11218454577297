import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { API_BASE_URL } from '../../config';
import axios from "axios";
import { Theme_Variable } from '../../config';
// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

// Import icons
import download_black from '../../Images/flutter/nav/cloud-icon.png';
import watchlist from '../../Images/flutter/nav/watchlist.png';
import shareIcon from '../../Images/flutter/nav/share-icon.png';

import white1 from '../../Images/flutter/nav/white1.png';
import white2 from '../../Images/flutter/nav/white2.png';
import white3 from '../../Images/flutter/nav/white3.png';

import arrwhite from '../../Images/flutter/nav/arr_white.png';
import arrblack from '../../Images/flutter/nav/arr_black.png';

import subscribe from '../../Images/flutter/subscribe.png';
import dark_about from "../../Images/flutter/about.png";

import dark_contact from "../../Images/flutter/contact.png";
import dark_moon from "../../Images/flutter/moon_dark.png";
import dark__arr from "../../Images/flutter/dark__ar.png";

import delete_device from "../../Images/flutter/delete.png"
import device from "../../Images/flutter/device.png";
import logout from "../../Images/flutter/logout.png";

// White Icons
import trash_white from '../../Images/flutter/White_icons/trash_white.png';
import logout_white from '../../Images/flutter/White_icons/logout_white.png';
import device_white from '../../Images/flutter/White_icons/device_white.png';
import white_arr from '../../Images/flutter/White_icons/white_arr.png'
import FlutterBackHeader from "./FlutterBackHeader";
import { t } from "i18next";


const FlutterEditProfile = () => {
    const location = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const UserId = localStorage.getItem('user_id') || '';
    const sessionName = localStorage.getItem('session_name') || '';

    useEffect(() => {
        // Check the current route and apply/remove the class accordingly
        if (location.pathname == '/flutter-profile-edit-page' && Theme_Variable == 'Light') {
            document.body.classList.add('no-black-background');
        } else if (location.pathname == '/flutter-profile-edit-page' && Theme_Variable == 'Dark') {
            document.body.classList.add('black-background');
        } else {
            document.body.classList.remove('no-black-background');
        }
    }, [location.pathname]);

    const deactiveAccount = () => {
        axios.post(`${API_BASE_URL}api/v1/account_delete`, {
            user_id: UserId,
            user_session_name: sessionName,
        })
            .then(response => {
                if (response.data.VIDEO_STREAMING_APP.success == "1") {
                    alert('Account successfully deactivated.');
                    // Perform any additional navigation or UI updates
                } else {
                    alert('Failed to deactivate account. Please try again.');
                }
                // console.log(response)
            })
            .catch(err => {
                console.error("Error deactivating account:", err);
                alert('An error occurred while deactivating the account. Please try again later.');
            });
    };

    const logouthandle = (e) => {
        e.preventDefault();
        let userId = localStorage.getItem('user_id');
        localStorage.clear();
        axios.post(`${API_BASE_URL}api/v1/logout`, {
            user_id: userId,
            user_session_name: sessionName,
        })
            .then(() => {
                window.location.href = '/flutter-home-screen';
            })
            .catch(err => {
                console.error("Logout error:", err);
            });
    };

    return (
        <>
            <FlutterBackHeader/>
            <section className="edit__profile__flutter__main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className={`links__profile__Edit__main ${Theme_Variable == 'Dark' ? 'links_on_profile_flutter_white' : ''}`}>
                                <ul className="list-unstyled p-0">
                                    <li>
                                        <Link to="/flutter-profile-your-device">
                                            <span className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                                <div className="icon__main_login device__icon">
                                                    {
                                                        Theme_Variable == 'Light' ? (
                                                            <img src={device} alt="" />) :
                                                            (<img src={device_white} alt="" />)
                                                    }
                                                </div>
                                                {t("Your Devices")}
                                            </span>
                                            <div className="left__icon__login__flutter">
                                                {
                                                    Theme_Variable == 'Light' ? (
                                                        <img src={dark__arr} alt="" />) :
                                                        (<img src={white_arr} alt="" />)
                                                }
                                            </div>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="#" onClick={deactiveAccount}>
                                            <span className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                                <div className="icon__main_login delete_icon">
                                                    {
                                                        Theme_Variable == 'Light' ? (
                                                            <img src={delete_device} alt="" />) :
                                                            (<img src={trash_white} alt="" />)
                                                    }
                                                </div>
                                                {t("Deactivate My Account")}
                                            </span>
                                            <div className="left__icon__login__flutter">
                                                {
                                                    Theme_Variable == 'Light' ? (
                                                        <img src={dark__arr} alt="" />) :
                                                        (<img src={white_arr} alt="" />)
                                                }
                                            </div>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="#" onClick={logouthandle}>
                                            <span className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>
                                                <div className="icon__main_login logout_icon_flutter">
                                                    {
                                                        Theme_Variable == 'Light' ? (
                                                            <img src={logout} alt="" />) :
                                                            (<img src={logout_white} alt="" />)
                                                    }
                                                </div>
                                                {t("logout",{defaultValue:'Logout'})}
                                            </span>
                                            <div className="left__icon__login__flutter">
                                                {
                                                    Theme_Variable == 'Light' ? (
                                                        <img src={dark__arr} alt="" />) :
                                                        (<img src={white_arr} alt="" />)
                                                }
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Modal Bootstrap */}
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Account Deactive</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Do you want to really delete this Account?
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default FlutterEditProfile;
