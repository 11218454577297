import { useState, useEffect } from "react";
import { API_BASE_URL } from '../../config';
import { Theme_Variable } from '../../config';
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import hd from '../../Images/flutter/hd.png'; // Ensure this image is required

// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import FlutterBottomBar from "./flutter_component/FlutterBottomBar";
import FlutterBackHeader from "./FlutterBackHeader";

const FlutterLiveTv = () => {
    const location = useLocation();
    const [liveTvLists, setLiveTvLists] = useState([]); // Default empty array
    const [pages_spinner, setpagesSpinnerFlutter] = useState(true);
    const navigate = useNavigate();
    const UserId = localStorage.getItem('user_id') || 0;
    const [checkplansuscription, setcheckplansuscription] = useState('');

    // Apply or remove class based on the route
    // useEffect(() => {
    //     if (location.pathname == '/flutter-livetv-all') {
    //         document.body.classList.add('no-black-background');
    //     } else if (location.pathname == '/flutter-livetv-all' && Theme_Variable == 'Dark') {
    //         document.body.classList.add('black-background');
    //     } else {
    //         document.body.classList.remove('no-black-background');
    //     }

    // }, [location.pathname]);

    // Fetch live TV channels
    useEffect(() => {

        if (UserId) {
            axios.post(`${API_BASE_URL}api/v1/check_user_plan`, {
                user_id: UserId
            })
                .then(response => {
                    let checkPlan = response.data.VIDEO_STREAMING_APP;
                    setcheckplansuscription(checkPlan.msg);
                }).catch(err => {
                    console.log(err);
                })
        }


        axios.post(`${API_BASE_URL}api/v1/livetv`, {})
            .then(response => {
                const liveTv = response.data?.VIDEO_STREAMING_APP?.livetv || [];
                setLiveTvLists(liveTv);
            })
            .catch(err => {
                console.error('Error fetching live TV channels:', err);
            }).finally(() => {
                setpagesSpinnerFlutter(false)
            });;
    }, []);

    const LiveTvClickFlutter = (live_tv) => {
        if(UserId){
            if (checkplansuscription == 'My Subscription' || live_tv.tv_access == 'Free') {
                navigate(`/flutter-livetv-player/${live_tv.tv_id}`);
            } else if (checkplansuscription == 'Renew subscription plan' || checkplansuscription == 'Please select subscription plan' || live_tv.tv_access === 'Paid') {
                navigate('/flutter-subscription');
            }
        }else{
            navigate('/flutter-login');
        }
    }

    return (
        <>
            {/* Top Bar */}
            <FlutterBackHeader />
            <section className="all_episodes_fetch">
                <div className={`episodes_row ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                    {liveTvLists.length > 0 ? (
                        liveTvLists.map((live_tv, index) => (
                            <div className="episodes_main" key={index}
                            onClick={() => LiveTvClickFlutter(live_tv)}
                            style={{cursor:"pointer"}}>
                                <div className="flutter_movie_show_img flutter_movie_show_featured">
                                    <img
                                        src={live_tv.tv_logo}
                                        alt={live_tv.tv_title}
                                    />
                                </div>
                                <div className="hd_icon">
                                    <img src={hd} alt="HD icon" />
                                </div>
                                <div className="episode_title_flutter">
                                    <p className={`text-right ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{live_tv.tv_title}</p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <></>
                    )}
                </div>
            </section>
            {/* <FlutterBottomBar/> */}
            {pages_spinner && (

                <div className="flutter_section_spinner">
                    <div class="flutter_spinned_sections"></div>
                </div>
            )}
        </>
    );
};

export default FlutterLiveTv;
