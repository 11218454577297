import { useState, useEffect } from "react";
import { API_BASE_URL } from '../../config';
import axios from "axios";
import { Theme_Variable } from '../../config';
import { useLocation, useNavigate } from "react-router-dom";
import hd from '../../Images/flutter/hd.png'; // Ensure this image is required

// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import FlutterBackHeader from "./FlutterBackHeader";

const FlutterFeaturedAllMoviesSerials = () => {
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);
    const [featuredMoviesShows, setFeaturedMoviesShows] = useState([]); // Default empty array
    const [pages_spinner, setpagesSpinnerFlutter] = useState(true);
    let childMode = parseInt(localStorage.getItem('child_mode'));
    const subUserSelectedAge = parseInt(localStorage.getItem('sub_account_age'));
    const navigate = useNavigate();

    // Function to toggle the overlay
    const toggleActiveClass = () => {
        setIsActive(!isActive);
    };

    // Apply or remove class based on the route

    useEffect(() => {
        if (location.pathname == '/flutter-featured-all-movies-serials' && Theme_Variable == 'Light') {
            document.body.classList.add('no-black-background');
        } else if (location.pathname == '/flutter-featured-all-movies-serials' && Theme_Variable == 'Dark') {
            document.body.classList.add('black-background');
        } else {
            document.body.classList.remove('no-black-background');
        }

    }, [location.pathname]);

    // Fetch featured movies and shows
    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/flutter_all_featured_list`, {})
            .then(response => {
                if (childMode === 1 && subUserSelectedAge) {
                    let filteredFeatured = response.data.VIDEO_STREAMING_APP.feature.filter(featured_movies_shows => {
                        if (featured_movies_shows.content_rating === '') {
                            return false;
                        }
                        return parseInt(featured_movies_shows.content_rating) <= subUserSelectedAge;
                    });
                    setFeaturedMoviesShows(filteredFeatured);
                } else {
                    const featuredMoviesShowsData = response.data?.VIDEO_STREAMING_APP?.feature || [];
                    setFeaturedMoviesShows(featuredMoviesShowsData);
                }
            })
            .catch(err => {
                console.error('Error fetching featured movies/shows:', err);
            }).finally(() => {
                setpagesSpinnerFlutter(false)
            });;
    }, []);

    const handleFlutterFeaturedMoviesShows = (movieShow) => {
        if (movieShow.movie_id) {
            navigate(`/flutter-movie-detail-screen/${movieShow.movie_id}`);
        } else if (movieShow.show_id) {
            navigate(`/flutter-show-detail-screen/${movieShow.show_id}`);
        }
    }

    return (
        <>
            {/*Top Bar  */}
        <FlutterBackHeader />
            <section className={`all_episodes_fetch ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                <div className={`episodes_row ${Theme_Variable == 'Dark' ? 'section_dark_color' : ''}`}>
                    {featuredMoviesShows.length > 0 ? (
                        featuredMoviesShows.map((movieShow, index) => (
                            <div className="episodes_main" key={index}
                                onClick={() => {
                                    handleFlutterFeaturedMoviesShows(movieShow);
                                }}>
                                <div className="flutter_movie_show_img flutter_movie_show_featured">

                                    {movieShow.show_id && (
                                        <>
                                            <div
                                                className="serial__over_flutter"
                                                style={{ backgroundImage: `url(${movieShow.show_poster || movieShow.movie_poster})` }}
                                            ></div>
                                            <div
                                                className="serial__over_2_flutter"
                                                style={{ backgroundImage: `url(${movieShow.show_poster || movieShow.movie_poster})` }}
                                            ></div>
                                        </>
                                    )}

                                    <img src={movieShow.show_poster || movieShow.movie_poster} alt={movieShow.show_title || movieShow.movie_poster} />
                                </div>
                                <div className="hd_icon">
                                    <img src={hd} alt="HD icon" />
                                </div>
                                <div className="episode_title_flutter">
                                    <p className={`${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`}>{movieShow.show_title || movieShow.movie_title}</p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className={`alert_inside_pages ${Theme_Variable == 'Dark' ? 'white_text_mode' : ''}`} >Free Movies are Not Found</p>


                    )}
                </div>
            </section>
            {pages_spinner && (

                <div className="flutter_section_spinner">
                    <div class="flutter_spinned_sections"></div>
                </div>
            )}
        </>
    );
};

export default FlutterFeaturedAllMoviesSerials;
