import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import '../Components/css/style.css';
import profile_img from '../Images/home_images/profile.jpg';
import { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from '../config';

const EditProfile = () => {
    // const { id } = useParams();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [userImage, setUserImage] = useState(null);
    const [userImageUrl, setUserImageUrl] = useState(localStorage.getItem('user_image'));
    const [responseAlert, setResponseAlert] = useState('');
    const [loading, setLoading] = useState(false);
    const [buttonCondition, setbuttonCondition] = useState(false);
    const id = localStorage.getItem('user_id');
    // Function For Alert Message
    function alert_msg(message) {
        setResponseAlert(message);
        let alertBox = document.querySelector(".update_msg-alert");
        alertBox.classList.add('active_edit_msg');

        setTimeout(() => {
            alertBox.classList.remove('active_edit_msg');
        }, 5000);
    }
    let get_phone = localStorage.getItem('phoneNumberVerification');
    useEffect(() => {
        setLoading(true);
        axios.post(`${API_BASE_URL}api/v1/profile`, {
            user_id: id,
        })
            .then(response => {
                setbuttonCondition(true);
                setLoading(false);
                const userData = response.data.VIDEO_STREAMING_APP[0];
                setName(userData.name || '');
                setEmail(userData.email || '');
                setPhone("+" + get_phone || '');
                setAddress(userData.user_address || '');
            })
            .catch(err => {
                console.error("Error fetching user data:", setResponseAlert(err));
                alert_msg("Error fetching user data");
            });
    }, [id]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setUserImage(file);
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setUserImageUrl(reader.result); // Display the uploaded image
            };
            reader.readAsDataURL(file);
        }
    };

    const handleFormSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append('user_id', parseInt(id));
        formData.append('name', name);
        formData.append('email', email);
        formData.append('user_address', address);
        if (userImage) {
            formData.append('user_image', userImage);
        }
        try {
            axios.post(`${API_BASE_URL}api/v1/profile_update`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            })
                .then(response => {
                    setLoading(false);
                    if (response.data.status == 401) {
                        setResponseAlert(response.data.errors.email[0]);
                        alert_msg(response.data.errors.email[0]);
                    }else if (response.data.VIDEO_STREAMING_APP[0].success == 1) {
                        setResponseAlert(response.data.VIDEO_STREAMING_APP[0].msg);
                        alert_msg(response.data.VIDEO_STREAMING_APP[0].msg);

                        axios.post(`${API_BASE_URL}api/v1/profile`, {
                            user_id: id,
                        })
                            .then(response => {
                                localStorage.setItem("user_image",response.data.VIDEO_STREAMING_APP[0].user_image)
                            })
                            .catch(err => {
                                console.error("Error fetching user data:", setResponseAlert(err));
                                alert_msg("Error fetching user data");
                            });
                    } else {
                        setResponseAlert(response.data.VIDEO_STREAMING_APP[0].msg);
                        alert_msg(response.data.VIDEO_STREAMING_APP[0].msg);

                    }
                })
                .catch(err => {
                    setResponseAlert(err);
                    console.error("Error updating profile:", err);
                    alert_msg(err);
                });
        } catch (err) {
            alert_msg(err);
        }

    };

    return (
        <>
            <section className="edit__profile-main videos-inner-wrapper">
                <div className="container-fluid">
                    <div className="custom-container">
                        <div className="edit_form">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    {
                                        loading && <div class="spinner-border spinner_color mt-3" role="status"></div>
                                    }
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <div className="edit__profile-title">
                                        <h2>Edit Your Profile</h2>

                                    </div>
                                    <div className="profile_image">
                                        <img src={userImageUrl} alt="Profile" />
                                    </div>
                                    <div className={`update_msg-alert ${responseAlert ? 'active_edit_msg' : ''}`}>
                                        <p className="m-0">{responseAlert}</p>
                                    </div>
                                    <form onSubmit={handleFormSubmit}>
                                        <input
                                            placeholder="Update Name"
                                            className="input_fileds"
                                            type="text"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                        <input
                                            placeholder="Update Email"
                                            className="input_fileds"
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <input
                                            placeholder="Update Phone"
                                            className="input_fileds"
                                            type="text"
                                            value={phone} readOnly
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                        <input
                                            placeholder="Update Address"
                                            className="input_fileds"
                                            type="text"
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                        />
                                        <input
                                            className="input_fileds"
                                            type="file"
                                            onChange={handleImageChange}
                                        />
                                        {
                                            buttonCondition &&
                                            <input type="submit" className="profile__edit-btn" value="Update Profile" />
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default EditProfile;
