import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_BASE_URL } from '../config';

const Episodesshows = () => {
    const { id } = useParams();
    const [episodes, setEpisodes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ageMessage, setageMessage] = useState('');
    const navigate = useNavigate();
    const subUserSelectedAge = parseInt(localStorage.getItem('sub_account_age'));
    sessionStorage.setItem('season_id', id);
    useEffect(() => {
        setLoading(true);
        let UserId = localStorage.getItem('user_id');
        axios.post(`${API_BASE_URL}api/v1/episodes`, {
            user_id: UserId || '',
            season_id: id
        }, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(response => {
            setLoading(false);
            if (subUserSelectedAge) {
                let filterEpisodes = response.data.VIDEO_STREAMING_APP.filter(episode => {
                    if (episode.series_content_rating == '') {
                        return false;
                    }
                    setageMessage('Not Found Any Episode in this age group');
                    return parseInt(episode.series_content_rating) <= subUserSelectedAge;
                });
                console.log(filterEpisodes)
                setEpisodes(filterEpisodes);
            } else {
                let episodes = response.data.VIDEO_STREAMING_APP || [];
                setEpisodes(episodes);
            }
        })
            .catch(err => {
                console.error("Something went wrong:", err);
            });
    }, [id]);


    const episodeHandle = (episode) => {
        navigate(`/watch-episode/${episode.episode_id}?season_id=${id}`); // Navigates to the episode page with the episode ID and season ID in the query parameters
    };

    return (
        <>
            <section className="episodes_wrapper">
                <div className="container-fluid">
                    <div className="inner_video_wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="series_name">
                                    <p>{episodes.length > 0 ? episodes[0].series_name : 'Series Name'}</p>
                                </div>
                            </div>
                        </div>
                        <div className="all__episodes">
                            <div className="row">
                                {
                                    episodes.length > 0 ? (
                                        episodes.map((episode, index) => (
                                            <div className="col-6 col-sm-3 col-md-2" key={index}>
                                                <div className="episode_card" onClick={() => episodeHandle(episode)}>
                                                    <div className="episode_poster">
                                                        <img src={episode.episode_image} alt={episode.episode_title} loading="lazy" />
                                                        <div className="overlay__episode">
                                                            <div className="more_info-episode">
                                                                {episode.duration &&
                                                                    <p className="duration">{episode.duration}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="episode_title">
                                                        <p>{episode.episode_title}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="episode_title">
                                            {
                                                (
                                                    ageMessage ? <p>{ageMessage}</p> :
                                                        <p>Not Found Any episode in this series</p>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                loading &&
                <div className="full-screen_loader">
                    <div className="spinner-border spinner_color mt-2 text-center" role="status"></div>
                </div>
            }
        </>
    );
};

export default Episodesshows;
