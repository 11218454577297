import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import hd from '../../Images/flutter/hd.png'; // Ensure this image is required
import i9 from '../../Images/flutter/NewFlix Images/i9.png';
import screen from '../../Images/flutter/screen.png';
import eds from '../../Images/flutter/eds.png';
import headphone from '../../Images/flutter/headphone.png';
import plygon from '../../Images/flutter/Polygon.png';
import email from '../../Images/flutter/email.png';
import phone from '../../Images/flutter/phone.png';
import question from '../../Images/flutter/question.png';
import right_arr from '../../Images/flutter/arrow_right.png';
import { API_BASE_URL } from '../../config';

const FlutterChildModeOtp = () => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [alert_otp, setOtpalert] = useState('');
    const [isInputInvalid, setIsInputInvalid] = useState(false);
    const [loading, setLoading] = useState(false);
    const phoneNumber = localStorage.getItem("phoneNumberVerification");
    const [pages_spinner, setpagesSpinnerFlutter] = useState(true);
    const [alertResponse, setResponse] = useState(null);
    const [code, setCode] = useState(Array(6).fill(''));

    useEffect(() => {
        document.body.classList.toggle('black-background', location.pathname === `/flutter-child-mode-verification`);
        return () => {
            document.body.classList.remove('black-background');
        };
    }, [location.pathname]);

    const inputRefs = useRef([]);
    useEffect(() => {
        inputRefs.current[0].focus();
    }, []);

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (!/^\d*$/.test(value)) {
            return;
        }
        const updatedCode = [...code];
        updatedCode[index] = value;
        console.log(updatedCode);
        setCode(updatedCode);

        if (value && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && index > 0 && !e.target.value) {
            inputRefs.current[index - 1].focus();
        }
    };

    const [timer, setTimer] = useState(60);
    const [showResendMessage, setShowResendMessage] = useState(false);

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setShowResendMessage(true);
        }
    }, [timer]);

    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/signup`, {
            phone: "+" + phoneNumber
        })
            .then(response => {
                setLoading(false);
                if (response.data.VIDEO_STREAMING_APP[0]['success'] === 1) {
                    setResponse("Message sent!");
                } else {
                    setResponse(response.data.VIDEO_STREAMING_APP[0]['msg']);
                }
            })
            .catch(error => {
                setResponse("There was an error submitting the phone number: " + error.message);
                console.error("Error:", error.message);
            });
    }, []);

    const handleVerifyClick = () => {
        const codeConverted = parseInt(code.join(''), 10); // Ensure parseInt has a radix
        setpagesSpinnerFlutter(true);
        setLoading(true);
        setOtpalert('');
        setIsInputInvalid(false);

        axios.post(`${API_BASE_URL}api/v1/verify_code`, {
            phone: `+${phoneNumber}`,
            code: codeConverted
        })
            .then(response => {
                setLoading(false);
                const result = response.data.VIDEO_STREAMING_APP[0];
                setOtpalert(result.msg);
                setpagesSpinnerFlutter(false);
                if (result.success == 1) {
                    localStorage.setItem('child_mode', 0);
                    localStorage.removeItem("sub_account_name");
                    localStorage.removeItem("sub_account_id");
                    localStorage.removeItem("sub_account_img");
                    localStorage.removeItem("sub_account_age");
                    window.location.href = `/flutter-home-screen`;
                } else {
                    setResponse(result.msg);
                }
            })
            .catch(error => {
                setOtpalert('An error occurred. Please try again.');
                setIsInputInvalid(true);
                setLoading(false);
            });
    };

    const handleSendCodeAgain = () => {
        setTimer(60); // Reset the timer to 60 seconds
        setShowResendMessage(false); // Hide the resend message
        setLoading(true);

        axios.post(`${API_BASE_URL}api/v1/signup`, {
            phone: `+${phoneNumber}`
        })
            .then(response => {
                setLoading(false);
                setOtpalert(response.data.VIDEO_STREAMING_APP[0].msg);
            })
            .catch(error => {
                setLoading(false);
                console.error("There was an error submitting the phone number!", error);
            });
    };

    return (
        <section className="flutter_child_otp_main">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="flutter_child_main_main">
                            <div className="back_btn_child_pg">
                                <Link to="/flutter-multi-profiles">Back
                                    <div className="right__arr_child">
                                        <img src={right_arr} alt="" />
                                    </div>
                                </Link>
                            </div>
                            <div className="child_mode_otp_form_flutter" style={{ direction: 'ltr' }}>
                                <div className="alert_for_child_mode">
                                    <span>Changing the viewer from child mode to adult mode requires your permission.</span>
                                    <p>Enter the verification code sent via SMS to the number {phoneNumber} to allow changing the viewer from child mode to adult mode.</p>
                                </div>
                                <div className="otp_boxes_main">
                                    {Array(6).fill().map((_, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            size={1}
                                            maxLength={1}
                                            className={`input_fileds_otp ${isInputInvalid ? 'invalid' : ''}`}
                                            ref={(el) => (inputRefs.current[index] = el)}
                                            onChange={(e) => handleChange(e, index)}
                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                        />
                                    ))}
                                </div>
                                <button onClick={handleVerifyClick}>Verify</button> {/* Changed to handleVerifyClick */}
                                <div className="opt_timer_flutter_child_mode">
                                    {showResendMessage ? (
                                        <p onClick={handleSendCodeAgain}>Send code again.</p>
                                    ) : (
                                        <p>Please wait {timer} seconds before requesting a new code.</p>
                                    )}
                                </div>
                                {pages_spinner && (
                                    <div className="flutter_section_spinner">
                                        <div className="flutter_spinned_sections"></div>
                                    </div>
                                )}
                                {alert_otp && (
                                    <div className={`${isInputInvalid ? 'err_flutter_login_child_mode' : 'err_flutter_login_success text-center'}`} role="alert">
                                        {alert_otp}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FlutterChildModeOtp;
