import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import successimg from '../../Images/success.png';
import axios from 'axios';
import { API_BASE_URL } from '.././../config';

const FlutterSucessPayment = () => {
    let userId = localStorage.getItem('user_id');
    let planId = localStorage.getItem('plan_id');
    let plan_days = localStorage.getItem('planDays');
    let price_plan = localStorage.getItem('planePrice');
    let paymentId = localStorage.getItem('payment_id');
    useEffect(()=>{
        axios.post(`${API_BASE_URL}api/v1/fib/payments/fib_success`,{
            user_id : userId,
            plan_id : planId,
            plan_days : plan_days,
            plan_amount : price_plan,
            payment_id : paymentId
        })
        .then(response=>{
            console.log(response.error);
        }).catch(err=>{
            window.location.href = '/payment-error';

            console.log("success payment error"+err);
        })
    },[]);
    return (
        <section className='success_payment-section'>
            <div className="container-fluid">
                <div className="custom-container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 text-center mt-2">
                            <div className="success_image text-center">
                                <img src={successimg} alt="" />
                            </div>
                            <div className="success_msg">
                                <p>Your payment has been successful.</p>
                                <Link to="/" className="btn btn-success text-center mt-2">
                                    Go to home
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default FlutterSucessPayment;