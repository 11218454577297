import NewFlixLogo from '../Images/newflix-logo.png';
import React, { useState } from 'react';
import 'swiper/css';
import '../Components/css/style.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';

const AddnewProfile = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const navigate = useNavigate();
    const userId = localStorage.getItem("user_id");
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [displayBlock, setDisplayBlock] = useState('none');

    const handleImageClick = () => {
        document.getElementById('fileInput').click();
    };

    function reloadMultiProfile (){
        setTimeout(()=>{
            window.location.reload();
        },2000);
    }
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            console.log(file);
        }
    };

    const handleSubmit = () => {
        if (!name || !age) {
            setResponseMessage('Please fill out all fields.');
            setDisplayBlock('block');
            return;
        }

        setLoading(true);
        setDisplayBlock('none');

        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('name', name);
        formData.append('user_image', selectedImage);
        formData.append('age', age);

        axios.post(`${API_BASE_URL}api/v1/sub_account_create`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(response => {
            if(response.data.status_code == 401){
                setResponseMessage(response.data.VIDEO_STREAMING_APP);
            }else{
                console.log(response.data.VIDEO_STREAMING_APP[0].msg);
                setResponseMessage(response.data.VIDEO_STREAMING_APP[0].msg);
                window.location.href = '/multiple-profiles';
            }
            setDisplayBlock('block');
        }).catch(err => {
            setResponseMessage('An error occurred while creating the profile.');
            setDisplayBlock('block');
            console.error(err);
        }).finally(() => {
            setLoading(false);
        });
    };

    const handlePage = () => {
        navigate('/multiple-profiles');
    }

    return (
        <>
            <section className="add__new-profile">
                <div className="container-fluid">
                    <div className="custom-container">
                        <div className="row justify-content-center">
                            <div className="col-md-6 text-center d-flex justify-content-center">
                                <div className="multi__profile-img">
                                    <img src={NewFlixLogo} alt="NewFlix Logo" />
                                </div>
                            </div>
                        </div>
                        <div className="multi__profile__heading-main">
                            <div className="row justify-content-center">
                                <div className="col-md-6 text-center">
                                    <div className="multi__profiles_add">
                                        <h2>Add New Member</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-inputs-wrapper">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-md-6">
                                    <div className="row justify-content-end align-items-center">
                                        <div className="col-md-8 order-last order-md-first">
                                            <div className="add_profile-form">
                                                <div className="form-group">
                                                    <label htmlFor="name" className="form__label">Your Name</label>
                                                    <input
                                                        type="text"
                                                        className="add__input-filed"
                                                        placeholder="Write Your Desired Name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="age" className="form__label">What age-appropriate content do you watch?</label>
                                                    <div className="select-wrapper">
                                                        <select
                                                            className="add__input-filed"
                                                            value={age}
                                                            onChange={(e) => setAge(e.target.value)}
                                                        >
                                                            <option value="" disabled>Select Age Group</option>
                                                            <option value="3">up to 3 years</option>
                                                            <option value="5">up to 5 years</option>
                                                            <option value="12">up to 12 years</option>
                                                            <option value="15">up to 15 years</option>
                                                            <option value="18">up to 18 years</option>
                                                            <option value="1000">everyone</option>
                                                        </select>
                                                        <i className="fas fa-chevron-down"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 order-first order-md-last">
                                            <div className="upload__image" onClick={handleImageClick}>
                                                <input
                                                    type="file"
                                                    id="fileInput"
                                                    style={{ display: 'none' }}
                                                    accept="image/*"
                                                    onChange={handleFileChange}
                                                />
                                                {selectedImage ? (
                                                    <img src={URL.createObjectURL(selectedImage)} alt="Selected" style={{ width: '100%', height: 'auto' }} />
                                                ) : (
                                                    <div className="upload-placeholder">
                                                        <i className="fa-regular fa-images"></i>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-4 text-right">
                                    {loading && (
                                        <div className="loader_section" style={{ display: "flex", justifyContent: "center" }}>
                                            <div className="spinner-border spinner_color mt-2 text-center" role="status"></div>
                                        </div>
                                    )}
                                    <p className={`response_create_msg m-0 d-${displayBlock}`}>{responseMessage}</p>
                                    <div className="button__group">
                                        <button className="add_profile_btn" onClick={handleSubmit} disabled={loading}>
                                            Add
                                        </button>
                                        <button className="return_profile_btn" onClick={handlePage}>
                                            Return
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AddnewProfile;
